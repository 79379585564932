import { Col, Input, Row } from "antd"
import Layout, { Header } from "antd/lib/layout/layout"
import IssueListPrivate from "../../components/IssueListPrivate/IssueListPrivate"
import PrivateSessionList from "./components/PrivateSessionList"
import './privateSession.less'
import PrivateSessionChat from "./components/PrivateSessionChat"
import PrivateSessionInfo from "./components/PrivateSessionInfo"
import PrivateSessionHeader from "./components/PrivateSessionHeader"
import PrivateSessionForwardModal from "./components/PrivateSessionForwardModal"
import PrivateSessionCloseModal from "./components/PrivateSessionCloseModal"


const PrivateSession = () => {
    return (<>
            <PrivateSessionCloseModal />
            <PrivateSessionForwardModal />
            <Row style={{ height: "100vh" }}>
                <Col span={6}>
                    <PrivateSessionList />
                </Col>
                <Col span={18} style={{height:'100%'}}>
                    <Row style={{height:'10%'}}>
                        <PrivateSessionHeader />
                    </Row>
                    <Row style={{height:'90%'}}>
                        <Col span={12} style={{height:'100%'}}>
                            <PrivateSessionChat />
                        </Col>
                        <Col span={12} style={{height:'100%'}}>
                            <PrivateSessionInfo />
                        </Col>
                    </Row>
                </Col>


            </Row>
    </>)
}
export default PrivateSession