import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AuthOutlet } from "../utils/AuthOutlet"
import Home from "../features/home/Home"
import PrivacyPolicy from "../features/privacypolicy/PrivacyPolicy"

const App = () => {
    return (
        <BrowserRouter>
            <Routes>    
                <Route path='/*' element={<AuthOutlet />} >
                    <Route path='*' element={<Home />} />
                    {/* <Route path='issue' element={<Chat />} /> */}
                </Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App

