import { Avatar } from "antd"
import {
    UserOutlined
} from '@ant-design/icons';
import lineIcon from '../../res/line_icon.svg'


const ProfileIcon = (props) => {
    return (
        <div style={{position:'relative'}}>
            <img 
                style={{width: '25px', height:'25px', bottom:'0px', position:'absolute',borderRadius: '50%'}} 
                src={props.miniProfileSrc} alt="Avatar" />
            <img
                style={{width: '40px', height:'40px', marginLeft:'12px',borderRadius: '50%'}} 
                src={props.profileSrc} alt="Avatar" />

        </div>
    )
}
export default ProfileIcon