import { getLocalStorage, removeFromLocalStorage, setLocalStorage } from "."
import { STORAGE_ACTIVE_MENU_KEY, STORAGE_MENU_KEY } from "./constant"
import jwt_decode from "jwt-decode";

// {
//     active_id,
//     is_collapsed
// }
export const setStorageActiveMenuID = (active_id) => {
    const menuState = getLocalStorage(STORAGE_MENU_KEY)
    return setLocalStorage(STORAGE_MENU_KEY, {
        ...menuState,
        active_id: active_id
    }, true)
}
export const getStorageActiveMenuID = () => {
    return getLocalStorage(STORAGE_MENU_KEY).active_id
}
export const setStorageMenuIsCollapsed = (is_collapsed) => {
    const menuState = getLocalStorage(STORAGE_MENU_KEY)
    return setLocalStorage(STORAGE_MENU_KEY, {
        ...menuState,
        is_collapsed: is_collapsed
    }, true) 
}
export const getStorageMenuIsCollapsed = () => {
    return getLocalStorage(STORAGE_MENU_KEY).is_collapsed
}

// export const setActiveMenuToStorage = (current_menu) => {
//     return setLocalStorage(STORAGE_MENU_KEY, current_menu, true)
// }   

// export const getActiveMenuFromStorage = () => {
//     return getLocalStorage(STORAGE_MENU_KEY)
// }

export const resetActiveMenuFromStorage = () => {
    return removeFromLocalStorage(STORAGE_MENU_KEY)
}


