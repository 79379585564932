export const ENDPOINT = process.env.REACT_APP_API_URL
export const API_ENDPOINT = `${ENDPOINT}/api/v1`

export const AUTH_EXCHANGE_API = `${API_ENDPOINT}/auth/exchange`
export const AUTH_LOGIN_API = `${API_ENDPOINT}/auth/login`
export const AGENT_INFO_API = `${API_ENDPOINT}/agent/info`
export const AGENT_SESSIONS_API = `${API_ENDPOINT}/agent/sessions`
export const SESSION_DETAIL_API = `${API_ENDPOINT}/session`
export const SESSION_REQUEST_API = `${API_ENDPOINT}/session/request`
export const SESSION_HOLDING_API = `${API_ENDPOINT}/session/hold`
export const SESSION_FORWARDING_API = `${API_ENDPOINT}/session/forward`
export const SESSION_CLOSING_API = `${API_ENDPOINT}/session/close`
export const SESSION_REOPEN_API = `${API_ENDPOINT}/session/reopen`
export const SESSION_USER_API = `${API_ENDPOINT}/session/user`
export const SESSION_HISTORY_API = `${API_ENDPOINT}/session/history`
export const SESSION_LIST_ALL_API = `${API_ENDPOINT}/session/list/all`
export const SESSION_MANUAL_ADD = `${API_ENDPOINT}/session/manual`
export const SESSION_ADD_TAG = `${API_ENDPOINT}/session/tag/add`
export const SESSION_EDIT = `${API_ENDPOINT}/session/edit`

export const FILE_UPLOAD = `${API_ENDPOINT}/file/upload`

export const TAG_LIST = `${API_ENDPOINT}/tag/list`