import { createSlice } from "@reduxjs/toolkit";
import { getAgentSessions } from "../../app/services/agent";
import { uploadFile } from "../../app/services/file";
import { getSession, getSessionHistory, getSessionUser, sessionAddTag } from "../../app/services/session";


const initialState = {
    session: {
        data: null,
        status: 'idle'
    },
    session_user: {
        data: null,
        status: 'idle'
    },
    session_history: {
        data: null,
        status: 'idle'
    },

    issues: {
        data: null,
        status: 'idle'
    },
    update_session_tag_result: {
        data: null,
        status: 'idle'
    },
    file_upload_result: {
        data: null,
        status: 'idle'
    }
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setCurrentChat: (state, action) => {
            state.current_chat = action.payload
        },
        resetChatPageState: (state, action) => {
            state.session = {
                data: null,
                status: 'idle'
            }
            state.session_user = {
                data: null,
                status: 'idle'
            }
            state.session_history = {
                data: null,
                status: 'idle'
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSession.pending, (state) => {
            state.session.status = 'pending'
        })
        builder.addCase(getSession.fulfilled, (state, action) => {
            state.session.status = 'fulfilled'
            state.session.data = action.payload
        })
        builder.addCase(getSession.rejected, (state, action) => {
            state.session.status = 'rejected'
        })

        builder.addCase(getSessionUser.pending, (state) => {
            state.session_user.status = 'pending'
        })
        builder.addCase(getSessionUser.fulfilled, (state, action) => {
            state.session_user.status = 'fulfilled'
            state.session_user.data = action.payload
        })
        builder.addCase(getSessionUser.rejected, (state, action) => {
            state.session_user.status = 'rejected'
        })

        builder.addCase(getSessionHistory.pending, (state) => {
            state.session_history.status = 'pending'
        })
        builder.addCase(getSessionHistory.fulfilled, (state, action) => {
            state.session_history.status = 'fulfilled'
            state.session_history.data = action.payload
        })
        builder.addCase(getSessionHistory.rejected, (state, action) => {
            state.session_history.status = 'rejected'
        })

        // Fetch user sessions
        builder.addCase(getAgentSessions.pending, (state) => {
            state.issues.status = 'pending'
        })
        builder.addCase(getAgentSessions.fulfilled, (state, action) => {
            state.issues.status = 'fulfilled'
            state.issues.data = action.payload
        })
        builder.addCase(getAgentSessions.rejected, (state, action) => {
            state.issues.status = 'rejected'
        })

        // Update tag list
        builder.addCase(sessionAddTag.pending, (state) => {
            state.update_session_tag_result.status = 'pending'
        })
        builder.addCase(sessionAddTag.fulfilled, (state, action) => {
            state.update_session_tag_result.status = 'fulfilled'
            state.update_session_tag_result.data = action.payload
        })
        builder.addCase(sessionAddTag.rejected, (state, action) => {
            state.update_session_tag_result.status = 'rejected'
        })

        // Upload file 
        builder.addCase(uploadFile.pending, (state) => {
            state.file_upload_result.status = 'pending'
        })
        builder.addCase(uploadFile.fulfilled, (state, action) => {
            state.file_upload_result.status = 'fulfilled'
            state.file_upload_result.data = action.payload
        })
        builder.addCase(uploadFile.rejected, (state, action) => {
            state.file_upload_result.status = 'rejected'
        })

    }
})

export const selectSession = (store) => store.chat.session
export const selectSessionUser = (store) => store.chat.session_user
export const selectSessionHistory = (store) => store.chat.session_history
export const selectCurrentChat = (store) => store.chat.current_chat
export const selectIssues = (store) => store.chat.issues
export const selectUpdateSessionTagResult = (store) => store.chat.update_session_tag_result
export const selectFileUploadResult = (store) => store.chat.file_upload_result
export const { setCurrentChat, resetChatPageState } = chatSlice.actions
export default chatSlice.reducer