import { Avatar, Badge, Card, Col, Comment, Descriptions, Layout, List, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { requestSession } from "../../app/services/session";
import { selectSession } from "../../features/lobby/lobbySlice";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { formatDatetime } from "../../utils/datetime";
import './issueList.less'
import lineIcon from '../../res/line_icon.svg'
import fbMessengerIcon from '../../res/fb_messenger_icon.svg'
import defaultSourceIcon from '../../res/default_source_icon.svg'
import defaultProfile from '../../res/default_profile.svg'

import {
    UserOutlined,
    PauseCircleOutlined,
    EditOutlined,
    ForwardOutlined,
    CheckCircleOutlined,
    ArrowLeftOutlined,
    FormOutlined,
    DownOutlined
} from '@ant-design/icons';
import ProfileIcon from "../ProfileIcon/profileIcon";

const IssueList = (props) => {
    const navigate = useNavigate();
    const session = useAppSelector(selectSession)
    const dispatch = useAppDispatch()

    const gridStyle = {
        width: '25%',
        textAlign: 'center',
    };
    const sourceIcon = {
        'line': lineIcon,
        'facebook': fbMessengerIcon,
        'mock': defaultSourceIcon
    }

    return (
        <List  
            style={{height:'100%', overflow:'auto'}}
            // pagination={{
            //     position: 'bottom',
            //     align: 'center'
            // }}
            dataSource={props.issues}
            itemLayout="horizontal"
            renderItem={(item) =>
                <div>
                    <Row
                        className={item._id === props.selected ? "issue-item-selected" : "issue-item"}
                        onClick={() => props.handleClickSession(item._id)}>
                        <Col span={11} className="profile-container">
                            {/* {item.type} */}

                            <ProfileIcon
                                profileSrc={item.user_avatar ? item.user_avatar : defaultProfile}
                                miniProfileSrc={sourceIcon[item.source]}
                            />
                            {/* {sourceIcon[item.source]} */}
                            <Comment style={{ marginLeft: '10px' }} author={item.user_name}
                                // avatar='https://joeschmoe.io/api/v1/random'
                                content={item.channel} />
                        </Col>
                        <Col span={11} className="date-container">
                            <span className="date-object">{formatDatetime(item.created_at)}</span>
                        </Col>
                        <Col span={2} className="date-container">
                            {
                                item.type === 'active_holding' ?
                                    <Badge 
                                        size="default"
                                        key={item._id} 
                                        color={'red'} />
                                    : <></>
                            }
                        </Col>

                    </Row>
                </div>

            }
        />

        // loading ? <>
        //     <Card style={{ margin: '20px' }} loading={true} />
        //     <Card style={{ margin: '20px' }} loading={true} />
        //     <Card style={{ margin: '20px' }} loading={true} />
        // </> :
        //     <List
        //         dataSource={issues}
        //         header={`${issues?.length} ${issues?.length > 1 ? 'issues' : 'issue'}`}
        //         itemLayout="horizontal"
        //         renderItem={(props) => props.is_badge ?
        //             <Badge.Ribbon text="new" color={"red"}>
        //                 <Card hoverable size="small" title={props.user_name} extra={`UserID: ${props.user_id}`} onClick={() => handleClickIssue(props._id)} style={{ marginBottom: '16px', cursor: 'pointer' }}>
        //                     <Descriptions bordered title={props.channel} column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        //                         <Descriptions.Item label="User ID">{props.user_id}</Descriptions.Item>
        //                         <Descriptions.Item label="Source">{props.source}</Descriptions.Item>
        //                         <Descriptions.Item label="Timestamp">{formatDatetime(props.created_at)}</Descriptions.Item>
        //                     </Descriptions>
        //                 </Card>
        //             </Badge.Ribbon> : <Card hoverable size="small" title={props.user_name} extra={`UserID: ${props.user_id}`} onClick={() => handleClickIssue(props._id)} style={{ marginBottom: '16px', cursor: 'pointer' }}>
        //                 <Descriptions bordered title={props.channel} column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
        //                     <Descriptions.Item label="Source">{props.source}</Descriptions.Item>
        //                     <Descriptions.Item label="Timestamp">{formatDatetime(props.created_at)}</Descriptions.Item>
        //                 </Descriptions>
        //             </Card>
        //         }
        //     />
    );
}
export default IssueList