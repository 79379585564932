import { Avatar, Col, Row } from "antd"
import { prepareCloseAction, prepareForwardAction, selectSelectedSession } from "../privateSessionSlice"
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks"
import defaultProfile from '../../../res/default_profile.svg'
import * as constants from '../../../constants'

const PrivateSessionHeader = () => {

    const dispatch = useAppDispatch()
    const selectedSession = useAppSelector(selectSelectedSession)

    const handleForwardSession = () => {
        dispatch(prepareForwardAction())
    }

    const handleCloseSession = () => {
        dispatch(prepareCloseAction())
    }

    return (<>
        <Row style={{ height: '100%', width: '100%', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center' }}>
            <Col>
                <Row>
                    <Avatar
                        src={selectedSession.current.data?.user_avatar ? selectedSession.current.data?.user_avatar : defaultProfile}
                        style={{ marginLeft: '10px' }}
                    />
                    <span className='holding-profile-name'>
                        {selectedSession.current.data?.user_name}
                    </span>
                </Row>
            </Col>
            <Col style={{ backgroundColor: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 0 }}>
                <button
                    className="btn"
                    data={constants.FORWARD_BTN_LABEL}
                    onClick={handleForwardSession}></button>
                <button
                    onClick={handleCloseSession}
                    className="accept-button">
                    {constants.CLOSE_BTN_LABEL}
                </button>
            </Col>
        </Row>
    </>)
}
export default PrivateSessionHeader