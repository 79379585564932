import { createSlice } from "@reduxjs/toolkit";
import { getAgentSessions } from "../../app/services/agent";
import { getSession, getSessionHistory, getSessionUser } from "../../app/services/session";
import { getTagList } from "../../app/services/tag";


const initialState = {
    tagging_list :[],
    tagTree: null
}

export const issueTaggingSlice = createSlice({
    name: 'issueTagging',
    initialState,
    reducers: {
        addTaggingList: (state, action) => {
            state.tagging_list = [...state.tagging_list, action.payload]
        },
        clearTaggingList: (state, action) => {
            state.tagging_list = []
        },
        setTagTree: (state, action) => {
            state.tagTree = action.payload
        }
    },
    extraReducers: (builder) => {
        // Upload file 
        // builder.addCase(getTagList.pending, (state) => {
        //     state.tag_options.status = 'pending'
        // })
        // builder.addCase(getTagList.fulfilled, (state, action) => {
        //     state.tag_options.status = 'fulfilled'
        //     state.tag_options.data = action.payload
        // })
        // builder.addCase(getTagList.rejected, (state, action) => {
        //     state.tag_options.status = 'rejected'
        // })

    }
})

export const selectTaggingList = (store) => store.issueTagging.tagging_list
export const selectTagOptions = (store) => store.issueTagging.tag_options
export const selectTagTree = (store) => store.issueTagging.tagTree
export const { addTaggingList,
            clearTaggingList,
            setTagTree } = issueTaggingSlice.actions
export default issueTaggingSlice.reducer