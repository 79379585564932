import { Input, Layout } from "antd"
import IssueList from "../../../components/IssueList/IssueList"
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks"
import {
    resetHistory,
    selectSelectedSessionCurrent,
    selectSelectedSessionHistory,
    selectSelectedSessionId,
    selectSessionList,
    setNextHistoryPage,
    setSelectedSessionId
} from "../publicSessionSlice"
import { useEffect } from "react"
import '../publicSession.less'
import { selectIsSocketConnected } from "../../../hooks/socket/socketReducer"
import { getPublicSessionDetail, getPublicSessionHistory, getPublicSessionsForAgent } from "../publicSessionActions"

const PublicSessionList = () => {
    const dispatch = useAppDispatch()
    const isSocketConnected = useAppSelector(selectIsSocketConnected)
    const selectedSessionId = useAppSelector(selectSelectedSessionId)
    const sessionList = useAppSelector(selectSessionList)
    const selectedSessionCurrent = useAppSelector(selectSelectedSessionCurrent)
    const selectedSessionHistory = useAppSelector(selectSelectedSessionHistory)

    const handleClickSession = (sessionId) => {
        if (sessionId === selectedSessionId) {
            return
        }
        dispatch(setSelectedSessionId(sessionId))
        dispatch(getPublicSessionDetail({ sessionId: sessionId }))
    }

    useEffect(() => {
        dispatch(getPublicSessionsForAgent())
    }, [])
    // TODO: bug implement update list when new chat coming
    // TODO: Refactor this to hook > useSocket
    useEffect(() => {
        if (isSocketConnected) {
            dispatch({ type: 'socket/onIncomingMessage' })
            dispatch({ type: 'socket/onStatusChange' })
        }
    }, [isSocketConnected])
    useEffect(() => {
        return () => {
            dispatch({ type: 'socket/offIncomingMessage' })
            dispatch({ type: 'socket/offStatusChange' })
        }
    }, [])

    useEffect(() => {
        if (selectedSessionCurrent.status === 'pending') {
            dispatch(resetHistory())
        } else if (selectedSessionCurrent.status === 'fulfilled') {
            dispatch(getPublicSessionHistory({ userId: selectedSessionCurrent.data?.user_id, page: selectedSessionHistory.currentPage, limit: 10 }))
            dispatch(setNextHistoryPage())
        }
    }, [selectedSessionCurrent.status])

    return (
        <>
            {/* <Layout className='session-search-container'>
                <Input style={{ borderRadius: '60px', height: '44px', paddingLeft: '30px' }} placeholder='Search Messager' />
            </Layout> */}
            <Layout className='session-list-container'>
                <IssueList
                    selected={selectedSessionId}
                    issues={sessionList.data?.incoming}
                    handleClickSession={handleClickSession}
                />
            </Layout>
        </>
    )
}
export default PublicSessionList