import { Card, Col, Image, Row } from "antd"
import Linkify from 'react-linkify';
import Comment from "../Comment";

const ChatCard = ({ side, props }) => {
    let className
    if (props.sticker) {
        className = 'chat-sticker'
    } else if (props.image) {
        className = 'chat-image'
    } else if (props.video) {
        className = 'chat-video'
    } else {
        className = 'chat-text'
    }
    const extraContentType = (props) => {
        if (props.content) {
            return (
                <Linkify
                    componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                    ) => (
                        <a href={decoratedHref} key={key} target="_blank">
                            {decoratedText}
                        </a>
                    )}
                >
                    {props.content}
                </Linkify>
            )
        } else if (props.sticker) {
            return (<Image
                width={200}
                src={props.sticker}
            />)
        } else if (props.image) {
            return (<Image
                style={{ borderRadius: '5px' }}
                width={200}
                src={props.image}
            />)
        } else if (props.video) {
            return (
                <video
                    style={{ borderRadius: '20px', padding: '15px' }}
                    controls
                    width={200}
                    src={props.video} />
            )
        }
    }
    return (
        <Row>
            <Col
                span={12}
                offset={side === 'right' ? 12 : 0}>
                <Comment
                    author={props.author}
                    status={props.sending_status}
                    side={side}
                    datetime={props.datetime}
                    content={extraContentType(props)}
                />
            </Col>
        </Row>
    )
    // return (
    //     side === 'left' ?
    //         <Row className={className}>
    //             <Col span={12}>
    //                 {
    //                     props.content !== '' ?
    //                         <Comment
    //                             datetime={props.datetime}
    //                             author={props.author}
    //                             content={<Linkify
    //                                 componentDecorator={(
    //                                     decoratedHref,
    //                                     decoratedText,
    //                                     key
    //                                 ) => (
    //                                     <a href={decoratedHref} key={key} target="_blank">
    //                                         {decoratedText}
    //                                     </a>
    //                                 )}
    //                             >
    //                                 {props.content}
    //                             </Linkify>}
    //                         />
    //                         : <Comment
    //                             author={props.chat_sender_name}
    //                             datetime={props.datetime}
    //                         />
    //                 }
    //                 {
    //                     props.sticker ?
    //                         <Image
    //                             width={200}
    //                             src={props.sticker}
    //                         />
    //                         : <></>
    //                 }
    //                 {
    //                     props.image ?
    //                         <Image
    //                             style={{ borderRadius: '5px' }}
    //                             width={200}
    //                             src={props.image}
    //                         />
    //                         : <></>
    //                 }
    //                 {
    //                     props.video ?
    //                         <video
    //                             style={{ borderRadius: '20px', padding: '15px' }}
    //                             controls
    //                             width={200}
    //                             src={props.video} />
    //                         : <></>
    //                 }
    //             </Col>
    //         </Row> :
    //         <Row className={className + '-right'}>
    //             <Col span={12} offset={12}>
    //                 {
    //                     props.content !== '' ?
    //                         <Comment
    //                             status={props.sending_status}
    //                             datetime={props.datetime}
    //                             author={props.author}
    //                             content={<Linkify
    //                                 componentDecorator={(
    //                                     decoratedHref,
    //                                     decoratedText,
    //                                     key
    //                                 ) => (
    //                                     <a href={decoratedHref} key={key} target="_blank">
    //                                         {decoratedText}
    //                                     </a>
    //                                 )}
    //                             >
    //                                 {props.content}
    //                             </Linkify>}
    //                         />
    //                         : <Comment
    //                             author={props.chat_sender_name}
    //                             avatar={props.avatar}
    //                             datetime={props.datetime}
    //                             content={
    //                                 props.sticker ?
    //                                     <Image
    //                                         width={200}
    //                                         src={props.sticker}
    //                                     />
    //                                     : <></>
    //                             }
    //                         />
    //                 }
    //                 {

    //                 }
    //                 {
    //                     props.image ?
    //                         <Image
    //                             style={{ borderRadius: '5px' }}
    //                             width={200}
    //                             src={props.image}
    //                         />
    //                         : <></>
    //                 }
    //                 {
    //                     props.video ?
    //                         <video
    //                             width={200}
    //                             src={props.video} />
    //                         : <></>
    //                 }
    //             </Col>
    //         </Row>
    // )
}

export default ChatCard