import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../utils/api/api-client"
import { AGENT_INFO_API, AGENT_SESSIONS_API } from "../../utils/api/config";
import { getAccessTokenFromStorage } from "../../utils/localStorage/token";

export const getAgentSessions = createAsyncThunk('/agent/getUserSessions', async (
    payload,
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: AGENT_SESSIONS_API,
            headers: { "Authorization": `Bearer ${access_token}` },
        })
        console.log(response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const getAgentInfo = createAsyncThunk('/agent/getUserInfo', async (
    payload,
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: AGENT_INFO_API,
            headers: { "Authorization": `Bearer ${access_token}` },
        })
        console.log(response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

