import { createAsyncThunk } from "@reduxjs/toolkit"
import { AGENT_SESSIONS_API, FILE_UPLOAD, SESSION_ACTIVE_API, SESSION_ADD_TAG, SESSION_CLOSING_API, SESSION_DETAIL_API, SESSION_FORWARDING_API, SESSION_HISTORY_API, SESSION_HOLDING_API, SESSION_REOPEN_API, SESSION_REQUEST_API, SESSION_USER_API } from "../../utils/api/config"
import { getAccessTokenFromStorage } from "../../utils/localStorage/token"
import apiClient from "../../utils/api/api-client"

export const getPrivateSessionsForAgent = createAsyncThunk('/privateSession/getPrivateSessionsForAgent', async (
    payload,
    { rejectWithValue, dispatch }
) => {
    console.log('this get private is called')
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: AGENT_SESSIONS_API,
            headers: { "Authorization": `Bearer ${accessToken}` },
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const holdSession = createAsyncThunk('/privateSession/holdSession', async (
    { sessionId, additionalInfo },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_HOLDING_API}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            data: {
                session_id: sessionId,
                additional_info: additionalInfo
            }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const reopenSession = createAsyncThunk('/privateSession/reopenSession', async (
    { sessionId, additionalInfo },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_REOPEN_API}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            data: {
                session_id: sessionId,
                additional_info: additionalInfo
            }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const requestToSubmitPrivateSession = createAsyncThunk('/privateSession/requestToSubmitPrivateSession', async (
    { sessionId },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_REQUEST_API}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            data: {
                session_id: sessionId,
            }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getPrivateSessionHistory = createAsyncThunk('/privateSession/getPrivateSessionHistory', async (
    { userId, limit, page },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_HISTORY_API}/${userId}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            params: { limit, page }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})


export const getPrivateSessionDetail = createAsyncThunk('/privateSession/getPrivateSessionDetail', async (
    { sessionId },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_DETAIL_API}/${sessionId}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const uploadFileToChat = createAsyncThunk('/privateSession/uploadFileToChat', async (
    { file },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        let bodyFormData = new FormData()
        bodyFormData.append('file', file)
        console.log('file content ,',file)
        const response = await apiClient({
            method: "post",
            url: `${FILE_UPLOAD}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            data: bodyFormData
        })
        
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const addTagToSession = createAsyncThunk('/privateSession/addTagToSession', async (
    { sessionId, tag },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_ADD_TAG}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            data: {
                session_id: sessionId, 
                tag: tag
            }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const forwardPrivateSession = createAsyncThunk('/privateSession/forwardPrivateSession', async (
    { sessionId, additionalInfo, forwardingChannel },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_FORWARDING_API}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            data: {
                session_id: sessionId,
                additional_info: additionalInfo,
                forwarding_channel: forwardingChannel
            }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    } 
})

// export const requestAndForwardPrivateSession = createAsyncThunk('/privateSession/requestAndForwardPrivateSession', async (
//     { sessionId, additionalInfo, forwardingChannel },
//     { rejectWithValue, dispatch }
// ) => {
//     try{
//         dispatch(requestToSubmitPrivateSession({sessionId: sessionId}))
//         const forwardSessionRes = dispatch(forwardPrivateSession({
//             sessionId,
//             additionalInfo,
//             forwardingChannel
//         }))
//         return forwardSessionRes
//     } catch (error) {
//         return rejectWithValue(error.response)
//     } 
// })

export const closePrivateSession = createAsyncThunk('/privateSession/closePrivateSession', async (
    { sessionId, additionalInfo },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_CLOSING_API}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            data: {
                session_id: sessionId,
                additional_info: additionalInfo
            }
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getPriorSession = createAsyncThunk('/privateSession/getPriorSession', async (
    { userId },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_USER_API}/${userId}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})