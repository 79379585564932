import { useEffect, useMemo, useState } from "react"
import { io } from "socket.io-client";
import { socketClient } from "../../app/store";
import { getAccessTokenFromStorage } from "../../utils/localStorage/token";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectIsSocketConnected } from "./socketReducer";

export const useSocket = () => {
    const dispatch = useAppDispatch()
    const is_socket_connected = useAppSelector(selectIsSocketConnected)

    useEffect(() => {
        dispatch({ type: 'socket/connect' })
        dispatch({ type: 'socket/onConnect' })
        return () => {
            dispatch({ type: 'socket/disconnect' })
            dispatch({ type: 'socket/onDisconnect' })
        }
    }, [])

    // useEffect(() => {
    //     if(is_socket_connected) {
    //         dispatch({ type: 'socket/onIncomingMessage' })
    //         dispatch({ type: 'socket/onStatusChange' })
    //     }
    // }, [is_socket_connected])

    return

}

