import { Avatar } from "antd"
import {
    UserOutlined
} from '@ant-design/icons';
import lineIcon from '../../res/line_icon.svg'
// import circle from '../../res/circle.svg';
import { ReactComponent as Circle } from '../../res/circle.svg'

const ProfileIconWithStatus = (props) => {
    return (
        <div style={{position:'relative'}}>
            {/* <img
                style={{width: '10px', height:'10px', bottom:'0px',right:'0px', position:'absolute',borderRadius: '50%'}} 
                src={circle}
                alt="Avatar" /> */}
                <Circle 
                    fill={props.status_color}
                    style={{width: '10px', height:'10px', bottom:'0px',right:'0px', position:'absolute'}} />
            <img
                style={{width: '35px', height:'35px',borderRadius: '50%'}} 
                src={props.profileSrc} alt="Avatar" />

        </div>
    )
}
export default ProfileIconWithStatus