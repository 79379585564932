import { createSlice } from "@reduxjs/toolkit";
import {
    getPublicSessionDetail,
    getPublicSessionHistory,
    getPublicSessionsForAgent,
    requestToSubmitPublicSession
} from "./publicSessionActions";
import dayjs from "dayjs";

const initialState = {
    // forwardActionModal: {
    //     isVisible: false,
    //     forwardTo: '',
    //     errorMessage: ''
    // },
    errorModal: {
        isVisible: '',
        errorMessage: ''
    },
    sessionList: {
        data: null,
        status: 'idle'
    },
    selectedSession: {
        id: null,
        isSessionSelected: false,
        currentWithHistory: null,
        current: {
            data: null,
            status: 'idle',
        },
        history: {
            currentPage: 1,
            hasMore: true,
            previousPage: 0,
            data: null,
            status: 'idle'
        },
        requestForChat: {
            data: null,
            status: 'idle'
        },
    },
    sessionActionState: {
        forward: {
            isClicked: false,
            channelSelected: '',
            errorMessage: ''
        }
    }
}

export const publicSessionSlice = createSlice({
    name: 'PUBLIC_SESSION',
    initialState,
    reducers: {
        resetLobbyPageState: (state, action) => {
            console.log('this si calleasdf')
            // state = {
            //     ...initialState
            // }
            state.forwardActionModal = {
                ...initialState.forwardActionModal
            }
            state.errorModal = {
                ...initialState.errorModal
            }
            state.sessionList = {
                ...initialState.sessionList
            }
            state.selectedSession = {
                ...initialState.selectedSession
            }
            state.sessionActionState = {
                ...initialState.sessionActionState
            }
        },
        setSelectedSessionId: (state, action) => {
            console.log('action payload', action.payload)
            state.selectedSession = {
                ...initialState.selectedSession,
                id: action.payload,
                isSessionSelected: true
            }
        },
        setNextHistoryPage: (state, action) => {
            state.selectedSession.history = {
                ...state.selectedSession.history,
                currentPage: state.selectedSession.history.currentPage + 1
            }
        },
        resetHistory: (state, action) => {
            state.selectedSession.history = {
                ...initialState.selectedSession.history
            }
        },
        prepareForwardAction: (state, action) => {
            state.sessionActionState.forward = {
                ...state.sessionActionState.forward,
                isClicked: true
            }
        },
        cancelForwardAction: (state, action) => {
            state.sessionActionState.forward = {
                ...state.sessionActionState.forward,
                isClicked: false,
                channelSelected: ''
            }
        },
        chooseForwardChannel: (state, action) => {
            state.sessionActionState.forward = {
                ...state.sessionActionState.forward,
                channelSelected: action.payload
            }
        }
    },
    handleChooseForwardChannel: (state, action) => {
        state.forwardAction = {
            ...state.forwardAction,
            channelSelected: action.payload
        }
    },
    extraReducers: (builder) => {

        // Fetch agent session list
        builder.addCase(getPublicSessionsForAgent.pending, (state) => {
            state.sessionList.status = 'pending'
        })
        builder.addCase(getPublicSessionsForAgent.fulfilled, (state, action) => {
            state.sessionList.status = 'fulfilled'
            state.sessionList.data = action.payload
            if(state.sessionList.data?.incoming){
                let tempIncoming = [...state.sessionList.data?.incoming]
                state.sessionList.data.incoming = tempIncoming.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1))
            }
        })
        builder.addCase(getPublicSessionsForAgent.rejected, (state, action) => {
            state.sessionList.status = 'rejected'
        })

        // Request session for chat
        builder.addCase(requestToSubmitPublicSession.pending, (state) => {
            state.selectedSession.requestForChat.status = 'pending'

            state.errorModal = initialState.errorModal

        })
        builder.addCase(requestToSubmitPublicSession.fulfilled, (state, action) => {
            state.selectedSession.requestForChat.status = 'fulfilled'
            state.selectedSession.requestForChat.data = action.payload

            state.errorModal = initialState.errorModal
        })
        builder.addCase(requestToSubmitPublicSession.rejected, (state, action) => {
            state.selectedSession.requestForChat.status = 'rejected'

            state.errorModal = {
                errorMessage: 'มีคนรับเรื่องนี้ไปแล้วกรุณาเลือกเรื่องใหม่',
                isVisible: true
            }
            state.selectedSession = {
                ...state.selectedSession,
                isSessionSelected: false
            }
        })

        // Get session detail
        builder.addCase(getPublicSessionDetail.pending, (state) => {
            state.selectedSession.current.status = 'pending'
        })
        builder.addCase(getPublicSessionDetail.fulfilled, (state, action) => {
            state.selectedSession.current.status = 'fulfilled'
            state.selectedSession.current.data = action.payload
        })
        builder.addCase(getPublicSessionDetail.rejected, (state, action) => {
            state.selectedSession.current.status = 'rejected'
        })

        // Get session history
        builder.addCase(getPublicSessionHistory.pending, (state) => {
            state.selectedSession.history.status = 'pending'
        })
        builder.addCase(getPublicSessionHistory.fulfilled, (state, action) => {
            state.selectedSession.history.status = 'fulfilled'
            const fetchedHistory = action.payload
            const currentHistory = state.selectedSession.history.data
            // set history data
            if (fetchedHistory && fetchedHistory.length > 0) {
                if (currentHistory && currentHistory.length > 0) {
                    // if there is history merge new history with current history
                    state.selectedSession.history = {
                        ...state.selectedSession.history,
                        data: state.selectedSession.history.data.concat(fetchedHistory)
                    }
                } else {
                    // if no current history then set new history
                    state.selectedSession.history = {
                        ...state.selectedSession.history,
                        data: action.payload
                    }
                }
            } else if (action.payload && action.payload?.length === 0) {
                // no more history content
                state.selectedSession.history = {
                    ...state.selectedSession.history,
                    hasMore: false
                }
            }
        })
        builder.addCase(getPublicSessionHistory.rejected, (state, action) => {
            state.selectedSession.history.status = 'rejected'
        })
    }
})

export const selectSessionList = (store) => store.publicSession.sessionList
export const selectSelectedSession = (store) => store.publicSession.selectedSession
export const selectSelectedSessionId = (store) => store.publicSession.selectedSession.id
export const selectSelectedSessionHistory = (store) => store.publicSession.selectedSession.history
export const selectSelectedSessionCurrent = (store) => store.publicSession.selectedSession.current
export const selectForwardingState = (store) => store.publicSession.sessionActionState.forward
export const selectRequestForChatState = (store) => store.publicSession.selectedSession.requestForChat
export const { resetLobbyPageState,
    setSelectedSessionId,
    setNextHistoryPage,
    resetHistory,
    prepareForwardAction,
    cancelForwardAction,
    chooseForwardChannel } = publicSessionSlice.actions
export default publicSessionSlice.reducer