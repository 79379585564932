import { Modal, Select } from "antd"
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks"
import { cancelForwardAction, chooseForwardChannel, closeForward, selectForwardingState, selectSelectedSessionId } from "../publicSessionSlice"
import { useEffect } from "react"
import { forwardPublicSession, requestAndForwardPublicSession } from "../publicSessionActions"
import { selectAgentInfo } from "../../home/homeSlice"

const PublicSessionForwardModal = () => {

    const dispatch = useAppDispatch()
    const forwardingState = useAppSelector(selectForwardingState)
    const agentInfo = useAppSelector(selectAgentInfo)
    const selectedSessionId = useAppSelector(selectSelectedSessionId)

    // choose forward channel
    const handleChooseForwardChannel = (channel) => {
        dispatch(chooseForwardChannel(channel))
    }
    // confirm forward
    const handleConfirmForward = () => {
        dispatch(requestAndForwardPublicSession({
            sessionId: selectedSessionId,
            additionalInfo: '',
            forwardingChannel: forwardingState.channelSelected
        }))
        // dispatch(requestSessionForLobby({session_id: selectedSessionId}))
    }
    // cancel forward
    const handleCancelForward = () => {
        dispatch(cancelForwardAction())
    }

    // prevent handle case ซ้ำ
    useEffect(() => {
        
    }, [])

    // success forward
    useEffect(() => {
        console.log(agentInfo)
    }, [])

    return (<>
        <Modal title="Forward the chat"
            visible={forwardingState.isClicked}
            onOk={handleConfirmForward}
            onCancel={handleCancelForward}>
            <p>Forward to</p>
            <Select onChange={handleChooseForwardChannel} style={{ width: '100%' }}>
                {
                    agentInfo?.data?.forwarded_channel?.map((channel, i) => {
                        return (
                            <Select.Option key={i} value={channel}>{channel}</Select.Option>
                        )
                    })
                }
            </Select>
        </Modal >
    </>

    )
}
export default PublicSessionForwardModal