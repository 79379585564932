import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom";
import { exchangeToken } from "../../app/services/auth";
import { GetValueFromHash } from "../../utils/url/hash";
import { checkTokenValid } from "../../utils/verifyToken"
import { selectAuth } from "./authReducer";
import { useAppDispatch, useAppSelector } from '../hooks';

export const useAuth = () => {
    
    const location = useLocation();
    const hash = location.hash
    const tokenHash = GetValueFromHash(hash, 'token')
    
    const dispatch = useAppDispatch()

    const authState = useAppSelector(selectAuth)

    const [isAuth, setIsAuth] = useState(false)

    useEffect(()=>{
        if(tokenHash){
            dispatch(exchangeToken({token: tokenHash}))
        }else{
            const is_token_valid = checkTokenValid()
            setIsAuth(is_token_valid)
        }
    },[])

    useEffect(()=>{
        console.log(authState.status)
        if (authState.status === 'fulfilled'){
            console.log("this is called")
            setIsAuth(true)
        }else if(authState.status === 'rejected'){
            setIsAuth(false)
        }
    },[authState.status])

    return isAuth
}

