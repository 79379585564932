import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAccessTokenFromStorage } from "../../utils/localStorage/token"
import apiClient from "../../utils/api/api-client"
import { TAG_LIST } from "../../utils/api/config"

export const getTagList = createAsyncThunk('/agent/getTagList', async (
    payload,
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: TAG_LIST,
            headers: { "Authorization": `Bearer ${access_token}` },
        })
        console.log(response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})