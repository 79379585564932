import { Col, Row } from "antd"
import './comment.less'

const Comment = (props) => {
    // author
    // datetime
    // content
    // status
    const transformSendingStatus = (status) => {
        switch (status) {
            case 'pending': {
                return 'กำลังส่งข้อความ'
            }
            case 'success': {
                return 'สำเร็จ'
            }
            case 'failed': {
                return 'ส่งข้อความไม่สำเร็จ'
            }
            default: {
                return 'สำเร็จ'
            }
        }
    }
    // let sendingStatusText = ''
    // switch (props.sending_status) {
    //     case 'pending': {
    //         sendingStatusText = 'กำลังส่งข้อความ'
    //         break
    //     }
    //     case 'success': {
    //         sendingStatusText = 'สำเร็จ'
    //     }
    //     case 'failed': {
    //         sendingStatusText = 'ส่งข้อความไม่สำเร็จ'
    //     }
    // }

    return (<div className={props.side + "-comment-container"}>
        <Row className={props.side + "-content-container"}>
            <Row className="header-container">
                <span className="author">{props.author}</span>
                <span className="datetime">{props.datetime}</span>
            </Row>
        </Row>
        <Row className={props.side + "-content-container"}>
            <Row>
                {props.side === "right" ? <Col className={props.side + "-status"}>{transformSendingStatus(props.status)}</Col> : <></>} 
                <Col className={(props.status === 'success' || props.status === undefined) ? props.side + "-content" : props.side + "-content-pale"}>{props.content}</Col>
            </Row>
        </Row>
    </div>)
}
export default Comment