import { Layout, Menu, Modal, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import logoBw from '../../res/logo_bw.svg';
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import './home.less'
import { ReactComponent as InboxLogo } from '../../res/inbox_logo.svg'
import { ReactComponent as ChatLogo } from '../../res/chat_logo.svg'
import { ReactComponent as IssueManagementLogo } from '../../res/issue_management_logo.svg'
import { LoadingOutlined, CheckOutlined, CloseOutlined, PoweroffOutlined } from '@ant-design/icons';
import Chat from "../chat/Chat";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { selectSession } from "../../features/chat/chatSlice";
import { requestSession } from "../../app/services/session";
import { selectActiveChat, selectAgentInfo, selectSocketStatus } from "./homeSlice";
import { getActiveChatIDFromStorage } from "../../utils/localStorage/activeChat";
import { getStorageActiveMenuID, getStorageMenuIsCollapsed, setStorageActiveMenuID, setStorageMenuIsCollapsed } from "../../utils/localStorage/menu";
import ProfileIconWithStatus from "../../components/ProfileIconWithStatus";
import vulcanIcon from '../../res/vulcan_icon.svg'
import { resetLocalStorage, setLocalStorage } from "../../utils/localStorage";
import { STORAGE_AUTH_KEY } from "../../utils/localStorage/constant";
import IssueManagement from "../issuemanagement/IssueManagement";
import { holding_message_menu_label, incoming_message_menu_label, issue_management_menu_label } from "../../wording";
import PublicSession from "../PublicSession";
import { getAgentInfo, getTagInfo } from './homeActions';
import PrivateSession from '../PrivateSession';
import { selectIsSocketConnected } from '../../hooks/socket/socketReducer';
import * as constants from '../../constants'
import { DashboardOutlined } from '@ant-design/icons';
import { getAccessTokenFromStorage } from '../../utils/localStorage/token';


export const Home = () => {

    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [component_displayed, setComponent] = useState('incoming')
    const [selected_menu, setSelectedMenu] = useState('1')
    const session = useAppSelector(selectSession)
    const active_chat = getActiveChatIDFromStorage()
    const dispatch = useAppDispatch()
    const agent = useAppSelector(selectAgentInfo)
    const socket_status = useAppSelector(selectSocketStatus)
    const isSocketConnected = useAppSelector(selectIsSocketConnected)
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
    const [socket_status_display, setSocketStatusDisplay] = useState({
        icon: <LoadingOutlined />,
        message: 'connecting',
        color: '#F8D210'
    })
    // const socket_status_icon = useAppSelector(<LoadingOutlined />)
    const [temp_margin, setTempMargin] = useState(null)

    const getItem = (label,
        key,
        icon,
        children) => {
        return {
            key,
            icon,
            children,
            label,
        };
    }
    const handleClickLogo = () => {
        navigate('/')
    }

    const components = {
        "incoming": <PublicSession />,
        "holding": <PrivateSession />
    }
    useEffect(() => {
        switch (getStorageActiveMenuID()) {
            case '1': {
                navigate('/')
                return
            }
            case '2': {
                navigate('/issue')
                return
            }
            case '3': {
                navigate('issue-management')
                return
            }
            default: {
                navigate('/')
                return
            }
        }
    }, [])
    useEffect(() => {
        console.log('menu changed', getStorageActiveMenuID())
        setSelectedMenu(getStorageActiveMenuID())
    }, [getStorageActiveMenuID()])
    const handleMenuClicked = (event) => {
        switch (event?.key) {
            case '1': {
                // setComponent('incoming')
                setStorageActiveMenuID('1')
                navigate('/')

                break
            }
            case '2': {
                setStorageActiveMenuID('2')

                if (active_chat) {
                    // TODO : check this 
                    // dispatch(requestSession({ session_id: active_chat }))
                }
                navigate('/issue')
                // setComponent('holding')
                break
            }
            case '3': {
                setStorageActiveMenuID('3')

                navigate('/issue-management')
                // setComponent('holding')
                break
            }
            case '4': {
                window.open(`/v2/#/dashboard/${getAccessTokenFromStorage()}`, '_blank');
                // setComponent('holding')
                break
            }
            case '5': {
                break
            }
            case '0': {
                // navigate('/')
                // setLocalStorage(STORAGE_AUTH_KEY, {}, true)
                resetLocalStorage()
                window.location.reload()
                break
            }
        }
    }
    const menuItems = [
        getItem('Incoming messages', '1', <InboxLogo />),
        getItem('Holding messages', '2', <ChatLogo />),
        // user profile
        // getItem(<div>{agent.data.email}</div>, '6', <ChatLogo />)
        // socket online status
    ];

    useEffect(() => {
        setCollapsed(getStorageMenuIsCollapsed())
    }, [])

    useEffect(() => {
        dispatch(getAgentInfo())
        dispatch(getTagInfo())
    }, [])

    useEffect(() => {
        if (isSocketConnected) {
            setSocketStatusDisplay({
                ...socket_status_display,
                message: 'connected',
                icon: <CheckOutlined />,
                color: '#2FF3E0'
            })
        } else {
            setSocketStatusDisplay({
                ...socket_status_display,
                message: 'disconnected',
                icon: <CloseOutlined />,
                color: '#F51720'
            })
        }
    }, [isSocketConnected])

    return (
        <Layout hasSider style={{ height: "100vh" }}>
            <Sider
                trigger=""
                style={{ background: 'linear-gradient(180deg, #4ADEDE 0%, #1AA7EC 100%)' }}
                breakpoint="lg"
                width='220px'
                // collapsedWidth="0"
                onBreakpoint={(broken) => {
                }}
                defaultCollapsed={false}
                collapsible collapsed={collapsed} onCollapse={(value) => {
                    setStorageMenuIsCollapsed(value)
                    setCollapsed(value)
                }
                }
            >
                <div className="logo" >
                    <img src={logoBw}
                    // onClick={handleClickLogo}
                    //  style={{ width: '150px' }} 
                    />
                </div>
                <Menu
                    style={{ background: 'transparent' }}
                    onClick={handleMenuClicked}
                    // theme='dark'
                    selectedKeys={[selected_menu]}
                    defaultSelectedKeys={[selected_menu]}
                    mode='inline'
                // items={menuItems} 
                >

                    <Menu.Item key={'1'} icon={<InboxLogo />}>
                        {constants.LOBBY_MENU_LABEL}
                    </Menu.Item>
                    <Menu.Item key={'2'} icon={<ChatLogo />}>
                        {constants.PRIVATE_CHAT_MENU_LABEL}
                    </Menu.Item>
                    <Menu.Item key={'3'} icon={<IssueManagementLogo />}>
                        {constants.SESSION_MANAGEMENT_MENU_LABEL}
                    </Menu.Item>
                    {
                        agent?.data?.access_type === 1 ?
                            <Menu.Item key={'4'} icon={<DashboardOutlined style={{ fontSize: '220%' }} />}>
                                {constants.DASHBOARD_MENU_LABEL}
                            </Menu.Item> : <></>
                    }


                    <Menu.Item key={'0'} icon={<PoweroffOutlined width={"31px"} height={"31px"} />}
                        style={{
                            marginBottom: 96,
                            position: 'absolute',
                            bottom: 0,
                            zIndex: 1,
                            transition: 'all 0.2s',
                        }}>
                        LOGOUT
                    </Menu.Item>
                    {/* Online status and User profile */}
                    <Menu.Item
                        icon={<ProfileIconWithStatus
                            profileSrc={vulcanIcon}
                            status_color={socket_status_display.color} />}
                        style={{
                            marginBottom: 48,
                            position: 'absolute',
                            bottom: 0,
                            zIndex: 1,
                            transition: 'all 0.2s',
                        }}>
                        <div style={{ marginLeft: '5px' }}>
                            {/* Agent1 Test */}
                            {agent.data?.name}
                        </div>
                    </Menu.Item>
                    {/* <Menu.Item
                        style={{
                            marginBottom: 48 + 40*(agent.data?.attended_channel?.length),
                            position: 'absolute',
                            bottom: 0,
                            zIndex: 1,
                            transition: 'all 0.2s',
                        }}>
                        {agent.data?.name}
                    </Menu.Item>
                    {
                        agent.data?.attended_channel?.map((item, i) => {
                            const marginBottom = 48 + 40*i
                            return (
                                <Menu.Item
                                    style={{
                                        marginLeft: '20px',
                                        marginBottom: marginBottom,
                                        position: 'absolute',
                                        bottom: 0,
                                        zIndex: 1,
                                        transition: 'all 0.2s',
                                    }}>
                                    {item}
                                </Menu.Item>
                            )
                        })
                    }
                    <Menu.Item
                        style={{
                            marginBottom: 88 + 40*(agent.data?.attended_channel?.length),
                            position: 'absolute',
                            bottom: 0,
                            zIndex: 1,
                            transition: 'all 0.2s',
                        }}
                        icon={socket_status_display.icon}
                    >
                        {socket_status_display.message}

                    </Menu.Item> */}

                </Menu>


            </Sider>
            <Layout>
                <Content>
                    <Routes>
                        <Route path="" element={<PublicSession />} />
                        <Route path='issue' element={<PrivateSession />} />
                        <Route path="issue-management" element={<IssueManagement />} />
                    </Routes>


                    {/* {components[component_displayed]} */}
                </Content>
            </Layout>
        </Layout>
    )
}

export default Home