import { Input, Row } from "antd"
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks"
import { resetChatHistory, resetChatState, resetSelectedSessionState, selectSelectedSession, selectSelectedSessionId, selectSession, setActive, setSelectedSessionId } from "../privateSessionSlice"
import { Header } from "antd/lib/layout/layout"
import IssueListPrivate from "../../../components/IssueListPrivate/IssueListPrivate"
import * as constants from '../../../constants'
import { useEffect } from "react"
import { activeSession, getPrivateSessionDetail, getPrivateSessionsForAgent, holdSession, reopenSession, requestToSubmitPrivateSession } from "../privateSessionActions"
import { getActiveChatIDFromStorage, setActiveChatToStorage } from "../../../utils/localStorage/activeChat"

const PrivateSessionList = () => {
    const dispatch = useAppDispatch()
    const selectedSession = useAppSelector(selectSelectedSession)
    const sessionList = useAppSelector(selectSession)
    const selectedSessionId = useAppSelector(selectSelectedSessionId)

    useEffect(() => {
        dispatch(getPrivateSessionsForAgent())
    }, [])

    const handleClickIssue = (newId) => {

        if (newId === selectedSessionId) {
            return
        }

        if (selectedSessionId) {
            // hold current session 
            dispatch(holdSession({
                sessionId: selectedSessionId,
                additionalInfo: selectedSession.additionalInfo
            }))
        }

        // set new active session
        // some how history is not resetted

        setActiveChatToStorage({ id: newId })
        dispatch(setSelectedSessionId(newId))
        // dispatch(requestToSubmitPrivateSession({ sessionId: newId }))
        // window.location.reload()
    }

    useEffect(() => {
        if (selectedSessionId) {
            dispatch(getPrivateSessionDetail({
                sessionId: selectedSessionId
            }))
            // dispatch reopen
            dispatch(reopenSession({ sessionId: selectedSessionId }))
            
            dispatch(setActive({session_id: selectedSessionId}))
        }
    }, [selectedSessionId])


    useEffect(() => {
        console.log('active chat id ', getActiveChatIDFromStorage())
        const activeChatId = getActiveChatIDFromStorage()
        dispatch(setSelectedSessionId(activeChatId))

        // dispatch(setActive({session_id: getActiveChatIDFromStorage()}))
        // if (getActiveChatIDFromStorage()) {
        //     dispatch(reopenSession({ session_id: getActiveChatIDFromStorage() }))
        // }
    }, [])

    return (
        <>
            <Row style={{ height: '10%', width: '100%', backgroundColor: '#ffffff' }}>
                <Header className='holding-header-text'>
                    {constants.HOLDING_MESSAGE_MENU_LABEL}
                </Header>
            </Row>
            {/* <Row className='holding-search-container' style={{ height: '7%', width: '100%', backgroundColor: '#ffffff' }}>
                <Input className='holding-search' placeholder='Search Messager' />
            </Row> */}
            <Row className='holding-container' style={{ height: '90%', width: '100%', backgroundColor: '#ffffff' }}>
                <IssueListPrivate
                    selected={selectedSession.id}
                    issues={sessionList.data?.holding}
                    handleClickIssue={handleClickIssue}
                // loading={is_loading_issues} 
                />
            </Row>
        </>
    )
}

export default PrivateSessionList