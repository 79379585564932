import { createSlice } from "@reduxjs/toolkit"
import { getTagList } from "../../app/services/tag"
import { getAgentInfo, getTagInfo } from "./homeActions"


const initialState = {
    active_chat: '',
    socket_status : 'idle', // idle ,connected, disconnected
    agentInfo: {
        data: null,
        status: 'idle'
    },
    tagOptions: {
        data: null,
        status: 'idle'
    }
}

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setActiveChat: (state, action) => {
            state.active_chat = action.payload
        },
        setSocketStatus: (state, action) => {
            state.socket_status = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTagInfo.pending, (state) => {
            state.tagOptions.status = 'pending'
        })
        builder.addCase(getTagInfo.fulfilled, (state, action) => {
            state.tagOptions.status = 'fulfilled'
            state.tagOptions.data = action.payload
        })
        builder.addCase(getTagInfo.rejected, (state, action) => {
            state.tagOptions.status = 'rejected'
        })

        builder.addCase(getAgentInfo.pending, (state) => {
            state.agentInfo.status = 'pending'
        })
        builder.addCase(getAgentInfo.fulfilled, (state, action) => {
            state.agentInfo.status = 'fulfilled'
            state.agentInfo.data = action.payload
        })
        builder.addCase(getAgentInfo.rejected, (state, action) => {
            state.agentInfo.status = 'rejected'
        })
    }
})

export const { setActiveChat, setSocketStatus } = homeSlice.actions
export const selectActiveChat = (store) => store.home.active_chat
export const selectSocketStatus = (store) => store.home.socket_status
export const selectAgentInfo = (store) => store.home.agentInfo
export const selectTagOptions = (store) => store.home.tagOptions.data.tag
export default homeSlice.reducer