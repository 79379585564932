import axios from 'axios'
import { redirect } from "react-router-dom";
import { setLocalStorage } from '../localStorage';
import { STORAGE_AUTH_KEY } from '../localStorage/constant';

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    console.log('error is called')
    if (error.response.status === 401) {
        // store.dispatch('logout')
        setLocalStorage(STORAGE_AUTH_KEY, {}, true)
        window.location.reload()
    }
    return Promise.reject(error)
})

export default axios