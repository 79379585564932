import { Avatar, Button, Divider, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatDatetime } from "../../utils/datetime";
import ChatCard from "../ChatCard/ChatCard";
import defaultProfile from '../../res/default_profile.svg'


const ChatList = ({ chats, history, is_loaded, next, has_more, container_id }) => {

    // session_id(pin):"6471ee8d7179610e50fa4909"
    // chat_sender_id(pin):"U809747fe3c07e606163ad9bb52c1b1eb"
    // chat_sender_name(pin):"inn,"
    // chat_sender_avatar(pin):"https://sprofile.line-scdn.net/0hY13Y-Fu2Bh4eIBNq-tt4YW5wBXQ9UV8MMkYbeCpwXyojERFBM0cbfiMjXykrFUhKZU8eKCh0W3oSM3F4AHb6KhkQWCknFklLMkVK_w"
    // timestamp(pin):"2023-05-27T11:50:37.020000"
    // is_agent(pin):false
    // type(pin):"text"
    // chat_message(pin):"Test ส่งข่อความ"

    // sending_status [sent, pending, failed]

    let reveresed = []

    if (chats) {
        reveresed = [...chats].reverse()
    }
    if (history) {
        reveresed = reveresed.concat({ component_type: 'history_divide' }).concat(history)
    }
    //  chats.reverse()

    return (
        <div
            id={container_id}
            style={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
                padding: '15px'
            }}>

            <InfiniteScroll
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                inverse={true}
                dataLength={reveresed?.length}
                next={next}
                hasMore={has_more}
                loader={<Skeleton
                    avatar
                    paragraph={{
                        rows: 1,
                    }}
                    active
                />}
                endMessage={<Divider plain>สิ้นสุดประวัติการแชท</Divider>}
                scrollableTarget={container_id}
            >
                {reveresed.map((props, index) => {
                    if (props.component_type === 'history_divide') {
                        return <Divider key={index} plain>ประวัติการแชท</Divider>
                    }
                    var formattedData
                    if (props.is_agent) {
                        formattedData = {
                            author: props.chat_sender_name,
                            // avatar: props.chat_sender_avatar ? props.chat_sender_avatar : 'https://joeschmoe.io/api/v1/random',
                            content: props.chat_message,
                            datetime: formatDatetime(props.timestamp),
                            sticker: props.chat_sticker_url,
                            image: props.chat_image_url,
                            video: props.chat_video_url,
                            sending_status: props.sending_status
                        }
                    } else {
                        formattedData = {
                            author: props.chat_sender_name,
                            avatar: props.chat_sender_avatar ? props.chat_sender_avatar : defaultProfile,
                            content: props.chat_message,
                            datetime: formatDatetime(props.timestamp),
                            sticker: props.chat_sticker_url,
                            image: props.chat_image_url,
                            video: props.chat_video_url,
                            sending_status: props.sending_status
                        }
                    }

                    return (
                        <div key={index}>
                            <ChatCard
                                side={props.is_agent ? 'right' : 'left'}
                                props={formattedData} />
                        </div>
                    )
                }
                )}
                {/* <List
                    dataSource={chats}
                    header={`${chats?.length} ${chats?.length > 1 ? 'replies' : 'reply'}`}
                    itemLayout='horizontal'
                    renderItem={(props) => {
                        let formattedData = {
                            author: props.chat_sender_name,
                            avatar: 'https://joeschmoe.io/api/v1/random',
                            content: <p>{props.chat_message}</p>,
                            datetime: props.timestamp
                        }
                        return (
                            <ChatCard side={props.is_agent ? 'right' : 'left'} props={formattedData} />
                        )
                    }
                    }
                /> */}
            </InfiniteScroll>

        </div>

    )
}


export default ChatList