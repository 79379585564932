import { Avatar, Button, Col, Row } from "antd"
import { UserOutlined } from '@ant-design/icons';
import './chatPreview.less'
import { useEffect } from "react";
import { useAppSelector } from "../../hooks/hooks";
import ChatList from "../ChatList/ChatList";
import { selectSession } from "../../features/lobby/lobbySlice";
import { accept_btn_label, forward_btn_label } from "../../wording";



const ChatPreview = ({ current, history, isSelected, containerId, hasMore, next, handleForward, handleSubmit }) => {
    console.log(current)
    console.log(isSelected)
    return (
        <Row style={{ height: '100%' }}>
            <Col
                className="profile-container">
                {
                    isSelected ?
                        <>
                            <Avatar
                                style={{ margin: '15px' }}
                                icon={<UserOutlined />}
                                src={current?.user_avatar}
                            />
                            <span className="profile-name">{current?.user_name}</span></> :
                        <></>
                }
            </Col>
            <Col
                className="chat-preview-container"
                style={{ height: '80%', width: '100%', backgroundColor: '#F0F2F5' }}>
                {
                    isSelected ?
                        <ChatList
                            container_id={containerId}
                            // className='chatlist'
                            history={history}
                            chats={current?.conversations}
                            next={next}
                            has_more={hasMore}
                        /> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                            Preview Message
                        </div>
                }


            </Col>
            <Col className="preview-action-container">
                {
                    isSelected ? <>
                        <button
                            onClick={() => handleForward()}
                            className="btn" data={forward_btn_label}></button>
                        <button
                            onClick={() => handleSubmit(current._id)}
                            className="accept-button">
                            {accept_btn_label}
                        </button>
                    </> : <></>
                }
            </Col>
        </Row>
    )
}
export default ChatPreview