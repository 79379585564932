import { AGENT_INFO_API, TAG_LIST } from "../../utils/api/config"
import { getAccessTokenFromStorage } from "../../utils/localStorage/token"
import apiClient from "../../utils/api/api-client"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getAgentInfo = createAsyncThunk('/home/getAgentInfo', async (
    payload,
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: AGENT_INFO_API,
            headers: { "Authorization": `Bearer ${accessToken}` },
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getTagInfo = createAsyncThunk('/home/getTagInfo', async (
    payload,
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: TAG_LIST,
            headers: { "Authorization": `Bearer ${accessToken}` },
        })
        return response.data
    } catch (error) {
    return rejectWithValue(error.response)
    }
})


