import { createAsyncThunk } from "@reduxjs/toolkit"
import { AGENT_SESSIONS_API, SESSION_DETAIL_API, SESSION_FORWARDING_API, SESSION_HISTORY_API, SESSION_REQUEST_API } from "../../utils/api/config"
import { getAccessTokenFromStorage } from "../../utils/localStorage/token"
import apiClient from "../../utils/api/api-client"

export const getPublicSessionsForAgent = createAsyncThunk('/publicSession/getPublicSessionsForAgent', async (
    payload,
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: AGENT_SESSIONS_API,
            headers: { "Authorization": `Bearer ${accessToken}` },
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getPublicSessionDetail = createAsyncThunk('/publicSession/getPublicSessionDetail', async (
    { sessionId },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_DETAIL_API}/${sessionId}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getPublicSessionHistory = createAsyncThunk('/session/getPublicSessionHistory', async (
    { userId, limit, page },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_HISTORY_API}/${userId}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            params: { limit, page }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const requestToSubmitPublicSession = createAsyncThunk('/publicSession/requestToSubmitPublicSession', async (
    { sessionId },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_REQUEST_API}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            data: {
                session_id: sessionId,
            }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const forwardPublicSession = createAsyncThunk('/publicSession/forwardPublicSession', async (
    { sessionId, additionalInfo, forwardingChannel },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_FORWARDING_API}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                session_id: sessionId,
                additional_info: additionalInfo,
                forwarding_channel: forwardingChannel
            }
        })
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    } 
})

export const requestAndForwardPublicSession = createAsyncThunk('/publicSession/requestAndForwardPublicSession', async (
    { sessionId, additionalInfo, forwardingChannel },
    { rejectWithValue, dispatch }
) => {
    try{
        dispatch(requestToSubmitPublicSession({sessionId: sessionId}))
        const forwardSessionRes = dispatch(forwardPublicSession({
            sessionId,
            additionalInfo,
            forwardingChannel
        }))
        return forwardSessionRes
    } catch (error) {
        return rejectWithValue(error.response)
    } 
})


