import { useNavigate } from "react-router-dom"
import { setStorageActiveMenuID } from "../../../utils/localStorage/menu"
import { Modal } from "antd"
import { useAppSelector } from "../../../hooks/hooks"
import { useDispatch } from "react-redux"
import { closePrivateSession } from "../privateSessionActions"
import { cancelCloseAction, resetPrivateChatInitState, selectCloseSessionState, selectInputNote, selectSelectedSessionId } from "../privateSessionSlice"
import { resetActiveChatFromStorage } from "../../../utils/localStorage/activeChat"

const PrivateSessionCloseModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const closeSessionState = useAppSelector(selectCloseSessionState)
    const selectedSessionId = useAppSelector(selectSelectedSessionId)
    const inputNote = useAppSelector(selectInputNote)

    const toLobbyPage = () => {
        setStorageActiveMenuID('1')
        navigate('/')
        resetActiveChatFromStorage()
    }

    const handleConfirmCloseSession = () => {
        //TODO: update additional info
        dispatch(closePrivateSession({
            sessionId: selectedSessionId,
            additionalInfo: inputNote
        }))
        dispatch(resetPrivateChatInitState())
        toLobbyPage()
    }
    const handleCancelCloseSession = () => {
        dispatch(cancelCloseAction())
    }

    return (
        <Modal title="Close the chat" 
            visible={closeSessionState.isClicked} 
            onOk={handleConfirmCloseSession} 
            onCancel={handleCancelCloseSession}>
            <p>Are you sure?</p>
            <p>you are going to close the issue.</p>
        </Modal>
    )
}
export default PrivateSessionCloseModal