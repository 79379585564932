import { Avatar, Card, Col, Descriptions, Row } from "antd"
import Meta from "antd/lib/card/Meta"
import { Content } from "antd/lib/layout/layout"
import dayjs from "dayjs"
import AdditionalInfoList from "../../components/AdditionalInfoList/AdditionalInfoList"
import { formatDatetime } from "../../utils/datetime"
import defaultProfile from '../../res/default_profile.svg'
import './userOverview.less'

const UserOverview = (props) => {
    console.log('user overview', props)
    console.log(props?.session)
    return (
        <Row style={{
            // padding: 24,
            // minHeight: 280,
            // display: 'flex',
            // flexFlow: 'column',
            height: '100%'
        }}>
            <Row>
                <Col span={5}>
                    <Avatar
                        src={props?.session?.user_avatar ? props?.session?.user_avatar : defaultProfile}
                        style={{ width: '80px', height: '80px' }} />
                </Col>
                <Col span={19}>
                    <Descriptions
                        size="small"
                        colon={false}
                        // title={props?.session?.user_name} 
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}>
                        <Descriptions.Item
                            contentStyle={{
                                color: '#202124',
                                fontFamily: 'Noto Sans Thai',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '13px',
                                lineHeight: '20px'
                            }}
                            labelStyle={{
                                color: '#202124',
                                fontFamily: 'Noto Sans Thai',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '13px',
                                lineHeight: '20px'
                            }}
                            label="User ID"
                            span={4}>{props?.session?.user_name}</Descriptions.Item>
                        {/* <Descriptions.Item label="Session ID" span={4}>{props?.session?._id}</Descriptions.Item> */}
                        <Descriptions.Item
                            contentStyle={{
                                color: '#202124',
                                fontFamily: 'Noto Sans Thai',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px'
                            }}
                            labelStyle={{
                                color: '#202124',
                                fontFamily: 'Noto Sans Thai',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '13px',
                                lineHeight: '20px'
                            }}
                            label="Channel" span={4}>{props?.session?.channel}</Descriptions.Item>
                        <Descriptions.Item
                            contentStyle={{
                                color: '#202124',
                                fontFamily: 'Noto Sans Thai',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px'
                            }}
                            labelStyle={{
                                color: '#202124',
                                fontFamily: 'Noto Sans Thai',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '13px',
                                lineHeight: '20px'
                            }}
                            label="Source" span={4}>{props?.session?.source}</Descriptions.Item>
                        {
                            props?.session?.closed_at ?
                                <Descriptions.Item
                                    contentStyle={{
                                        color: '#202124',
                                        fontFamily: 'Noto Sans Thai',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        lineHeight: '18px'
                                    }}
                                    labelStyle={{
                                        color: '#202124',
                                        fontFamily: 'Noto Sans Thai',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '13px',
                                        lineHeight: '20px'
                                    }}
                                    label="Created At" span={4}>{formatDatetime(props?.session?.created_at)}
                                </Descriptions.Item> :
                                <>
                                </>

                        }
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                
            </Row>


        </Row>
    )
}


export default UserOverview
