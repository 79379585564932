import { createSlice } from "@reduxjs/toolkit";
import { exchangeToken } from "../../app/services/auth";
import { getSessionForLobby, getSessionHistoryForLobby, requestSessionForLobby } from "../../app/services/session";
import { getAgentInfo, getAgentSessions } from "../../app/services/agent";


const initialState = {
    user: {
        data: null,
        status: 'idle'
    },
    issues: {
        data: null,
        status: 'idle'
    },
    session: {
        data: null,
        status: 'idle',
        request: {
            data: null,
            status: 'idle' 
        }
    },
    session_history: {
        data: null,
        status: 'idle'
    },
}

export const lobbySlice = createSlice({
    name: 'lobby',
    initialState,
    reducers: {
        resetLobbyPageState: (state, action) => {
            state.user = {
                data: null,
                status: 'idle'
            }
            state.issues = {
                data: null,
                status: 'idle'
            }
            state.session = {
                data: null,
                status: 'idle',
                request: {
                    data: null,
                    status: 'idel' 
                }
            }
            state.session_history = {
                data: null,
                status: 'idle'
            }
        }
    },
    extraReducers: (builder) => {
        // Fetch user data
        builder.addCase(getAgentInfo.pending, (state) => {
            state.user.status = 'pending'
        })
        builder.addCase(getAgentInfo.fulfilled, (state, action) => {
            state.user.status = 'fulfilled'
            state.user.data = action.payload
        })
        builder.addCase(getAgentInfo.rejected, (state, action) => {
            state.user.status = 'rejected' 
        })
        // Fetch user sessions
        builder.addCase(getAgentSessions.pending, (state) => {
            state.issues.status = 'pending'
        })
        builder.addCase(getAgentSessions.fulfilled, (state, action) => {
            state.issues.status = 'fulfilled'
            state.issues.data = action.payload
        })
        builder.addCase(getAgentSessions.rejected, (state, action) => {
            state.issues.status = 'rejected' 
        })
        // Request session
        builder.addCase(requestSessionForLobby.pending, (state) => {
            state.session.request.status = 'pending'
        })
        builder.addCase(requestSessionForLobby.fulfilled, (state, action) => {
            state.session.request.status = 'fulfilled'
            state.session.request.data = action.payload
        })
        builder.addCase(requestSessionForLobby.rejected, (state, action) => {
            state.session.request.status = 'rejected' 
        })

        builder.addCase(getSessionForLobby.pending, (state) => {
            state.session.status = 'pending'
        })
        builder.addCase(getSessionForLobby.fulfilled, (state, action) => {
            state.session.status = 'fulfilled'
            state.session.data = action.payload
        })
        builder.addCase(getSessionForLobby.rejected, (state, action) => {
            state.session.status = 'rejected'
        })

        builder.addCase(getSessionHistoryForLobby.pending, (state) => {
            state.session_history.status = 'pending'
        })
        builder.addCase(getSessionHistoryForLobby.fulfilled, (state, action) => {
            state.session_history.status = 'fulfilled'
            state.session_history.data = action.payload
        })
        builder.addCase(getSessionHistoryForLobby.rejected, (state, action) => {
            state.session_history.status = 'rejected'
        })
    }
})

export const selectUser = (store) => store.lobby.user
export const selectIssues = (store) => store.lobby.issues
export const selectSession = (store) => store.lobby.session
export const selectSessionHistory = (store) => store.lobby.session_history
export const { resetLobbyPageState } = lobbySlice.actions
export default lobbySlice.reducer