import { createSlice } from "@reduxjs/toolkit";
import { exchangeToken, login } from "../../app/services/auth";

const initialState = {
    auth: {
        access_token: null,
        status:'idle'
    }
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(exchangeToken.pending, (state) => {
            state.auth.status = 'pending'
        })
        builder.addCase(exchangeToken.fulfilled, (state, action) => {
            state.auth.status = 'fulfilled'
            state.auth.access_token = action.payload.access_token
        })
        builder.addCase(exchangeToken.rejected, (state, action) => {
            state.auth.status = 'rejected' 
        })

        builder.addCase(login.pending, (state) => {
            state.auth.status = 'pending'
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.auth.status = 'fulfilled'
            state.auth.access_token = action.payload.access_token
        })
        builder.addCase(login.rejected, (state, action) => {
            state.auth.status = 'rejected' 
        })
    }
})

export const selectAuth = (store) => store.auth.auth
export default authSlice.reducer