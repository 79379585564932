import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../utils/api/api-client"
import { AUTH_EXCHANGE_API, AUTH_LOGIN_API } from "../../utils/api/config";
import { setLocalStorage } from "../../utils/localStorage";
import { STORAGE_AUTH_KEY } from "../../utils/localStorage/constant";

export const exchangeToken = createAsyncThunk('/auth/exchange', async(
    { token },
    { rejectWithValue, dispatch }
) => {
    try {
        let bodyFormData = new FormData()
        bodyFormData.append('token', token)
        const response = await apiClient({
            method: "post",
            url: AUTH_EXCHANGE_API,
            headers: { "Content-Type": "multipart/form-data" },
            data: bodyFormData  
        })
        console.log(response)
        let formatted_response = {
            access_token: response.data.access_token,
            token_type: response.data.refresh_token
        }
        console.log('formatted access token response ', formatted_response)
        setLocalStorage(STORAGE_AUTH_KEY, formatted_response)
        return formatted_response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const login = createAsyncThunk('/auth/login', async(
    { username, password },
    { rejectWithValue, dispatch }
) => {
    try {
        let bodyFormData = new FormData()
        bodyFormData.append('username', username)
        bodyFormData.append('password', password)
        const response = await apiClient({
            method: "post",
            url: AUTH_LOGIN_API,
            headers: { "Content-Type": "multipart/form-data" },
            data: bodyFormData
        })
        console.log(response)
        let formatted_response = {
            access_token: response.data.access_token,
            token_type: response.data.refresh_token
        }
        console.log('formatted access token response ', formatted_response)
        setLocalStorage(STORAGE_AUTH_KEY, formatted_response)
        return formatted_response
    } catch (error) {
        return rejectWithValue(error.response)
    }
})