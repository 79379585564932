import { getLocalStorage } from "."
import { STORAGE_AUTH_KEY } from "./constant"
import jwt_decode from "jwt-decode";

export const getAccessTokenFromStorage = () => {
    return getLocalStorage(STORAGE_AUTH_KEY)?.access_token
}
export const getRefreshTokenFromStorage = () => {
    return getLocalStorage(STORAGE_AUTH_KEY)?.refresh_token
}

export const getTokenData = (field_name) => {
  let access_token_data = getAccessTokenFromStorage()

    if (access_token_data == null) { //one tab logged out, be sure to log out for all
        return null

    } else { //in case if token exists but can still be expired

        let decodedToken = jwt_decode(access_token_data);

        return decodedToken[field_name]
    }
}

export const getUserIDFromToken = () => {
    return getTokenData("user_id")
}

export default getTokenData;
