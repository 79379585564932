import { Card, Col, Divider, Input, Radio, Row, Select, Space, Tag } from "antd"
import { Content } from "antd/lib/layout/layout"
import { useEffect, useState } from "react"
// import { issue_tagging_options } from "./options"
import { LoadingOutlined, CheckOutlined, CloseOutlined, PoweroffOutlined } from '@ant-design/icons';
import './issueTagging.less'
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
// import { selectTaggingList, addTaggingList, clearTaggingList } from "./issueTaggingSlice";
import { clear_tagging_btn_label, save_tagging_btn_label } from "../../wording";
import { getTagList } from "../../app/services/tag";
import { flushSync } from "react-dom";
import { selectTagOptions } from "../home/homeSlice";



const IssueTagging = ({ parent_type, tagging_list, setTaggingList, on_save }) => {

    // display main categories
    const [display_main_array, setDisplayMainArray] = useState([])
    // display pop ups
    const [display_sub_array, setDisplaySubArray] = useState([])

    const [main_array_data, setMainArrayData] = useState([])

    const [sub_array_data, setSubArrayData] = useState([])

    const [input, setInput] = useState([])

    const [needClearing, setNeedClearing] = useState(false)

    // const [tagging_list, setTaggingList] = useState([])

    const dispatch = useAppDispatch();
    // const tagging_list = useAppSelector(selectTaggingList)
    const issue_tagging_options = useAppSelector(selectTagOptions)

    const [error, setError] = useState({
        is_error: false,
        error_message: ''
    })
    // const [issue_tagging_options, setIssueTaggingOptions] = useState(null)

    // useEffect(() => {
    //     dispatch(getTagList())
    // },[])

    useEffect(() => {
        // do not perform update when need clearing is false
        if (!needClearing) {
            return
        }
        console.log("ahah 2")
        let dup_options = issue_tagging_options
        let a = {
            ...dup_options,
            choices: generateIDForTree(dup_options?.choices)
        }
        console.log('after generate', a)
        flushSync(() => {
            setMainArrayData([])
            setDisplayMainArray([])
            setSubArrayData([])
            setDisplaySubArray([])
            setInput([])
        })
        setMainArrayData(a.choices)
        setDisplayMainArray(renderDisplayMainArray(a.choices))
        setNeedClearing(false)
    }, [needClearing])

    // useEffect(() => {
    //     switch(tag_options?.status){
    //         case 'fulfilled': {
    //             console.log('tag_options',tag_options)
    //             setIssueTaggingOptions({
    //                 data: tag_options?.data?.tag
    //             })
    //             break
    //         }
    //     }
    // },[tag_options?.status, tag_options?.data?.tag])

    useEffect(() => {
        if (issue_tagging_options) {

            console.log('tag option ', issue_tagging_options)
            let dup_options = issue_tagging_options
            let a = {
                ...dup_options,
                choices: generateIDForTree(dup_options?.choices)
            }
            console.log('after generate', a, issue_tagging_options)
            setMainArrayData(a?.choices)
            setDisplayMainArray(renderDisplayMainArray(a?.choices))
        }
    }, [issue_tagging_options])

    const removeDuplicateKeyFromArray = (arr) => {
        return arr.filter((thing, index, self) => self.findIndex(t => t.key === thing.key) === index)
    }

    const removeSameKeyFromArr = (arr, key) => {
        return arr.filter(obj => obj.key != key)
    }

    // const generateIDForTree = (issue_tagging_options, hist_id = '0') => {
    //     for (let i = 0; i < issue_tagging_options?.length; i++) {
    //         issue_tagging_options[i].id = '' + hist_id + '' + i
    //         issue_tagging_options[i].parent_id = hist_id
    //         generateIDForTree(issue_tagging_options[i].choices, hist_id + '' + i)
    //     }
    // }

    // const generateIDForTree = (issue_tagging_options, hist_id = '0') => {

    //     for (let i = 0; i < issue_tagging_options?.length; i++) {
    //         issue_tagging_options[i].id = '' + hist_id + '' + i
    //         issue_tagging_options[i].parent_id = hist_id
    //         generateIDForTree(issue_tagging_options[i].choices, hist_id + '' + i)
    //     }
    //     generateIDForTree
    // }

    const generateIDForTree = (issue_tagging_options, hist_id = '0') => {
        console.log(issue_tagging_options)

        return issue_tagging_options.map((x, i) => {
            if (x.choices) {
                return {
                    ...x,
                    id: '' + hist_id + '' + i,
                    parent_id: hist_id,
                    choices: generateIDForTree(x.choices, hist_id + '' + i)
                }
            }
            else {
                return {
                    ...x,
                    id: '' + hist_id + '' + i,
                    parent_id: hist_id,
                }
            }

        })
    }

    useEffect(() => {
        const uniqueArray = removeDuplicateKeyFromArray(sub_array_data)
        console.log(uniqueArray)
    }, [sub_array_data])


    const removeSameParentMembers = (arr, current_parent_id) => {
        let newArr = []
        let removedArr = []
        arr.map(item => {
            console.log('current_parent_id', current_parent_id)
            console.log('item parent_id', item.parent_id)
            if (item.parent_id !== current_parent_id) {
                newArr.push(item)
                console.log('1')
            } else {
                // เก็บ array ตัวที่โดนเอาออก 
                removedArr.push(item)
                console.log('2')
            }
        })
        console.log('new arr', newArr)
        console.log('removedArr', removedArr)

        let lastNewArr = []
        if (removedArr.length !== 0) {
            // วน array ตัวที่โดนเอาออก แล้วดูว่ามีอันไหนอยู่ใต้มันบ้าง เอาออก
            newArr.map(item => {
                removedArr.map(remove_item => {
                    const remove_item_id_length = remove_item.id.length
                    const item_id_length = item.id.length
                    console.log('remove item id ', remove_item.id)
                    console.log('item_id ', item.id)
                    if (remove_item_id_length < item_id_length && item.id.substring(0, remove_item_id_length) === remove_item.id) {

                    } else {
                        lastNewArr.push(item)
                    }
                })
            })
        } else {
            lastNewArr = newArr
        }

        console.log('last new arr', lastNewArr)
        return lastNewArr
    }

    useEffect(() => {
        console.log(sub_array_data)
        return () => {

        }
    }, [sub_array_data])

    useEffect(() => {
        console.log(input)
        return () => {

        }
    }, [input])


    const treeHandler = (choice) => {
        switch (choice.inputType) {
            case 'multiple': {
                // เลือกได้หลายอัน
                // สร้าง radio group
                // ถ้า parent ซ้ำไม่เป็นไร
                // แต่ถ้า key ซ้ำเอาออก !
                let options = []
                // generate Select options
                choice?.choices?.map(choice_item => {
                    options.push({
                        label: choice_item.description,
                        value: JSON.stringify(choice_item)
                    })
                })
                const handleChange = (value) => {
                    console.log(value)
                    console.log(sub_array_data)
                    if (value.length > 1) {
                        value.map(item => {
                            console.log(item)
                            const jsonValue = JSON.parse(item)
                            // setSubArrayData(prevArr=> [...prevArr, jsonValue])
                            setSubArrayData(prevArr => {
                                return removeDuplicateKeyFromArray([...prevArr, jsonValue])
                            })
                            setInput(prevArr => {
                                return removeDuplicateKeyFromArray([...prevArr, jsonValue])
                            })
                        })
                    } else if (value.length === 1) {
                        console.log(value)
                        const jsonValue = JSON.parse(value)
                        // setSubArrayData(prevArr=> [...prevArr, jsonValue])
                        setSubArrayData(prevArr => {
                            return removeDuplicateKeyFromArray([...prevArr, jsonValue])
                        })
                        setInput(prevArr => {
                            return removeDuplicateKeyFromArray([...prevArr, jsonValue])
                        })
                    } else if (value.length === 0) {
                        console.log('weradf')
                        return
                    }
                }
                const handleClear = () => {
                    setSubArrayData(prevArr => [...removeSameParentMembers(prevArr, choice.id)])
                    setInput(prevArr => [...removeSameParentMembers(prevArr, choice.id)])
                }
                const handleDeselect = (value) => {
                    console.log('value voiii', JSON.parse(value).id)
                    let jsonValue = JSON.parse(value)
                    console.log('sub_ar', sub_array_data)
                    setSubArrayData(prevArr => [...removeSameKeyFromArr(prevArr, jsonValue.key)])
                    setInput(prevArr => [...removeSameKeyFromArr(prevArr, jsonValue.key)])
                }
                return choice.choices?.map(choice_item => {
                    return treeHandler(choice_item)
                })
                // return <div key={choice.key}>
                //     {choice.description}
                //     <Select
                //         onDeselect={handleDeselect}
                //         allowClear
                //         onClear={handleClear}
                //         mode="multiple"
                //         key={choice.key}
                //         showSearch
                //         // onSearch={onSearchProduct}
                //         filterOption={(input, option) =>
                //             (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                //         }
                //         defaultValue={[]}
                //         style={{ width: '100%' }}
                //         // status={is_extra ? 'warning' : ''}
                //         bordered
                //         onChange={handleChange}
                //         options={options}
                //     />
                // </div>
                // return <div key={choice.key}>
                //     {choice.description}
                //     <div>
                //         <Radio.Group onChange={handleChange} value="">
                //             {choice.choices.map(choice_item => {
                //                 return <Radio key={choice_item.key}
                //                     value={JSON.stringify(choice_item)}>{choice_item.description}</Radio>
                //             })}
                //         </Radio.Group>
                //     </div>
                // </div>
            }
            case 'mutual': {
                // เลือกได้อันเดียว
                // สร้าง select ขึ้นมามี choice ตามหัวข้อด้านใน
                // เมื่อเลือกก็ add choice เข้าไป
                // ถ้า parent ซ้ำ ต้องเลือกใหม่
                let options = []
                // generate Select options
                choice?.choices?.map(choice_item => {
                    options.push({
                        label: choice_item.description,
                        value: JSON.stringify(choice_item)
                    })
                })
                const handleChange = (value) => {
                    if (value) {
                        const jsonValue = JSON.parse(value)
                        // setSubArrayData(prevArr=> [...prevArr, jsonValue])
                        setSubArrayData(prevArr => {
                            return removeDuplicateKeyFromArray([...removeSameParentMembers(prevArr, jsonValue.parent_id), jsonValue])
                        })
                        setInput(prevArr => {
                            return removeDuplicateKeyFromArray([...removeSameParentMembers(prevArr, jsonValue.parent_id), jsonValue])
                        })
                    }
                }
                const handleClear = () => {
                    setSubArrayData(prevArr => [...removeSameParentMembers(prevArr, choice.id)])
                    setInput(prevArr => [...removeSameParentMembers(prevArr, choice.id)])
                }
                return <div key={choice.key}>
                    {choice.description}
                    <Select
                        allowClear
                        onClear={handleClear}
                        key={choice.key}
                        showSearch
                        // onSearch={onSearchProduct}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        defaultValue=""
                        style={{ width: '100%' }}
                        // status={is_extra ? 'warning' : ''}
                        bordered
                        onChange={handleChange}
                        options={options}
                    />
                </div>
            }
            case 'property': {
                // ต้องเลือกทุกอัน
                // สร้าง list ของ item ข้างใน choice เป็น type อะไรก็สร้างตามนั้น
                //recursive again
                return renderDisplaySubArray(choice.choices)
            }
            case 'text': {
                // ใส่ text field
                const handleChange = (event) => {

                    let newChoice = choice
                    newChoice.value = event.target.value
                    // const jsonValue = JSON.parse(value)
                    // setSubArrayData(prevArr=> [...prevArr, jsonValue])
                    // setSubArrayData(prevArr => {
                    //     return removeDuplicateKeyFromArray([...removeSameParentMembers(prevArr, newChoice.parent_id), newChoice])
                    // })
                    setInput(prevArr => {
                        return removeDuplicateKeyFromArray([...prevArr, newChoice])
                    })

                }
                return <div key={choice.key}>
                    {choice.description}
                    <Input onChange={handleChange} />
                </div>
            }
            default: {
                return
            }
        }
    }


    const renderDisplayMainArray = (main_array) => {
        console.log(main_array)
        return main_array?.map(choice => {
            return treeHandler(choice)
        })
    }

    useEffect(() => {
        setDisplaySubArray(renderDisplaySubArray(sub_array_data))
    }, [sub_array_data])

    const renderDisplaySubArray = (sub_array) => {
        return sub_array.map(choice => {
            return treeHandler(choice)
        })
    }

    const transformIntoResult = (dup_options, current) => {
        return current
    }
    const saveResult = () => {
        console.log('save result is called')
        let dup_options = issue_tagging_options
        const result = transformIntoResult(dup_options, sub_array_data)
        console.log('result ', result)
    }

    const checkAnswer = (question) => {
        const current_answers = [...input]
        console.log('current answers', input)
        // let x = []
        switch (question.inputType) {
            case 'multiple': {
                let answersOfQuestion = []

                question.choices.map(choice => {

                    // let isAnswered = false

                    // current_answers?.map(current_answer => {
                    //     console.log(current_answer.key, question)
                    //     console.log(choice.key)
                    //     // ถ้า input key ตรงกับ choice key แสดงว่ามีการตอบคำถามนี้
                    //     if (current_answer.key === choice.key) {
                    //         console.log('found', current_answer.key, question)
                    //         isAnswered = true
                    //         console.log('multiple', choice)
                    //         answersOfQuestion.push({
                    //             value: checkAnswer(choice)
                    //         })
                    //         console.log(answersOfQuestion)
                    //         // x = 
                    //         // x.push(checkAnswer(choice))
                    //     }
                    // })

                    let x
                    x = checkAnswer(choice)
                    if (choice.required && choice.required === true) {
                        if (!x) {
                            throw new Error('ไม่มีข้อมูล ' + choice.description)
                        }
                        answersOfQuestion.push(x)
                    } else {
                        if(x){
                            answersOfQuestion.push(x) 
                        }
                    }

                    // if (!isAnswered && question.required) {
                    //     console.log('question ที่ require', question)
                    //     console.log(current_answers)
                    //     throw new Error('ขาดข้อมูลบางอย่าง ' + question.description)
                    //     // setError({
                    //     //     is_error:true,
                    //     //     error_message: 'ขาดข้อมูลบางอย่าง ' + question.description
                    //     // })
                    // }
                })
                if (answersOfQuestion.length > 0) {
                    return { key: question.key, value: answersOfQuestion, description: question.description }
                } else {
                    return { key: question.key, description: question.description }
                }
            }
            case 'mutual': {
                console.log('mutual', question)
                // let x = []
                let x = {}
                let isAnswered = false
                question.choices.map(choice => {
                    current_answers?.map(current_answer => {
                        // console.log('choice' , choice, current_answer)
                        // ถ้า input key ตรงกับ choice key แสดงว่ามีการตอบคำถามนี้
                        if (current_answer.key === choice.key && current_answer.parent_id === question.id) {
                            console.log('found!', current_answer.key)
                            // x.push(checkAnswer(choice))
                            isAnswered = true
                            x = checkAnswer(choice)
                            // x = 
                            // x.push(checkAnswer(choice))
                        }
                    })
                })
                if (!isAnswered && question.required) {
                    throw new Error('ไม่มีข้อมูล ' + question.description)
                    // setError({
                    //     is_error: true,
                    //     error_message: 'ไม่มีข้อมูล '+ question.description
                    // })
                }
                // if (!x) {
                //     throw new Error('ต้องเลือกอย่างน้อยหนึ่ง choice จาก ' + question.description)
                //     // setError({
                //     //     is_error: true,
                //     //     error_message: 'ต้องเลือกอย่างน้อยหนึ่ง choice จาก ' + question.description
                //     // })
                // }
                if (x?.key) {
                    return { key: question.key, value: x, description: question.description }
                } else {
                    return null
                }
            }
            case 'property': {
                let x = []
                console.log('property', question.choices)
                question.choices.map(choice => {
                    let answer = checkAnswer(choice)
                    if (!answer) {
                        throw new Error('ขาดข้อมูล ' + choice.description)
                        // setError({
                        //     is_error: true,
                        //     error_message: 'ขาดข้อมูล ' + choice.description
                        // })
                    } else {
                        x.push(checkAnswer(choice))
                    }
                })
                x.map(xItem => {
                    if (!xItem) {
                        // error data not complete
                        throw new Error('ข้อมูลไม่ครบ')
                        // setError({
                        //     is_error: true,
                        //     error_message: 'ข้อมูลไม่ครบ'
                        // })
                    }
                })
                return { key: question.key, value: x, description: question.description }
            }
            case 'text': {
                let value
                let isAnswered = false
                // console.log('this is called')
                current_answers?.map(current_answer => {
                    // ถ้า input key ตรงกับ choice key แสดงว่ามีการตอบคำถามนี้
                    if (current_answer.key === question.key) {
                        isAnswered = true
                        if (current_answer.value === '') {
                            isAnswered = false
                        } else {
                            value = { key: question.key, value: current_answer.value, description: question.description }

                            // SPECIAL CASE ORDER NUMBER
                            // add ช่องทางการสั่งซื้อ
                            // if(question.key === 'order_no'){
                            //     console.log('order number is set')
                            //     console.log(current_answer.value)
                            // }

                        }
                        // console.log('this is also called', current_answer)
                    }
                })
                if ((!isAnswered && question.required)) {
                    throw new Error('ไม่มีข้อมูล ' + question.description)
                    // setError({
                    //     is_error: true,
                    //     error_message: 'ไม่มีข้อมูล '+ question.description
                    // })
                }
                return value
            }
            default: {
                let value
                let isAnswered = false
                current_answers?.map(current_answer => {
                    // ถ้า input key ตรงกับ choice key แสดงว่ามีการตอบคำถามนี้
                    if (current_answer.key === question.key) {
                        isAnswered = true
                        // console.log('this is also called', current_answer)
                        value = { key: current_answer.key, description: current_answer.description }
                    }
                })
                if (!isAnswered && question.required) {
                    throw new Error('ไม่มีข้อมูล ' + question.description)
                    // setError({
                    //     is_error: true,
                    //     error_message: 'ไม่มีข้อมูล '+ question.description
                    // })
                }
                return value
                // let value
                // console.log('this is called')
                // current_answers?.map(current_answer => {
                //     // ถ้า input key ตรงกับ choice key แสดงว่ามีการตอบคำถามนี้
                //     if (current_answer.key === question.key) {
                //         console.log('this is also called', current_answer)
                //         value = current_answer.key
                //     }
                // })
                // return value
            }
        }
    }
    const transformTagging = (tagging) => {

    }

    // useEffect(() => {
    //     return (() => {
    //             dispatch(clearTaggingList())
    //     })
    // }, [])

    useEffect(() => {
        console.log(tagging_list)
    }, [tagging_list])


    const handleRemoveTagList = async (list_index) => {
        console.log('index', list_index)
        let tagList
        await setTaggingList(prev => {
            tagList = [...prev]
            tagList.splice(list_index, 1)

            return tagList
        })
        on_save(tagList)
        // call 
    }

    const renderTaggingList = (tagging_item, parent_desc, list_index) => {

        console.log('list_idnex', list_index)

        const renderList = (tagging_item, parent_desc, list_index) => {
            if (Array.isArray(tagging_item.value)) {
                console.log('bababababa', tagging_item.value)
                return (
                    <div wrap key={tagging_item + 'array'}>
                        {tagging_item.description}
                        <div>

                            <Tag key={tagging_item + 'array'} color="red" style={{ padding: '5px' }}>

                                {
                                    tagging_item.value.map((item, index) => {
                                        if (item) {
                                            if (Array.isArray(item.value)) {
                                                console.log(item)
                                                return (
                                                    item.value.map(value_list => {
                                                        return (<div wrap >
                                                            {renderList(value_list, item.description)}
                                                        </div>)
                                                    })
                                                    // renderList(item.value, item.description)

                                                    // <div key={item + index}>
                                                    //     {/* ถ้าไม่มี value แสดงว่าเป็นปลายสุดของการ select
                                                    // ถ้า value เป็น string แสดงว่า เป็น ปลายสุดของ input text */}
                                                    //     {renderList(item.value, item.description)}
                                                    // </div>
                                                )
                                            } else {
                                                console.log(item)
                                                return (
                                                    <div wrap >
                                                        {renderList(item.value, item.description)}
                                                    </div>
                                                    // renderList(item.value, item.description)

                                                    // <div key={item + index}>
                                                    //     {/* ถ้าไม่มี value แสดงว่าเป็นปลายสุดของการ select
                                                    // ถ้า value เป็น string แสดงว่า เป็น ปลายสุดของ input text */}
                                                    //     {renderList(item.value, item.description)}
                                                    // </div>
                                                )
                                            }

                                        }
                                    })
                                }
                            </Tag>

                        </div>
                    </div>
                )
            } else {
                if (tagging_item.value) {
                    return (
                        <div style={{ padding: '5px' }}>
                            <Tag color="blue" style={{ padding: '5px' }}>
                                {tagging_item.description}
                                {
                                    renderList(tagging_item.value, tagging_item.description)}
                            </Tag>
                        </div>
                    )
                } else {
                    if (typeof (tagging_item) === 'string') {
                        return (
                            <Tag style={{ padding: '5px' }}>{parent_desc}: {tagging_item}</Tag>
                            // <div>
                            //     {parent_desc}{tagging_item.description} {tagging_item}
                            // </div>
                        )
                    } else {
                        return (
                            <Tag style={{ padding: '5px' }}>{parent_desc}: {tagging_item.description}</Tag>
                            // <div>
                            //     {parent_desc}{tagging_item.description} 
                            // </div>
                        )
                    }

                }
            }
        }




        return (
            <Card size="small" style={{ width: '100%', overflow: 'auto' }}
                extra={<CloseOutlined style={{ cursor: 'pointer' }} onClick={() => handleRemoveTagList(list_index)} />} >
                <Space wrap style={{ width: '100%' }} >
                    {renderList(tagging_item, parent_desc)}
                </Space>
            </Card>

        )
    }


    if (parent_type === 'auto') {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ width: '100%', height: '90%', overflow: 'auto' }}>

                    <div>
                        {display_main_array}
                        <Divider plain />
                        {display_sub_array}
                    </div>

                    <div style={{ width: '100%' }} >

                        {/* Tagging list display */}
                        {/* {tagging_list_display} */}
                        {tagging_list?.map((tag, index) => {
                            console.log('i', index)
                            return renderTaggingList(tag, null, index)
                        })}

                    </div>

                </div>
                <Row style={{ height: '10%' }}>
                    <Col span={12} style={{ alignSelf: 'center' }}>
                        <div>มีข้อมูลเพิ่มเติมทั้งหมด {tagging_list?.length} รายการ</div>
                        {error.is_error ?
                            <div style={{ width: '100%', color: 'red' }}>ERROR: {error.error_message}</div>
                            : <></>}
                    </Col>
                    {/* <button
                        className="btn"
                        data={clear_tagging_btn_label}
                        onClick={() => {
                            setError({
                                is_error: false,
                                error_message: ''
                            })
                            setTaggingList([])
                            // dispatch(clearTaggingList())
                            // setInput([])
                        }}></button> */}
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                            // style={{textAlign:'right'}}
                            onClick={() => {
                                setError({
                                    is_error: false,
                                    error_message: ''
                                })
                                try {
                                    const results = []
                                    let dup_options = issue_tagging_options
                                let a = {
                                    ...dup_options,
                                    choices: generateIDForTree(dup_options?.choices)
                                }
                                    a.choices.map(option => {
                                        const ans = checkAnswer(option)
                                        if (ans) {
                                            results.push(checkAnswer(option))
                                        }
                                    })
                                    console.log('resutls', results)
                                    console.log('error', error.is_error)
                                    let tag_list
                                    setTaggingList(prev => {
                                        tag_list = [...prev, { key: 'initiate', value: results }]
                                        return tag_list
                                    }
                                    )
                                    setNeedClearing(true)
                                    on_save(tag_list)
                                    // dispatch(addTaggingList({ key: 'initiate', value: results }))

                                } catch (e) {
                                    setError({
                                        is_error: true,
                                        error_message: e.message
                                    })
                                }

                            }}
                            className="accept-button" style={{ marginLeft: 'auto' }} >{save_tagging_btn_label}</button>
                    </Col>

                </Row>
            </div>
        )
    } else {
        return (
            <Content style={{ height: '100%', width: '100%' }}>


                <Row style={{ width: '100%' }}>
                    <Col span={12} >
                        {display_main_array}
                    </Col>
                    <Col span={12} >
                        {display_sub_array}
                    </Col>
                </Row>
                {error.is_error ?
                    <div style={{ width: '100%', height: '10%', textAlign: 'right', color: 'red' }}>{error.error_message}</div>
                    : <></>}
                <div style={{ width: '100%', height: '10%', textAlign: 'right' }}>
                    {/* <button
                        className="btn"
                        data={clear_tagging_btn_label}
                        onClick={() => {
                            setError({
                                is_error: false,
                                error_message: ''
                            })
                            setTaggingList([])
                            // dispatch(clearTaggingList())
                            // setInput([])
                        }}></button> */}
                    <button
                        onClick={() => {
                            setError({
                                is_error: false,
                                error_message: ''
                            })
                            try {
                                const results = []
                                let dup_options = issue_tagging_options
                                let a = {
                                    ...dup_options,
                                    choices: generateIDForTree(dup_options?.choices)
                                }
                                a.choices.map(option => {
                                    const ans = checkAnswer(option)
                                    if (ans) {
                                        results.push(checkAnswer(option))
                                    }
                                })
                                console.log('resutls', results)
                                console.log('error', error.is_error)
                                setTaggingList(prev => [...prev, { key: 'initiate', value: results }])
                                // dispatch(addTaggingList({ key: 'initiate', value: results }))
                                setNeedClearing(true)

                            } catch (e) {
                                setError({
                                    is_error: true,
                                    error_message: e.message
                                })
                            }

                        }}
                        className="accept-button" style={{ marginLeft: 'auto' }} >{save_tagging_btn_label}</button>
                </div>
                <Row style={{ width: '100%' }} >

                    {/* Tagging list display */}
                    {/* {tagging_list_display} */}
                    {tagging_list?.map((tag, index) => {
                        return renderTaggingList(tag, null, index)
                    })}

                </Row>
            </Content>

        )
    }

}
export default IssueTagging