import { Avatar, Card, Comment, Descriptions, List } from "antd"
import Meta from "antd/lib/card/Meta"
import dayjs from "dayjs"
import { formatDatetime } from "../../utils/datetime"
import { no_activity, no_activity_label } from "../../wording"
import './additionalInfoList.less'

const AdditionalInfoList = (props) => {

    console.log('AdditionalInfoList props', props)
    return (
        props?.session_user && props?.session_user?.length > 1 ?

            <>
                <List
                    style={{width:'100%', height:'100%', overflow:'auto'}}
                    dataSource={props?.session_user}
                    renderItem={item => {
                        return (
                            item.type === 'closed' || item.type === 'forwarded' ?

                                <div className="info-item-container">
                                    <span className="info-item-name">{item.agent_email}</span>
                                    <span className="info-item-name">( {item.type} )</span>
                                    <span className="info-item-date">{formatDatetime(item.closed_at)}</span>
                                    {
                                        item.additional_info ?
                                            <div className="info-item-content-container">
                                                <span className="info-item-content">{item.additional_info}</span>
                                            </div> :
                                            <></>
                                    }
                                </div>

                                : <></>
                        )

                        // return (
                        //     item.type === 'closed' || item.type === 'forwarded' ?
                        //         <Descriptions bordered title={item.agent_email} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                        //             <Descriptions.Item label="type">{item.type}</Descriptions.Item>
                        //             <Descriptions.Item label="created_at">{formatDatetime(item.created_at)}</Descriptions.Item>
                        //             <Descriptions.Item label="closed_at">{formatDatetime(item.closed_at)}</Descriptions.Item>
                        //             <Descriptions.Item label="Additional Info">{item.additional_info ? item.additional_info : "-"}</Descriptions.Item>
                        //         </Descriptions>
                        //         : <></>
                        // )
                    }
                    } />
            </>
            : <> {no_activity_label} </>
    )
}

export default AdditionalInfoList