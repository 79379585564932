import { FILE_UPLOAD } from "../../utils/api/config"
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../utils/api/api-client"
import { getAccessTokenFromStorage } from "../../utils/localStorage/token";

export const uploadFile = createAsyncThunk('/file/upload', async (
    { file },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        let bodyFormData = new FormData()
        bodyFormData.append('file', file)
        const response = await apiClient({
            method: "post",
            url: `${FILE_UPLOAD}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: bodyFormData
        })
        console.log('uploadFile', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})