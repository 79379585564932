import { Col, Row } from "antd"
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks"
import { appendChatWithPendingStatus, resetChatImageState, resetChatText, selectSelectedInfoCompoment, selectSelectedSession, selectSelectedSessionChatIamge, selectSelectedSessionChatImage, selectSelectedSessionChatText, selectSelectedSessionHistory, selectSelectedSessionId, setChatText, setNextHistoryPage } from "../privateSessionSlice"
import ChatList from "../../../components/ChatList/ChatList"
import { getPriorSession, getPrivateSessionDetail, getPrivateSessionHistory, uploadFileToChat } from "../privateSessionActions"
import { useEffect, useRef, useState } from "react"
import sendBtnIcon from '../../../res/send_btn.svg'
import addItemIcon from '../../../res/add_item.svg'
import { CloseOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea"
import { selectIsSocketConnected } from "../../../hooks/socket/socketReducer"
import { v4 as uuidv4 } from 'uuid';
import { selectAgentInfo } from "../../home/homeSlice"

const PrivateSessionChat = () => {

    const hiddenFileInput = useRef()

    const dispatch = useAppDispatch()
    const selectedSession = useAppSelector(selectSelectedSession)
    const selectedSessionHistory = useAppSelector(selectSelectedSessionHistory)
    const selectedSessionId = useAppSelector(selectSelectedSessionId)
    const currentChatText = useAppSelector(selectSelectedSessionChatText)
    const currentChatImage = useAppSelector(selectSelectedSessionChatImage)
    const isSocketConnected = useAppSelector(selectIsSocketConnected)
    const agentInfo = useAppSelector(selectAgentInfo)

    const loadMoreHistory = () => {
        // TODO load more history
        console.log('load more history called ')
        dispatch(getPrivateSessionHistory({
            userId: selectedSession.current.data?.user_id,
            limit: 10,
            page: selectedSessionHistory.currentPage
        }))
        dispatch(setNextHistoryPage())
    }

    const handleUploadFile = () => {
        hiddenFileInput.current.click()
    }
    const handleChooseFileToUplaod = (event) => {
        const fileUploaded = event.target.files[0]
        dispatch(uploadFileToChat({
            file: fileUploaded
        }))
    }
    const handleCancelUploadImage = () => {
        // TODO: also delete image uploaded from cloud bucket
        dispatch(resetChatImageState())
    }

    useEffect(() => {
        if (isSocketConnected) {
            dispatch({ type: 'socket/onNewChat', payload: { session_id: selectedSessionId }})
            dispatch({ type: 'socket/onIncomingMessage' })
        }
    }, [isSocketConnected, selectedSessionId])
    useEffect(() => {
        return () => {
            dispatch({ type: 'socket/offIncomingMessage' })
        }
    }, [])
    const handleSendMessage = () => {
        const uuid_v4 = uuidv4()
        dispatch(appendChatWithPendingStatus({message_id: uuid_v4, chat_sender_name: agentInfo.data.email}))
        if (currentChatImage.fullURL) {
            dispatch(resetChatImageState())
            dispatch({ type: 'socket/sendImage', payload: { message_id:uuid_v4, session_id: selectedSessionId, url: currentChatImage.fullURL, preview_url: currentChatImage.previewURL } })
        } else {
            if (!currentChatText) return;
            // append chat with pending status
            dispatch(resetChatText())
            console.log('uuid', uuid_v4)
            dispatch({ type: 'socket/sendMessage', payload: { message_id:uuid_v4, session_id: selectedSessionId, message: currentChatText } })
        }
    };

    const handleClickChatEnter = (event) => {
        if (event.keyCode == 13 && event.shiftKey || event.keyCode == 13 && event.ctrlKey || event.keyCode == 13 && event.altKey) {
        } else if (event.keyCode == 13) {
            handleSendMessage()
            event.preventDefault();
        }
    }

    const handleUpdateChatText = (event) => {
        dispatch(setChatText(event.target.value))
    }

    // // called only once but after user_id is valued
    // useLayoutEffect(() => {
    //     if (session.data?.user_id) {
    //         dispatch(getSessionUser({ user_id: session.data?.user_id }))
    //         dispatch(getSessionHistory({ user_id: session.data?.user_id, page: chat_history.current_page, limit: 10 }))
    //         setChatHistory({
    //             ...chat_history,
    //             current_page: chat_history.current_page + 1
    //         })
    //     }
    // }, [session.data?.user_id])


    useEffect(() => {
        if (selectedSession.current.status === 'pending') {
            // dispatch(resetHistory())
        } else if (selectedSession.current.status === 'fulfilled') {
            dispatch(getPrivateSessionHistory({ userId: selectedSession.current.data?.user_id, page: selectedSessionHistory.currentPage, limit: 10 }))
            dispatch(setNextHistoryPage())
            dispatch(getPriorSession({
                userId: selectedSession.current.data.user_id
            }))
        }
    }, [selectedSession.current.status])



    return (<>
        <Row style={{ height: '80%', width: '100%', backgroundColor: '#F0F2F5' }}>
            {/* React component will be remounted when key is changed even custom component 
            this will solve infinitscoll not reset scoll position 
            https://legacy.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
            */}
            <ChatList
                key={selectedSessionId}
                container_id='chat-chatlist' 
                history={selectedSessionHistory.data}
                chats={selectedSession.current?.data?.conversations}
                next={loadMoreHistory}
                has_more={selectedSessionHistory.hasMore} />
        </Row>
        <Row style={{ height: '20%', width: '100%', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center' }}>
            <Col span={3} style={{ alignItems: 'center', textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <img
                    onClick={handleUploadFile}
                    style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer'
                    }} src={addItemIcon} />
                <input
                    type="file"
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    onChange={handleChooseFileToUplaod} />

            </Col>
            <Col span={18} style={{ alignItems: 'center', textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center' }}>
                {
                    currentChatImage.previewURL ?
                        <div style={{ position: 'relative', height: '80%', overflow: 'auto' }}>
                            <CloseOutlined
                                onClick={handleCancelUploadImage}
                                style={{ right: '0px', position: 'absolute', cursor: 'pointer', color: 'red', fontSize: '20px' }}
                            />
                            <img
                                style={{ width: '100%', height: '100%' }}
                                src={currentChatImage.previewURL}
                            />
                        </div>
                        :
                        <TextArea
                            onPressEnter={handleClickChatEnter}
                            style={{ borderRadius: '20px', overflowY: 'auto', width: '100%' }}
                            value={currentChatText}
                            onChange={handleUpdateChatText}
                            onResize={(width, height) => {
                                console.log(width, height)
                            }}
                            autoSize={{
                                minRows: 4,
                                maxRows: 4,
                            }}
                            placeholder='Aa' />
                }
            </Col>
            <Col span={3} style={{ alignItems: 'center', textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <img
                    onClick={handleSendMessage}
                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                    src={sendBtnIcon} />
            </Col>
        </Row>
    </>)
}
export default PrivateSessionChat