import { Badge, Button, Col, Descriptions, Divider, Form, Input, Modal, Row, Select, Space, Table, Tag } from "antd"
import { columns, issueData, issue_data } from "./issuedata"
import './issueManagement.less'
import lineIcon from '../../res/line_icon.svg'
import fbMessengerIcon from '../../res/fb_messenger_icon.svg'
import shopeeIcon from '../../res/shopee_icon.svg'
import lazadaIcon from '../../res/lazada_icon.svg'
import tiktokIcon from '../../res/tiktok_icon.svg'
import defaultSourceIcon from '../../res/default_source_icon.svg'
import defaultProfile from '../../res/default_profile.svg'
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import CreateIssue from "../createissue/CreateIssue"
import { useAppDispatch, useAppSelector } from "../../hooks/hooks"
import { editSession, getAllSessionList } from "../../app/services/session"
import { selectManualAdd, selectSessionEditResult, selectSessionList } from "./issueManagementSlice"
import { formatDateTimeIso, formatDatetime, formatDatetimeWithYear } from "../../utils/datetime"
import IssueTagging from "../IssueTagging/IssueTagging"
import { create_issue_btn_label, issue_management_menu_label } from "../../wording"
import { manualAddSession } from "../../app/services/session"
import { current } from "@reduxjs/toolkit"
import IssueItem from "../issueItem/IssueItem"
import Item from "antd/lib/list/Item"
import ChatList from "../../components/ChatList/ChatList"
import { selectSession } from "../issueItem/IssueItemSlice"
import html2canvas from "html2canvas"


const IssueManagement = () => {

    const tagDisplay = JSON.parse(process.env.REACT_APP_TAG_DISPLAY)
    const previewTagRef = useRef(null)
    const dispatch = useAppDispatch();
    const session_list = useAppSelector(selectSessionList)
    const manual_add = useAppSelector(selectManualAdd)
    const session_edit_result = useAppSelector(selectSessionEditResult)
    // const tagging_list = 
    const [display_session, setDisplaySession] = useState([])
    const [tagging_list, setTaggingList] = useState([])

    const [tableParams, setTableParams] = useState({
        pagination: {
            sort: 'created_at',
            sort_type: -1,
            current: 1,
            limit: 10
        }
    })

    const [standard_data, setStandardData] = useState({
        username: '',
        source: ''
    })
    const [standard_data_error, setStandardDataError] = useState({
        visible: false,
        message: ''
    })
    const [is_edited_mode, setIsEditedMode] = useState(false)
    const [edit_data, setEditData] = useState({
        user_name: '',
        source: '',
        session_id: ''
    })

    const [search_param, setSearchParam] = useState({
        user_name: '',
        source: '',
        search: ''
    })
    const [tag_search, setTagSearch] = useState('')
    const session = useAppSelector(selectSession)

    const on_save_event = () => {
        dispatch(getAllSessionList({
            sort: tableParams.pagination.sort,
            sort_type: tableParams.pagination.sort_type,
            limit: tableParams.pagination.limit,
            page: tableParams.pagination.current,
            search: search_param.search,
            user_name: search_param.user_name,
            source: search_param.source
        }))
    }
    const [preview_tag, setPreviewTag] = useState([])

    useEffect(() => {
        switch (session_edit_result.status) {
            case 'fulfilled': {
                dispatch(getAllSessionList({
                    sort: tableParams.pagination.sort,
                    sort_type: tableParams.pagination.sort_type,
                    limit: tableParams.pagination.limit,
                    page: tableParams.pagination.current,
                    search: search_param.search,
                    user_name: search_param.user_name,
                    source: search_param.source
                }))
                break
            }
        }
    }, [session_edit_result.status])

    useEffect(() => {
        dispatch(getAllSessionList({
            sort: tableParams.pagination.sort,
            sort_type: tableParams.pagination.sort_type,
            limit: tableParams.pagination.limit,
            page: tableParams.pagination.current,
            search: search_param.search,
            user_name: search_param.user_name,
            source: search_param.source
        }))
    }, [])

    useEffect(() => {
        console.log('session_list ', session_list)
    }, [session_list])


    const TABLE_HEADER_HEIGHT = 55

    const [create_action_modal, setCreateActionModal] = useState({
        visible: false
    })
    const [preview_tag_modal, setPreviewTagModal] = useState({
        visible: false
    })
    const [issue_item_modal, setIssueItemModal] = useState({
        visible: false,
        data: {}
    })



    useEffect(() => {
        setEditData({
            user_name: issue_item_modal.data.user_name,
            source: issue_item_modal.data.source,
            session_id: issue_item_modal.data._id
        })
    }, [issue_item_modal.data])

    const handleConfirmIssueItemModal = () => {
        setIssueItemModal({
            ...issue_item_modal,
            visible: false
        })
    }
    const handleCancelIssueItemModal = () => {
        setIssueItemModal({
            ...issue_item_modal,
            visible: false
        })
    }

    const [tableHeight, setTableHeight] = useState(600)
    const tableRef = useRef(null)

    useLayoutEffect(() => {
        const node = tableRef.current
        const { top } = node.getBoundingClientRect()
        const height = window.innerHeight - top - TABLE_HEADER_HEIGHT - 80
        setTableHeight(height)
        console.log(height)
    }, [tableRef])

    const handleConfirmCreateIssue = () => {
        console.log('confirm create issue')
        if (!standard_data.username || !standard_data.source) {
            setStandardDataError({
                visible: true,
                message: 'ข้อมูลพื้นฐานไม่ครบ'
            })
        } else {
            dispatch(manualAddSession({
                // name, username, type, detail, tag, source
                name: 'default',
                username: standard_data.username,
                type: 'manual',
                detail: '',
                tag: tagging_list,
                source: standard_data.source
            }))

            setCreateActionModal({
                ...create_action_modal,
                visible: false
            })
            setStandardDataError({
                visible: false,
                message: ''
            })
            setStandardData({
                username: '',
                source: ''
            })
            setTaggingList([])
        }

    }

    useEffect(() => {
        switch (manual_add.status) {
            case 'fulfilled': {
                dispatch(getAllSessionList({
                    sort: tableParams.pagination.sort,
                    sort_type: tableParams.pagination.sort_type,
                    limit: tableParams.pagination.limit,
                    page: tableParams.pagination.current,
                    search: search_param.search,
                    user_name: search_param.user_name,
                    source: search_param.source
                }))
                break
            }
        }
    }, [manual_add.status])

    const handleCancelCreateIssue = () => {
        console.log('cancel create issue')
        setCreateActionModal({
            ...create_action_modal,
            visible: false
        })
    }
    const handleClickStartCreateIssue = () => {
        console.log('start create issue modal')
        setCreateActionModal({
            ...create_action_modal,
            visible: true
        })
    }
    const sourceIcon = {
        'line': lineIcon,
        'facebook': fbMessengerIcon,
        'shopee': shopeeIcon,
        'lazada': lazadaIcon,
        'tiktok': tiktokIcon
    }
    const getValueFromTree = (tag_item) => {
        if (tag_item.key) {

        }
        // // check key is sku
        // if (tag_item.key === 'sku'){
        //     return tag_item.value
        // }else{
        //     if(typeof tag_item.value === 'object'){
        //         getValueFromTree(tag_item.value)
        //     }else if(Array.isArray(tag_item.value){
        //         getValueFromTree()
        //     })
        // }
        // // if sku return 
        // // else 
        // // if object
        // // if array
    }

    const searchTagByKey = (tag, description) => {
        if(tag){
            if(typeof(tag) === 'object' && !Array.isArray(tag)){
                if(tag?.description === description){
                    return tag
                }else{
                    return searchTagByKey(tag.value, description)
                }
            }else if((Array.isArray(tag))){
                let obj = tag.find(e => e.key === description)
                if(obj){
                    return obj
                }else{
                    return searchTagByKey(tag.value, description)
                }
            }
        }else{
            return null
        }
    }
    const showTagDescriptionByDeepLevel = (tag, level) => {
        let output = tag
        for(let i = 0; i < level; i++){
            output = output?.value
        }
        return output?.description
    }

    const transformToDisplaySession = (session_list_data) => {
        let output = []
        session_list_data.map((session_item_data, index) => {
            const tags = []
            if (session_item_data.tag) {
                session_item_data.tag?.map(tag_item => {
                    let found = searchTagByKey(tag_item, tagDisplay.tag)
                    if(found){
                        tags.push(showTagDescriptionByDeepLevel(found, tagDisplay.level))
                    }
                })
            }
            output.push({
                key: session_item_data._id,
                source: session_item_data.source,
                user_name: session_item_data.user_name,
                agent_email: session_item_data.agent_email,
                created_at: session_item_data.created_at,
                raw_data: session_item_data,
                conversations: session_item_data.conversations,
                tags: tags
            })
        })
        return output
    }
    useEffect(() => {
        switch (session_list.status) {
            case 'fulfilled': {
                console.log('session_list_data', session_list.data.session)
                setDisplaySession(transformToDisplaySession(session_list.data?.session))
                break
            }
        }
    }, [session_list.status])
    const handleClickUser = (user) => {
        console.log(user)
        setSearchParam({
            ...search_param,
            user_name: user
        })
    }
    const columns = [
        {
            title: '',
            dataIndex: 'source',
            key: 'source',
            render: (text) => <img
                style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                src={sourceIcon[text] ? sourceIcon[text] : defaultSourceIcon}
                alt="Avatar" />
        },
        {
            title: 'ชื่อ Social หรือ ชื่อผู้ติดต่อ',
            dataIndex: 'user_name',
            key: 'user_name',
            render: (text) => <a onClick={(e) => {
                e.stopPropagation()
                handleClickUser(text)
            }}>{text}</a>,
        },
        {
            title: 'พนักงานรับเรื่อง',
            dataIndex: 'agent_email',
            key: 'agent_email',
            render: (text) => <span>{text}</span>
        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        //     render: () => <Badge status="success" text="Finished" />,
        // },
        {
            title: 'เวลา',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            render: (timestring) => <span>{formatDatetimeWithYear(timestring)}</span>
        },
        {
            title: 'tags',
            dataIndex: 'tags',
            render: (tags) => (
                <>
                    {tags.map((tag) => (
                        <Tag color="blue" key={tag}>
                            {tag}
                        </Tag>
                    ))}
                </>
            )
        }
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <a>Edit</a>
        //             {/* <a>Edit {record.username}</a> */}
        //             <a>Delete</a>
        //         </Space>
        //     ),
        // },
    ];
    const handleChangeTable = (pagination, filters, sorter) => {
        console.log('handle change', sorter)
        let sort_type
        let sort_field
        if (sorter.hasOwnProperty("column")) {
            sort_field = sorter.field
            switch (sorter.order) {
                case 'ascend': {
                    sort_type = 1
                    break
                }
                case 'descend': {
                    sort_type = -1
                    break
                }
                default: {
                    sort_type = 0
                    break
                }
            }
            // params.order = { field: sorter.field, dir: sorter.order };
        } else {
            sort_field = 'created_at'
            sort_type = -1
        }
        // const offset = pagination.current * pagination.pageSize - pagination.pageSize
        const current = pagination.current
        const limit = pagination.pageSize

        console.log('current', current)

        setTableParams({
            pagination: {
                sort: sort_field,
                sort_type: sort_type,
                limit: limit,
                current: current
            }
        })

    }


    useEffect(() => {
        dispatch(getAllSessionList({
            sort: tableParams.pagination.sort,
            sort_type: tableParams.pagination.sort_type,
            limit: tableParams.pagination.limit,
            page: tableParams.pagination.current,
            search: search_param.search,
            user_name: search_param.user_name,
            source: search_param.source
        }))
    }, [tableParams, search_param.user_name, search_param.source, search_param.search])

    const handleClickRow = (record) => {

        setIssueItemModal({
            ...issue_item_modal,
            visible: true,
            data: record.raw_data
        })
    }
    const handleChangeUsername = (event) => {
        setStandardData({
            ...standard_data,
            username: event.target.value
        })
    }
    const handleChangeSource = (value) => {
        setStandardData({
            ...standard_data,
            source: value
        })
    }
    const source_options = [
        {
            value: 'call_center',
            label: 'Call_Center'
        },
        {
            value: 'facebook',
            label: 'Facebook'
        },
        {
            value: 'line',
            label: 'Line OA'
        },
        {
            value: 'tiktok',
            label: 'TIKTOK'
        },
        {
            value: 'shopee',
            label: 'Shopee'
        },
        {
            value: 'lazada',
            label: 'Lazada'
        },
        {
            value: 'social_commerce',
            label: 'Social_Commerce'
        },
        {
            value: 'e-mail',
            label: 'E-mail'
        }
    ]

    const handleClickEditMode = () => {
        setIsEditedMode(true)
    }
    const handleCancelEditStandardData = () => {
        setIsEditedMode(false)
    }
    const handleSubmitEditStandardData = () => {
        // dispatch update data
        dispatch(editSession({
            session_id: edit_data.session_id,
            user_name: edit_data.user_name,
            source: edit_data.source
        }))

        setIsEditedMode(false)

        on_save_event()
    }

    const handleEditUsername = (event) => {
        setEditData({
            ...edit_data,
            user_name: event.target.value
        })
    }
    const handleEditSource = (value) => {
        setEditData({
            ...edit_data,
            source: value
        })
    }

    const handleClearSearchParam = () => {
        setSearchParam({
            search: '',
            user_name: '',
            source: ''
        })
        setTagSearch('')
    }

    const handleChangeUsernameFilter = (event) => {
        setSearchParam({
            ...search_param,
            user_name: event.target.value
        })
        // console.log(event.target.value)
        // dispatch(getAllSessionList({
        //     sort: 'created_at',
        //     sort_type: -1,
        //     limit: tableParams.pagination.limit,
        //     page: tableParams.pagination.current,
        //     user_name: event.target.value,
        //     search: ''
        // }))
    }
    const handleChangeSourceFilter = (value) => {
        setSearchParam({
            ...search_param,
            source: value
        })
    }
    const handleChangeTaggingSearch = (event) => {
        setTagSearch(event.target.value)
    }
    const handleSubmitSearch = () => {
        console.log('tag', tag_search)
        setSearchParam({
            ...search_param,
            search: tag_search
        })
    }
    const handlePreviewSessionTag = () => {
        setPreviewTagModal({
            visible: true
        })
        console.log(issue_item_modal.data)
        setPreviewTag(unfoldTaggings(issue_item_modal.data.tag))

    }
    const handleClosePreviewTag = () => {
        setPreviewTagModal({
            visible: false
        })
    }
    const unfoldTaggings = (tags) => {
        let outputTags = []
        console.log(tags)
        console.log('1')
        tags?.map(tag_item => {
            console.log(tag_item)
            let unfolded = unfoldTagging(tag_item)
            outputTags.push(unfolded)
        })
        console.log(outputTags)
        return outputTags
        // return outputTag
    }

    const unfoldTagging = (tag, parent, hist = []) => {
        if (tag.value) {
            if (typeof tag.value === 'object' && !Array.isArray(tag.value)) {
                unfoldTagging(tag.value, tag.description, hist)
            } else if (Array.isArray(tag.value)) {
                for (let i = 0; i < tag.value.length; i++) {
                    unfoldTagging(tag.value[i], tag.description, hist)
                }
            } else {
                hist.push({ key: tag.description, value: tag.value })
            }
        } else {
            hist.push({ key: parent, value: tag.description })
        }
        return hist
    }
    // const previewTag = document.getElementById('preview_tag')

    const handleDownloadPreview = async() => {
        console.log(previewTagRef.current.value)
        html2canvas(previewTagRef.current).then(function(canvas) {
            let data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');
         
            link.href = data;
            let link_url = issue_item_modal.data.user_name + '-' + formatDateTimeIso(Date.now()) + '.jpg'
            link.download = link_url;
         
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        // const element = document.getElementById("preview_tag")
        // let canvas =   html2canvas(element)
        // let data = canvas.toDataURL('image/jpg')
        // let link = document.createElement('a')

        // link.href = data;
        // link.download = 'downloaded-image.jpg'

        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link)
        // html2canvas(document.getElementById('slip-content-container')).then(function(canvas) {
        //     setProgress(40)
        //     const context = canvas.getContext('2d')
        //     const width = canvas.width
        //     const height = canvas.height
        //     setProgress(50)
        // try {
        //   setProgress(75)
        //   app.deviceObj.brightness = 0.5
        //   app.deviceObj.halftone = app.deviceObj.HALFTONE_THRESHOLD
        //   app.deviceObj.addImage(context, 0, 0, width, height, app.deviceObj.COLOR_1, app.deviceObj.MODE_MONO)
        //   app.deviceObj.addCut(app.deviceObj.CUT_FEED)
        //   app.deviceObj.send()
        //   setProgress(100)
        // } catch (e) {
        //   setPrintStatus(false)
        //   connect()
        // }
        // setTimeout(() => {
        //   setProgress(0)
        // }, 2000)
    

}


return (
    <div style={{ height: '100vh', width: '100%', paddingTop: '31px', paddingLeft: '74px', paddingRight: '74px', paddingBottom: '31px', overflow: 'auto' }}>
        {/* สร้างรายการใหม่ */}
        <Modal
            title="Preview"
            footer={false}
            visible={preview_tag_modal.visible}
            onCancel={handleClosePreviewTag}
            cancelText="ปิด">
                <div  ref={previewTagRef}>
            <Descriptions
               
                bordered>
                <Descriptions.Item span={3} label="ช่องทาง">{issue_item_modal.data.source}</Descriptions.Item>
                <Descriptions.Item span={3} label="ชื่อลูกค้า">{issue_item_modal.data.user_name}</Descriptions.Item>
                <Descriptions.Item span={3} label="ผู้รับเรื่อง">{issue_item_modal.data.agent_email}</Descriptions.Item>
                {
                    preview_tag.map((preview_tag_item, i) => {
                        return <>
                            {
                                preview_tag_item.map(item => {
                                    return (
                                        <Descriptions.Item span={3} label={item.key}>{item.value}</Descriptions.Item>
                                    )
                                })
                            }
                        </>
                    })
                }
            </Descriptions>
            </div>
            <Button onClick={handleDownloadPreview}>Download</Button>
        </Modal>
        <Modal
            title={create_issue_btn_label}
            visible={create_action_modal.visible}
            onOk={handleConfirmCreateIssue}
            okText="ยืนยันสร้างรายการใหม่"
            onCancel={handleCancelCreateIssue}
            cancelText="ยกเลิก"
            width={1000}>
            <div>
                <Divider><h2>ข้อมูลพื้นฐาน</h2></Divider>
                <Row>
                    ชื่อ Social หรือ ชื่อผู้ติดต่อ: <Input value={standard_data.username} onChange={handleChangeUsername} />
                </Row>
                <Row style={{ marginTop: '15px' }}>
                    ช่องทางติดต่อ:
                    <div>
                        <Select
                            style={{ width: '120px' }}
                            options={source_options}
                            value={standard_data.source} onChange={handleChangeSource} />
                    </div>
                </Row>
                {standard_data_error.visible ?
                    <div style={{ width: '100%', height: '10%', textAlign: 'right', color: 'red' }}>{standard_data_error.message}</div>
                    : <></>}


                <Divider><h2>ข้อมูลเพิ่มเติม</h2></Divider>
                <IssueTagging
                    parent_type='manual'
                    tagging_list={tagging_list}
                    setTaggingList={setTaggingList} />
            </div>
            {/* <CreateIssue /> */}
        </Modal>
        <Modal
            width={1000}
            bodyStyle={{ height: '600px' }}
            footer={null}
            visible={issue_item_modal.visible}
            onOk={handleConfirmIssueItemModal}
            onCancel={handleCancelIssueItemModal}

        >
            <Row style={{ height: '100%', width: '100%' }}>
                <Col style={{ height: '100%', borderStyle: 'solid' }} className="chat-preview-container" span={12}>

                    <ChatList
                        container_id='issue_management_chatlist'
                        className='chatlist'
                        // history={props.history}
                        chats={session?.data?.conversations}
                    // next={props.next}
                    // has_more={props.has_more}
                    />
                </Col>
                <Col style={{ height: '100%', overflow: 'auto', padding: '15px' }} span={12}>

                    <Button style={{ float: 'right' }} onClick={handlePreviewSessionTag}>Preview</Button>
                    <Divider><h2>ข้อมูลพื้นฐาน</h2></Divider>
                    <div style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={handleClickEditMode}>แก้ไขข้อมูลพื้นฐาน</Button>
                    </div>

                    <div>ชื่อ Social หรือ ชื่อผู้ติดต่อ:
                        <Input
                            onChange={handleEditUsername}
                            disabled={!is_edited_mode}
                            value={edit_data.user_name} />
                        {/* <Input>{issue_item_modal.data.user_name}</Input> */}
                    </div>
                    <div style={{ marginTop: '15px' }}>ช่องทางติดต่อ:
                        <Select
                            disabled={!is_edited_mode}
                            style={{ width: '120px' }}
                            options={source_options}
                            value={edit_data.source} onChange={handleEditSource} />
                        {/* {issue_item_modal.data.source} */}
                    </div>

                    {
                        is_edited_mode ? <div style={{ textAlign: 'right' }}>
                            <Button type="primary" danger onClick={handleCancelEditStandardData}>ยกเลิกบันทึกข้อมูลพื้นฐาน</Button>
                            <Button type="primary" onClick={handleSubmitEditStandardData}>บันทึกข้อมูลพื้นฐาน</Button>
                        </div> : <></>
                    }

                    <Divider><h2>ข้อมูลเพิ่มเติม</h2></Divider>
                    <IssueItem
                        on_save_event={on_save_event}
                        data={issue_item_modal.data}
                    />
                </Col>
            </Row>


        </Modal>
        <Row style={{ width: '100%', height: '10%' }}>
            <Col className="page-header">{issue_management_menu_label}</Col>
            <Col style={{ marginLeft: 'auto' }}><Button onClick={handleClickStartCreateIssue} className="btn-create-issue" >{create_issue_btn_label}</Button></Col>
        </Row>
        <Row style={{ width: '100%', height: '10%', display: 'flex', alignItems: 'center' }}>
            <Col style={{ padding: '10px' }}>
                ช่องทางติดต่อ:
                <Select
                    value={search_param.source}
                    allowClear
                    // disabled={!is_edited_mode}
                    style={{ width: '120px' }}
                    options={source_options}
                    // value={} 
                    onChange={handleChangeSourceFilter}
                />
                {/* <Input style={{ borderRadius: '60px', height: '44px', paddingLeft: '30px' }} placeholder='ค้นหาชื่อ Social หรือ ชื่อผู้ติดต่อ' /> */}
            </Col>
            <Col style={{ padding: '10px' }}>
                <Input
                    placeholder="ค้นหาข้อมูล tag"
                    value={tag_search}
                    onChange={handleChangeTaggingSearch}
                />
                {/* <Input style={{ borderRadius: '60px', height: '44px', paddingLeft: '30px' }} placeholder='ค้นหาชื่อ พนักงาน' /> */}
            </Col>
            <Col>
                <Button onClick={handleSubmitSearch}>ค้นหา</Button>

            </Col>
            <Col style={{ padding: '10px' }}>
                <Input
                    value={search_param.user_name}
                    onChange={handleChangeUsernameFilter}
                    style={{ borderRadius: '60px', height: '44px', paddingLeft: '30px' }}
                    placeholder='ค้นหาชื่อ Social หรือ ชื่อผู้ติดต่อ' />
            </Col>
            <Col>
                <Button onClick={handleClearSearchParam}>ล้างการค้นหาทั้งหมด</Button>
            </Col>
        </Row>
        <div
            ref={tableRef}
            style={{ height: '80%' }}>
            <Table
                onChange={handleChangeTable}
                pagination={{
                    // pageSize: 50,
                    total: session_list?.data?.sessions_count
                }}
                columns={columns}
                dataSource={display_session}
                rowClassName='table-row'
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            handleClickRow(record)
                            // console.log(record)
                            // console.log(event.target)
                        }
                    }
                }}
                onClick={(event) => {
                    console.log(event)
                }}
                scroll={{
                    x: 1500,
                    y: tableHeight
                }} />
        </div>
    </div>
)
}
export default IssueManagement