import { Col, Radio, Row } from "antd"
import { resetNote, selectInputNote, selectPriorSession, selectSelectedInfoCompoment, selectSelectedSession, selectSelectedSessionId, setNote, setSelectedInfoComponent } from "../privateSessionSlice"
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks"
import * as constants from '../../../constants'
import UserOverview from "../../../components/UserOverview/UserOverview"
import IssueTagging from "../../IssueTagging/IssueTagging"
import { selectAgentInfo } from "../../home/homeSlice"
import { addTagToSession } from "../privateSessionActions"
import { useEffect, useState } from "react"
import AdditionalInfo from "./AdditionalInfo"

const PrivateSessionInfo = () => {

    const dispatch = useAppDispatch()
    const selectedInfoComponent = useAppSelector(selectSelectedInfoCompoment)
    const agentInfo = useAppSelector(selectAgentInfo)
    const note = useAppSelector(selectInputNote)
    const selectedSession = useAppSelector(selectSelectedSession)
    const selectedSessionId = useAppSelector(selectSelectedSessionId)
    const priorSession = useAppSelector(selectPriorSession)

    const [tagging_list, setTaggingList] = useState([])

    const handleChangeInfoComponent = (event) => {
        console.log(event.target.value)
        dispatch(setSelectedInfoComponent(event.target.value))
    }

    const onSaveTagList = (tagList) => {
        dispatch(addTagToSession({
            sessionId: selectedSessionId,
            tag: tagList
        }))
    }

    useEffect(() => {
        setNote(selectedSession?.current?.data?.additional_info)
        if (selectedSession?.current?.data?.tag) {
            setTaggingList(selectedSession?.current?.data?.tag)
        } else {
            setTaggingList([])
        }

    }, [selectedSession?.current?.data])

    useEffect(() => {
        console.log('selectedInfoComponent ', selectedInfoComponent)
    }, [])

    const handleChangeNote = (event) => {
        dispatch(setNote(event.target.value))
    }

    const handleResetNote = () => {
        dispatch(resetNote())
    }

    const component = {
        'user_detail': <UserOverview
            loading={false}
            session={selectedSession.current.data}
            session_user={agentInfo.data}
        />,
        'activity': <AdditionalInfo
            session_user={priorSession.data}
            handleChange={handleChangeNote}
            handleReset={handleResetNote}
            value={note}
        />,
        'tag': <IssueTagging
            id={selectedSessionId}
            on_save={onSaveTagList}
            parent_type='auto'
            tagging_list={tagging_list}
            setTaggingList={setTaggingList}
        />
    }

    return (<>
        <Row style={{ height: '100%', width: '100%', backgroundColor: '#ffffff' }}>
                    <Row style={{ width: '100%', height: '5%' }}>
                        <Radio.Group style={{ width: '100%' }} value={selectedInfoComponent} onChange={handleChangeInfoComponent}>
                            <Row>
                                <Col span={8}>
                                    <Radio.Button style={{ width: '100%', textAlign: 'center' }} value="user_detail">{constants.USER_DETAIL_LABEL}</Radio.Button>
                                </Col>
                                <Col span={8}>
                                    <Radio.Button style={{ width: '100%', textAlign: 'center' }} value="activity">{constants.ACTIVITY_HISTORIES_LABEL}</Radio.Button>
                                </Col>
                                <Col span={8}>
                                    <Radio.Button style={{ width: '100%', textAlign: 'center' }} value="tag">{constants.TAGGING_LABEL}</Radio.Button>
                                </Col>
                            </Row>
                        </Radio.Group>
                    </Row>
                    <Row style={{ padding: '30px', height: '95%', width: '100%' }}>
                        {component[selectedInfoComponent]}
                    </Row>
                </Row>
    </>)
}
export default PrivateSessionInfo