import { createSlice } from "@reduxjs/toolkit";
import { getAgentSessions } from "../../app/services/agent";
import { getSession, getSessionForIssueItem, getSessionHistory, getSessionUser, sessionAddTag } from "../../app/services/session";


const initialState = {
    session: {
        data: null,
        status: 'idle'
    }
}

export const issueItemSlice = createSlice({
    name: 'issue_item',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getSessionForIssueItem.pending, (state) => {
            state.session.status = 'pending'
        })
        builder.addCase(getSessionForIssueItem.fulfilled, (state, action) => {
            state.session.status = 'fulfilled'
            state.session.data = action.payload
        })
        builder.addCase(getSessionForIssueItem.rejected, (state, action) => {
            state.session.status = 'rejected'
        })
    }
})

export const selectSession = (store) => store.issueItem.session
export default issueItemSlice.reducer