import { useEffect } from "react"
import ChatPreview from "../../../components/ChatPreview/ChatPreview"
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks"
import { openForward, prepareForwardAction, resetLobbyPageState, selectForwardingState, selectRequestForChatState, selectSelectedSession, selectSelectedSessionHistory, selectSelectedSessionId, setNextHistoryPage } from "../publicSessionSlice"
import { setActiveChatToStorage } from "../../../utils/localStorage/activeChat"
import { getPublicSessionHistory, requestToSubmitPublicSession } from "../publicSessionActions"
import { useNavigate } from "react-router-dom"
import { setStorageActiveMenuID } from "../../../utils/localStorage/menu"

const PublicSessionPreview = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const sessionHistory = useAppSelector(selectSelectedSessionHistory)
    const selectedSession = useAppSelector(selectSelectedSession)
    const selectedSessionId = useAppSelector(selectSelectedSessionId)
    const requestForChatState = useAppSelector(selectRequestForChatState)
    const forwardingState = useAppSelector(selectForwardingState)

    const handleForwardSession = () => {
        // dispatch(requestSessionForLobby({ session_id: selectedSessionId }))
        dispatch(prepareForwardAction())
    }
    const handleSubmitSession = () => {
        setActiveChatToStorage({ id: selectedSessionId })
        dispatch(requestToSubmitPublicSession({ sessionId: selectedSessionId }))
    }
    const handleLoadMoreHistory = () => {
        dispatch(getPublicSessionHistory({
            userId: selectedSessionId,
            limit: 10,
            page: sessionHistory.currentPage
        }))

        dispatch(setNextHistoryPage())
    }

    const toChatPage = () => {
        dispatch(resetLobbyPageState())
        setStorageActiveMenuID('2')
        navigate('/issue')
    }

    useEffect(() => {
        switch(requestForChatState.status){
            case 'fulfilled':{
                if(forwardingState.isClicked) {
                    window.location.reload()
                }else{
                    toChatPage()
                }
                break
            }
        }
    }, [requestForChatState.status])

    return (<>
        <ChatPreview
            key={selectedSessionId}
            containerId="lobby-chatlist"
            current={selectedSession.current.data}
            history={sessionHistory.data}
            next={handleLoadMoreHistory}
            isLoaded={sessionHistory.isLoaded}
            hasMore={sessionHistory.hasMore}
            handleForward={handleForwardSession}
            handleSubmit={handleSubmitSession}
            isSelected={selectedSession.isSessionSelected} />
    </>)
}
export default PublicSessionPreview