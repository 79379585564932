import { getLocalStorage } from "./localStorage"
import { STORAGE_AUTH_KEY } from "./localStorage/constant"
import jwt_decode from 'jwt-decode'

export const getCurrentUserType = () => {
    let access_token_data = getLocalStorage(STORAGE_AUTH_KEY)?.access_token

    if (access_token_data == null) { //one tab logged out, be sure to log out for all
        return null

    } else { //in case if token exists but can still be expired

        let decodedToken = jwt_decode(access_token_data);

        return decodedToken["user_type"]
    }
}

export const checkTokenValid = () => {
    let access_token_data = getLocalStorage(STORAGE_AUTH_KEY)?.access_token

    if (!access_token_data) {
        return false
} else {
        let decodedToken = jwt_decode(access_token_data);
        let expiredDate_check = new Date(parseInt(decodedToken['exp']) * 1000);
        let now_check = new Date();
        if (now_check > expiredDate_check) {
            return false
        }
    }
    return true
}


