import { createSlice } from "@reduxjs/toolkit";
import { getPriorSession, getPrivateSessionDetail, getPrivateSessionHistory, getPrivateSessionsForAgent, uploadFileToChat } from "./privateSessionActions";

const sortWithTimestamp = (list) => {
    list.sort(function(x, y){
        return x.timestamp - y.timestamp;
    })
}

// chat_message: "Test ส่งข่อความ"
// chat_sender_avatar: "https://sprofile.line-scdn.net/0hY13Y-Fu2Bh4eIBNq-tt4YW5wBXQ9UV8MMkYbeCpwXyojERFBM0cbfiMjXykrFUhKZU8eKCh0W3oSM3F4AHb6KhkQWCknFklLMkVK_w"
// chat_sender_id: "U809747fe3c07e606163ad9bb52c1b1eb"
// chat_sender_name: "inn,"
// is_agent: false
// session_id: "6471ee8d7179610e50fa4909"
// timestamp: "2023-05-27T11:50:37.020000"
// type: "text"

const initialState = {
    selectedSession: {
        id: null,
        isSessionSelected: false,
        currentWithHistory: null,
        current: {
            data: null,
            status: 'idle',
        },
        history: {
            currentPage: 1,
            hasMore: true,
            previousPage: 0,
            data: null,
            status: 'idle'
        },
        requestForChat: {
            data: null,
            status: 'idle'
        },
        inputInfo: {
            note: '',
            tag: null
        },
        chat: {
            image: {
                status: 'idle',
                previewURL: '',
                fullURL: ''
            },
            text: ''
        },
        priorSession: {
            data: null,
            status: 'idle'
        },
    },
    sessionList: {
        data: null,
        status: 'idle'
    },
    sessionActionState: {
        forward: {
            isClicked: false,
            channelSelected: '',
            errorMessage: ''
        },
        close: {
            isClicked: false
        }
    },
    selectedInfoComponent: 'user_detail'
}

export const privateSessionSlice = createSlice({
    name: 'privateSession',
    initialState,
    reducers: {
        setSelectedSessionId: (state, action) => {
            state.selectedSession = {
                ...initialState.selectedSession,
                id: action.payload,
                isSessionSelected: true,
            }
        },
        prepareForwardAction: (state, action) => {
            state.sessionActionState.forward = {
                ...state.sessionActionState.forward,
                isClicked: true
            }
        },
        cancelForwardAction: (state, action) => {
            state.sessionActionState.forward = {
                ...state.sessionActionState.forward,
                isClicked: false
            }
        },
        chooseForwardChannel: (state, action) => {
            state.sessionActionState.forward = {
                ...state.sessionActionState.forward,
                channelSelected: action.payload
            }
        },
        confirmForwardAction: (state, action) => {

        },
        prepareCloseAction: (state, action) => {
            state.sessionActionState.close = {
                ...state.sessionActionState.close,
                isClicked: true
            }
        },
        cancelCloseAction: (state, action) => {
            state.sessionActionState.close = {
                ...state.sessionActionState.close,
                isClicked: false
            }
        },
        confirmCloseAction: (state, action) => {

        },
        setNextHistoryPage: (state, action) => {
            state.selectedSession.history = {
                ...state.selectedSession.history,
                currentPage: state.selectedSession.history.currentPage + 1
            }
        },
        resetChatImageState: (state, action) => {
            state.selectedSession.chat.image = {
                ...initialState.selectedSession.chat.image
            }
        },
        setChatText: (state, action) => {
            state.selectedSession.chat.text = action.payload
        },
        resetChatText: (state, action) => {
            state.selectedSession.chat.text = ''
        },
        resetChatState: (state, action) => {
            state.selectedSession.chat = {
                ...initialState.selectedSession.chat
            }
        },
        setSelectedInfoComponent: (state, action) => {
            console.log(action.payload)
            state.selectedInfoComponent = action.payload
        },
        setNote: (state, action) => {
            state.selectedSession.inputInfo.note = action.payload
        },
        resetNote: (state, action) => {
            state.selectedSession.inputInfo.note = initialState.selectedSession.inputInfo.note
        },
        appendChatWithPendingStatus: (state,action) => {
            // TODO: change chat sender name
            if(state.selectedSession.chat.text){
                state.selectedSession.current.data.conversations = state.selectedSession.current.data.conversations.concat({
                    chat_message:  state.selectedSession.chat.text,
                    is_agent: true,
                    sending_status: 'pending',
                    message_id: action.payload.message_id,
                    chat_sender_name: action.payload.chat_sender_name,
                    type: 'text'
                })
            }else if(state.selectedSession.chat.image.fullURL){
                state.selectedSession.current.data.conversations = state.selectedSession.current.data.conversations.concat({
                    chat_image_url:  state.selectedSession.chat.image.fullURL,
                    is_agent: true,
                    sending_status: 'pending',
                    message_id: action.payload.message_id,
                    chat_sender_name: action.payload.chat_sender_name,
                    type: 'image'
                })
            }
        },
        appendCurrentChat: (state, action) => {
            // TODO: sort chat with timestamp 
            state.selectedSession.current.data.conversations = state.selectedSession.current.data.conversations.concat(action.payload)
        },
        setChatSucess: (state, action) => {
            console.log(action.payload)
            let foundIndex = state.selectedSession.current.data.conversations.findIndex(x => x.message_id === action.payload.message_id)
            console.log(foundIndex)
            state.selectedSession.current.data.conversations[foundIndex].sending_status = 'success'
            // state.selectedSession.current.data.conversations.find()
        },
        resetSelectedSessionState: (state, action) => {
            state.selectedSession = initialState.selectedSession
        },
        resetPrivateChatInitState: (state, action) => {
            state.selectedSession = initialState.selectedSession
            state.sessionList = initialState.sessionList
            state.sessionActionState = initialState.sessionActionState
            state.selectedInfoComponent = initialState.selectedInfoComponent
        },
        setActiveHolding: (state, action) => {
            console.log('set active holding ',action.payload)
            let foundIndex = state.sessionList.data?.holding.findIndex(x => x._id === action.payload.session_id)
            console.log(foundIndex)
            if(foundIndex !== -1 && state.sessionList.data?.holding){
                state.sessionList.data.holding[foundIndex].type = 'active_holding'
            }
        },
        setActive: (state, action) => {
            console.log('set active ',action.payload)
            let foundIndex = state.sessionList.data?.holding.findIndex(x => x._id === action.payload.session_id)
            if(foundIndex !== -1 && state.sessionList.data?.holding){
                console.log('found',foundIndex)
                state.sessionList.data.holding[foundIndex].type = 'active'
            }
        }
    },
    extraReducers: (builder) => {
        // Fetch agent session list
        builder.addCase(getPrivateSessionsForAgent.pending, (state) => {
            state.sessionList.status = 'pending'
        })
        builder.addCase(getPrivateSessionsForAgent.fulfilled, (state, action) => {
            state.sessionList.status = 'fulfilled'
            state.sessionList.data = action.payload
        })
        builder.addCase(getPrivateSessionsForAgent.rejected, (state, action) => {
            state.sessionList.status = 'rejected'
        })

         // Get session detail
         builder.addCase(getPrivateSessionDetail.pending, (state) => {
            state.selectedSession.current.status = 'pending'
        })
        builder.addCase(getPrivateSessionDetail.fulfilled, (state, action) => {
            state.selectedSession.current.status = 'fulfilled'
            state.selectedSession.current.data = action.payload
        })
        builder.addCase(getPrivateSessionDetail.rejected, (state, action) => {
            state.selectedSession.current.status = 'rejected'
        })

        // Get session history
        builder.addCase(getPrivateSessionHistory.pending, (state) => {
            state.selectedSession.history.status = 'pending'
        })
        builder.addCase(getPrivateSessionHistory.fulfilled, (state, action) => {
            state.selectedSession.history.status = 'fulfilled'
            const fetchedHistory = action.payload
            const currentHistory = state.selectedSession.history.data
            // set history data
            if (fetchedHistory && fetchedHistory.length > 0) {
                if (currentHistory && currentHistory.length > 0) {
                    // if there is history merge new history with current history
                    state.selectedSession.history = {
                        ...state.selectedSession.history,
                        data: state.selectedSession.history.data.concat(fetchedHistory)
                    }
                } else {
                    // if no current history then set new history
                    state.selectedSession.history = {
                        ...state.selectedSession.history,
                        data: action.payload
                    }
                }
            } else if (action.payload && action.payload?.length === 0) {
                // no more history content
                state.selectedSession.history = {
                    ...state.selectedSession.history,
                    hasMore: false
                }
            }
        })
        builder.addCase(getPrivateSessionHistory.rejected, (state, action) => {
            state.selectedSession.history.status = 'rejected'
        })

        // upload image
        builder.addCase(uploadFileToChat.pending, (state) => {
            state.selectedSession.chat.image.status = 'pending'
        })
        builder.addCase(uploadFileToChat.fulfilled, (state, action) => {
            state.selectedSession.chat.image.status = 'fulfilled'
            state.selectedSession.chat.image.previewURL = action.payload.preview_url
            state.selectedSession.chat.image.fullURL = action.payload.full_url
        })
        builder.addCase(uploadFileToChat.rejected, (state, action) => {
            state.selectedSession.chat.image.status = 'rejected'
        })

        builder.addCase(getPriorSession.pending, (state) => {
            state.selectedSession.priorSession.status = 'pending'
        })
        builder.addCase(getPriorSession.fulfilled, (state, action) => {
            state.selectedSession.priorSession.status = 'fulfilled'
            state.selectedSession.priorSession.data = action.payload
        })
        builder.addCase(getPriorSession.rejected, (state, action) => {
            state.selectedSession.priorSession.status = 'rejected'
        })

    }
})

export const selectSelectedSession = (store) => store.privateSession.selectedSession
export const selectSession = (store) => store.privateSession.sessionList
export const selectSelectedSessionHistory = (store) => store.privateSession.selectedSession.history
export const selectSelectedSessionId = (store) => store.privateSession.selectedSession.id
export const selectSelectedSessionChatImage = (store) => store.privateSession.selectedSession.chat.image
export const selectSelectedSessionChatText = (store) => store.privateSession.selectedSession.chat.text
export const selectSelectedInfoCompoment = (store) => store.privateSession.selectedInfoComponent
export const selectInputNote = (store) => store.privateSession.selectedSession.inputInfo.note
export const selectForwardingState = (store) => store.privateSession.sessionActionState.forward
export const selectCloseSessionState = (store) => store.privateSession.sessionActionState.close
export const selectPriorSession = (store) => store.privateSession.selectedSession.priorSession

export const {
    setSelectedSessionId,
    prepareForwardAction,
    cancelForwardAction,
    prepareCloseAction,
    cancelCloseAction,
    setNextHistoryPage,
    resetChatImageState,
    setChatText,
    resetChatText,
    resetChatState,
    setSelectedInfoComponent,
    setNote,
    resetNote,
    appendChatWithPendingStatus,
    appendCurrentChat,
    setChatSucess,
    resetSelectedSessionState,
    chooseForwardChannel,
    resetPrivateChatInitState,
    setActiveHolding,
    setActive
} = privateSessionSlice.actions
export default privateSessionSlice.reducer