import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    socket: null,
    is_socket_connected: false
}

export const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload
        },
        setSocketConnected: (state, action) => {
            state.is_socket_connected = action.payload
        }
    },
})
export const { setSocket, setSocketConnected } = socketSlice.actions

export const selectSocket = (store) => store.socket.socket
export const selectIsSocketConnected = (store) => store.socket.is_socket_connected
export default socketSlice.reducer