import { useEffect, useState } from "react"
import { getSession, getSessionForIssueItem, sessionAddTag } from "../../app/services/session"
import { useAppDispatch, useAppSelector } from "../../hooks/hooks"
import { selectUpdateSessionTagResult } from "../chat/chatSlice"
import IssueTagging from "../IssueTagging/IssueTagging"
import { selectSession } from "./IssueItemSlice"

const IssueItem = ({ data, on_save_event }) => {
    const dispatch = useAppDispatch()
    const session = useAppSelector(selectSession)
    const [tagging_list, setTaggingList] = useState([])
    const update_session_tag_result = useAppSelector(selectUpdateSessionTagResult)

    useEffect(() => {
        console.log('this is called')
        dispatch(getSessionForIssueItem({ session_id: data._id }))
    }, [data._id])

    useEffect(() => {
        switch (session.status) {
            case 'fulfilled': {
                setTaggingList(session.data.tag)
                break
            }
        }
    }, [session.status])

    const UpdateTaggingListToSession = (tag_list) => {
        dispatch(sessionAddTag({
            session_id: data._id,
            tag: tag_list
        }))
    }
    // if update session success then call new session
    useEffect(() => {
        switch (update_session_tag_result.status) {
            case 'fulfilled': {
                dispatch(getSession({ session_id: data._id }))
                break
            }
        }
    }, [update_session_tag_result.status])

    const onSaveTagList = (tag_list) => {
        // call add tag 
        // then call new list 
        UpdateTaggingListToSession(tag_list)
        setTimeout(() => {
            on_save_event()
        }, 500)
    }
    
    // useEffect(() => {
    //     console.log('data', data)
    //     if(data?.tag) {
    //         setTaggingList(data.tag)
    //     }else{
    //         setTaggingList([])
    //     }
    // },[data])
    return (<div style={{ padding: '20px' }}>
        <IssueTagging
            on_save={onSaveTagList}
            parent_type='auto'
            tagging_list={tagging_list}
            setTaggingList={setTaggingList}
        />
    </div>)
}

export default IssueItem