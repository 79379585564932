// Button Label
export const forward_btn_label = 'ส่งต่อ'
export const close_btn_label = 'ดำเนินการเสร็จสิ้น'
export const accept_btn_label = 'ตอบรับดำเนินการ'
export const create_issue_btn_label = 'สร้างรายการใหม่'
export const clear_tagging_btn_label = 'ล้างข้อมูลเพิ่มเติม'
export const save_tagging_btn_label = 'บันทึกข้อมูลเพิ่มเติม'

// Menu Label
export const incoming_message_menu_label = 'ห้องข้อความหลัก'
export const INCOMING_MESSAGE_MENU_LABEL = 'ห้องข้อความหลัก'
export const holding_message_menu_label = 'ห้องข้อความส่วนตัว'
export const issue_management_menu_label = 'ประวัติการบริการ'

export const user_detail_label = 'ข้อมูลลูกค้า'
export const activity_histories_label = `ประวัติการให้บริการ`
export const tagging_label = 'ข้อมูลกำกับการบริการ'

export const no_activity_label = 'ไม่มีประวัติการบริการ'
