import { Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import AdditionalInfoList from "../../../components/AdditionalInfoList/AdditionalInfoList";
import TextArea from "antd/lib/input/TextArea";

const AdditionalInfo = (props) => {
    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };
    return (
        <Content style={{
            height: '100%'
        }}>
            <Row style={{ width: '100%', height: '50%' }}>
                <AdditionalInfoList session_user={props?.session_user} />
            </Row>
            <Row style={{ width: '100%', height: '50%', display:'flex', alignItems:'center', justifyContent:'center' }}>
                <TextArea 
                    style={{ width: '100%'}}
                    rows={10}
                    placeholder="Additional Info"
                    allowClear
                    showCount
                    maxLength={500}
                    value={props.value}
                    onChange={props.handleChange} />

            </Row>

        </Content>
    )
}

export default AdditionalInfo
