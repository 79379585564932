import { Header } from "antd/lib/layout/layout"
import './publicSession.less'
import * as Constants from "../../constants"
import PublicSessionErrorModal from "./components/PublicSessionErrorModal"
import PublicSessionForwardModal from "./components/PublicSessionForwardModal"
import PublicSessionList from "./components/PublicSessionList"
import PublicSessionPreview from "./components/PublicSessionPreview"

const PublicSession = () => {
    return (
        <div className='channels-container'>
            <PublicSessionErrorModal />
            <PublicSessionForwardModal />
            <div className='channel-container left'>
                <Header className='channel-header'>
                    {Constants.LOBBY_MENU_LABEL}
                </Header>
                <PublicSessionList />
            </div>
            <div className='channel-container'>
                <PublicSessionPreview />
            </div>
        </div>
    )
}
export default PublicSession