

const PrivacyPolicy = () => {
    return (
        <div>

            <div style={{ padding: '50px' }}>

                {/* <OutsiderNavbar /> */}

                <div >
                    <h1><b>Privacy Policy</b></h1>
                    <h2><b>Privacy Notice</b></h2>

                    &emsp;&emsp;Vulcan Coalition Co., Ltd. (hereinafter referred to as “Company” or “we”) provides services about customer service support platform for Mc Jeans Manufacturing Co., Ltd. We are committed to respecting the privacy and safety of your personal data and thus define and comply with the privacy notice in order to inform service users and our clients to realize personal data that we collect, gather, use, disclose and protect. We issue this privacy notice to enable you to understand before collecting your personal data as follows.
                    <br /><br />

                    <h3><b>Scope of notice</b></h3>

                    &emsp;&emsp;This privacy notice is effective only with your data under our control, and it does not apply with data gathered or provided from services by third parties that are beyond our control. It includes cases of access to a website or other products of the third parties through our application.
                    <br /><br />

                    <h3><b>Source and personal data we collect from you</b></h3>

                    &emsp;&emsp;The company will directly collect your personal data when you engage in, contact, or create an account with us or use our services. It includes data that we may receive from our partners as follows:
                    <br /><br />

                    <ol>
                        <li>Application data such as name-surname, address, and telephone number.</li>
                        <li>The user provided chat data in the messenger channel, including texts, images, and other files.</li>
                        <li>Email address (Optional)</li>
                        <li>Facebook profile</li>
                        <li>Line profile</li>
                        <li>Shopee profile</li>
                        <li>Lazada profile</li>
                        <li>Pay slip for purchase confirmation (Not include Credit/debit card number and/or internet banking information)</li>
                    </ol>
                    &emsp;&emsp;We will notify you of sensitive data to ask for consent before collecting, processing, or disclosing such data.
                    <br /><br />

                    <h3><b>Objectives of personal data collection, use and disclosure</b></h3>

                    &emsp;&emsp;The company will collect, gather, use, and disclose your personal data to related persons, including but not limited to the following objectives.
                    <br /><br />

                    <ol>
                        <li>To create and manage a user's account.</li>
                        <li>To deliver products and related services</li>
                        <li>To develop products, services, and experiences for users</li>
                        <li>To exchange and manage data inside the organization.</li>
                        <li>To do marketing promotion activities</li>
                        <li>To develop a processing system for a better user experience</li>
                        <li>To offer after-sale service</li>
                        <li>To accept the application-related suggestion from users</li>
                        <li>To make a payment confirmation for product and service cost</li>
                        <li>To comply with an agreement, legal obligation, and claim for legal enforcement.</li>
                    </ol>

                    <h3><b>Data storage safety</b></h3>

                    &emsp;&emsp;The company will manage your personal data safely in an electronic form on the secured server or network and/ or a hard copy form in a firmly closed cabinet, restricted for only related persons to access data.
                    <br /><br />

                    <h3><b>Duration of data storage</b></h3>

                    &emsp;&emsp;The company will collect and gather your data as long as you still use the services and have not confirmed cancellation of our services. However, if we receive the confirmation of application or service cancellation from you, we will retain some data for another 2 years for tax and accounting purposes. 
                    <br /><br />

                    &emsp;&emsp;The company reserves right to keep on retaining your data if necessary to achieve legal and significant business objectives such as compliance with our legal obligations and dispute settlement etc. as long as the applicable laws defines. After that, your data will be immediately erased or destroyed after such term is due.
                    <br /><br />

                    <h3><b>Entities where personal data may be disclosed</b></h3>

                    &emsp;&emsp;According to the objectives of collecting, gathering, using and/ or disclosing your personal data, the company may disclose your personal data to other companies that are our partners as well as external entities such as payment service providers, academicians, auditors, advisers, financial institutions, legal enforcement and data protection authorities, and public organizations etc.
                    <br /><br />

                    &emsp;&emsp;In case of developing a processing system, if the findings are published/ delivered, only statistical data will be presented, and no voice data or facial expression data will be shown unless having permission from you individually.
                    <br /><br />

                    <h3><b>Delivery or transfer of your personal data to overseas</b></h3>

                    &emsp;&emsp;Since we sometimes need to deliver or transfer your personal data abroad or send data to be stored in a cloud server overseas for services, we will put our best effort in delivering or transferring your personal data to our reliable business allies, service providers or data recipients for security of your personal data.
                    <br /><br />

                    &emsp;&emsp;In case a destination country has inadequate standard of personal data protection, we will deliver or transfer your personal data according to the Personal Data Protection Act, and implement a suitable personal data protection measure as necessary and appropriate.
                    <br /><br />

                    <h3><b>Refusal</b></h3>

                    &emsp;&emsp;In case you disallows us to collect, gather, use or disclose your data, the company may not be able to comply with the legal obligation or a contract entered into by you with the company or plan about future activities with you. In this case, the company may deny your application or services and limit your rights, benefits and/ or interests as a result of the company not able to use your personal data for further consideration or operation.
                    <br /><br />

                    <h3><b>Rights to be requested with the company</b></h3>

                    &emsp;&emsp;Any rights according to the privacy policy whether it is a right to withdraw consent, right to access and request a copy of personal data, right to request right operation with the data, right in deletion of personal data, right for personal data transfer, and right to make a complaint, can be requested. You can file an application for processing as per above-mentioned right or make a contact with the company. Nevertheless, the company reserves right to consider and not to follow such application or request in some cases in the event applicable laws authorize the company to do so.
                    <br /><br />

                    <h3><b>Contact channel for inquiry or complaint</b></h3>

                    &emsp;&emsp;The company thank you for reading our privacy notice. If you have any question or wish to contact us about this privacy notice, please feel free to contact us at following address:
                    <br /><br />

                    <tr><td>&emsp;&emsp;Company name:</td><td>Vulcan Coalition Co., Ltd.</td></tr>
                    <tr><td>&emsp;&emsp;Address: </td><td>559/67, Tanapat House Village, Nonsi Road,</td></tr>
                    <tr><td> </td><td>Chong Nonsi Sub-district, Yannawa District, Bangkok 10120</td></tr>
                    <tr><td> &emsp;&emsp;Email:</td><td>vulcan@lab.ai</td></tr>

                    <h3><b>Change</b></h3>

                    &emsp;&emsp;We will update this privacy notice periodically to be consistent with changes or amendments proposed for your personal data use or to be in line with changes in applicable laws or regulations. We may notify you through an email or through other communication channels when significant changes in the privacy policy occur. We suggest you study this privacy policy regularly to keep updated about your personal data use by us.
                    <br /><br />

                    <h3><b>Vulcan Coalition Co., Ltd.<br />23 Feb 2023</b></h3>


                </div>

            </div>

        </div>
    )
}
export default PrivacyPolicy