import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "../hooks/auth/authReducer";
import socketReducer, { setSocketConnected } from "../hooks/socket/socketReducer";
import lobbyReducer from '../features/lobby/lobbySlice';
import chatReducer from '../features/chat/chatSlice';
import homeReducer, { setSocketStatus } from '../features/home/homeSlice'
import issueManagementReducer from '../features/issuemanagement/issueManagementSlice'
import issueTaggingReducer from '../features/IssueTagging/issueTaggingSlice'
import issueItemReducer from '../features/issueItem/IssueItemSlice'
import publicSessionReducer from '../features/PublicSession/publicSessionSlice'
import privateSessionReducer, { appendCurrentChat, setActiveHolding, setChatSucess } from '../features/PrivateSession/privateSessionSlice'
import SocketClient from "../utils/socket";
import { getAgentSessions } from "./services/agent";
import { getSession } from "./services/session";
import { getPublicSessionsForAgent } from "../features/PublicSession/publicSessionActions";
import { getPrivateSessionsForAgent } from "../features/PrivateSession/privateSessionActions";

export const socketClient = new SocketClient()

const socketMiddleware = () => {
    return params => next => action => {
        const { dispatch } = params
        const { type, payload } = action
        // console.log(payload, type)
        switch (type) {
            case 'socket/connect': {
                console.log('connect')
                socketClient.connect()
                break
            }
            case 'socket/disconnect': {
                socketClient.disconnect()
                break
            }
            case 'socket/onConnect': {
                if (socketClient.socket) {
                    socketClient.socket.on('connect', (cb) => {
                        console.log('successfully connected', cb)
                        dispatch(setSocketConnected(true))
                        dispatch(setSocketStatus('connect'))
                    })
                }
                // socketClient.listenConnected()
                break
            }
            case 'socket/onDisconnect': {
                if (socketClient.socket) {
                    socketClient.socket.on('disconnect', (cb) => {
                        console.log('on socket disconnected')
                        dispatch(setSocketStatus('disconnect'))
                        // dispatch(setSocketConnected(true))
                    })

                }
                // socketClient.listenConnected()
                break
            }
            case 'socket/onIncomingMessage': {
                if(socketClient.socket) {
                    socketClient.socket.on('incoming_message', (cb) => {
                        console.log('incoming message ', cb)
                        // update list 
                        dispatch(getPublicSessionsForAgent())
                    })
                }
                break
            }
            case 'socket/offIncomingMessage': {
                if(socketClient.socket) {
                    socketClient.socket.off('incoming_message', (cb) => {
                        console.log('off incoming message ', cb)
                        // // update list 
                        // dispatch(getPublicSessionsForAgent())
                    })
                }
                break
            }
            case 'socket/onNewChat': {
                console.log('on new chat is called')
                if (socketClient.socket) {
                    // remove previous event listener
                    socketClient.socket.off('message')
                    socketClient.socket.on('message', (cb) => {

                        console.log(cb)
                        console.log('new chat is coming ')
                        // check the payload 
                        // if new one is the same with session id
                        // then call append current active chat 
                        if(action.payload.session_id === cb.session_id){
                            if(cb.is_client){
                                dispatch(appendCurrentChat(cb))
                            }else{
                                // confirm agent chat is received
                                dispatch(setChatSucess(cb))
                            }
                        }
                        // if not then set active holding
                        else{
                            console.log('setActiveHolding is called')
                            dispatch(setActiveHolding({
                                session_id: cb.session_id
                            }))
                        }
                    })
                }
                break
            }
            case 'socket/sendImage': {
                if (socketClient.socket) {
                    console.log('sending image ', action.payload)
                    socketClient.socket.emit("picture-message",{
                        session_id: action.payload.session_id,
                        url: action.payload.url,
                        preview_url: action.payload.preview_url,
                        message_id: action.payload.message_id
                    })
                }
                break
            }
            case 'socket/sendMessage': {
                if (socketClient.socket) {
                    socketClient.socket.send({
                        session_id: action.payload.session_id,
                        message: action.payload.message,
                        message_id: action.payload.message_id
                    })
                }
                break
            }
            case 'socket/onStatusChange': {
                if (socketClient.socket) {
                    socketClient.socket.on('status_change', (cb) => {
                        console.log('status changes')
                        console.log(cb)
                        dispatch(getPublicSessionsForAgent())
                        dispatch(getPrivateSessionsForAgent())
                    })
                }
                break
            }
            case 'socket/offStatusChange': {
                if (socketClient.socket) {
                    socketClient.socket.off('status_change', (cb) => {
                        console.log('off status changes')
                        // console.log(cb)
                        // dispatch(getPublicSessionsForAgent())
                        // dispatch(getPrivateSessionsForAgent())
                    })
                }
                break
            }
            // case 'socket/removeAllListeners': {
            //     console.log('removeAllListeners')
            //     socketClient.removeAllListeners()
            //     break
            // }
            default: break
        }
        return next(action)
    }
}

export const store = configureStore({
    reducer: {
        auth: authReducer,
        socket: socketReducer,
        lobby: lobbyReducer,
        chat: chatReducer,
        home: homeReducer,
        issueManagement: issueManagementReducer,
        issueTagging: issueTaggingReducer,
        issueItem: issueItemReducer,
        publicSession: publicSessionReducer,
        privateSession: privateSessionReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketMiddleware(socketClient))
})

