import { Button, Col, Input, Row } from "antd"
import welcomeAgent from '../../res/welcome_agent.png'
import './login.less'
import logoColor from '../../res/logo_color.svg'
import { useAppDispatch, useAppSelector } from "../../hooks/hooks"
import { login } from "../../app/services/auth"
import { useEffect, useMemo, useState } from 'react';
import { selectAuth } from "../../hooks/auth/authReducer"

const Login = () => {
    const auth = useAppSelector(selectAuth)
    const [loginForm, setLoginForm] = useState({
        username: '',
        password: ''
    })
    const [login_error, setLoginError] = useState({
        visible: false,
        message: ''
    })

    const dispatch = useAppDispatch()

    const handleChangeUsername = (event) => {
        setLoginForm({
            ...loginForm,
            username: event.target.value
        })
    }

    const handleChangePassword = (event) => {
        setLoginForm({
            ...loginForm,
            password: event.target.value
        })
    }

    const handleClickLogin = () => {
        console.log('login')
        // dispatch(login({ username: 'test.shopee@lab.ai', password: 'Q@4t7WfrQJ' }))
        dispatch(login({ username: loginForm.username, password: loginForm.password }))
    }

    useEffect (() => {
        switch(auth?.status) {
            case 'fulfilled': {
                setLoginError({
                    visible: false,
                    message: ''
                })
                break
            }
            case 'rejected': {
                setLoginError({
                    visible: true,
                    message: 'username or password is not correct'
                })
                break   
            }
            default: {
                setLoginError({
                    visible: false,
                    message: ''
                })
                break
            }
        }
    }, [auth?.status])
    return (
        <div style={{ height: '100vh' }}>
            <Row style={{ height: '100%' }}>
                <Col
                    className="login-side"
                    style={{ height: '100%', position: 'relative' }} span={8}>
                    <img
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: '10%',
                            // top: '84px',
                            width: '704px',
                            height: '816px'
                        }}
                        src={welcomeAgent} alt="beautiful agent" />
                </Col>
                <Col
                    className="login-form-container"
                    style={{ height: '100%' }}
                    span={16}>
                    <div style={{
                        padding: '20px'
                    }}>
                        <Row>
                            <Col><img
                                style={{
                                    width: '135px',
                                    height: '93px'
                                }}
                                src={logoColor} /></Col>
                            <Col span={14} className="login-form-container">
                                <span className="login-welcome-txt">WELCOME TO <br />
                                    HEART2HEART</span></Col>
                        </Row>




                        <div style={{ marginTop: '50px' }}>
                            <div className="login-label">Email:</div>
                            <div className="login-input-view">
                                <Input
                                    className="login-input"
                                    onChange={handleChangeUsername}
                                    placeholder="Your Email" />
                            </div>
                            <div className="login-label">Password:</div>
                            <div className="login-input-view">
                                <Input
                                type="password"
                                    className="login-input"
                                    onChange={handleChangePassword}
                                    placeholder="Password" />
                            </div>
                            <div>
                                {
                                    login_error.visible ? <div style={{color:'#FF6464'}}>{login_error.message}</div> : <></>
                                }
                                <button className="login-btn"
                                    onClick={handleClickLogin}>LOGIN</button>
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>

        </div>
    )

}
export default Login