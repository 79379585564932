import { createSlice } from "@reduxjs/toolkit";
import { getAgentSessions } from "../../app/services/agent";
import { editSession, getAllSessionList, getSession, getSessionHistory, getSessionUser, manualAddSession } from "../../app/services/session";


const initialState = {
    session_list: {
        data: null,
        status: 'idle'
    },
    manual_add: {
        data: null,
        status: 'idle'
    },
    session_edit_result: {
        data: null,
        status: 'idle'
    }
}

export const issueManagementSlice = createSlice({
    name: 'issueManagement',
    initialState,
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSessionList.pending, (state) => {
            state.session_list.status = 'pending'
        })
        builder.addCase(getAllSessionList.fulfilled, (state, action) => {
            state.session_list.status = 'fulfilled'
            state.session_list.data = action.payload
        })
        builder.addCase(getAllSessionList.rejected, (state, action) => {
            state.session_list.status = 'rejected'
        })

        builder.addCase(manualAddSession.pending, (state) => {
            state.manual_add.status = 'pending'
        })
        builder.addCase(manualAddSession.fulfilled, (state, action) => {
            state.manual_add.status = 'fulfilled'
            state.manual_add.data = action.payload
        })
        builder.addCase(manualAddSession.rejected, (state, action) => {
            state.manual_add.status = 'rejected'
        })

        builder.addCase(editSession.pending, (state) => {
            state.session_edit_result.status = 'pending'
        })
        builder.addCase(editSession.fulfilled, (state, action) => {
            state.session_edit_result.status = 'fulfilled'
            state.session_edit_result.data = action.payload
        })
        builder.addCase(editSession.rejected, (state, action) => {
            state.session_edit_result.status = 'rejected'
        })

        
    }
})

export const selectSessionList = (store) => store.issueManagement.session_list
export const selectManualAdd = (store) => store.issueManagement.manual_add
export const selectSessionEditResult = (store) => store.issueManagement.session_edit_result
export default issueManagementSlice.reducer