import { Outlet } from "react-router-dom"
import Login from "../features/login/Login";
import { useAuth } from "../hooks/auth/useAuth";
import { useSocket } from "../hooks/socket/useSocket";

export const AuthOutlet = () => {
    const isAuth = useAuth()
    
    return isAuth ? (
        <AppOutlet />
    ) : (
        <Login />
    )
}

export const AppOutlet = () => {
    useSocket()
    return <Outlet />
}

// const Unautorized = () => {
//     return (<div>
//         Unautorized
//     </div>)
// }

