import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../utils/api/api-client"
import { SESSION_ADD_TAG, SESSION_CLOSING_API, SESSION_DETAIL_API, SESSION_EDIT, SESSION_FORWARDING_API, SESSION_HISTORY_API, SESSION_HOLDING_API, SESSION_LIST_ALL_API, SESSION_MANUAL_ADD, SESSION_REOPEN_API, SESSION_REQUEST_API, SESSION_USER_API, USER_SESSIONS_API } from "../../utils/api/config";
import { getAccessTokenFromStorage } from "../../utils/localStorage/token";

export const getSession = createAsyncThunk('/session/getSession', async (
    { session_id },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_DETAIL_API}/${session_id}`,
            headers: { "Authorization": `Bearer ${access_token}` },
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const getSessionForLobby = createAsyncThunk('/session/getSessionForLobby', async (
    { session_id },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_DETAIL_API}/${session_id}`,
            headers: { "Authorization": `Bearer ${access_token}` },
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const getSessionDetail = createAsyncThunk('/session/getSessionDetail', async (
    { sessionId },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_DETAIL_API}/${sessionId}`,
            headers: { "Authorization": `Bearer ${access_token}` },
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const requestSession = createAsyncThunk('/session/requestSession', async (
    { session_id },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_REQUEST_API}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                session_id,
            }
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const requestSessionForLobby = createAsyncThunk('/session/requestSessionForLobby', async (
    { session_id },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_REQUEST_API}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                session_id,
            }
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const holdSession = createAsyncThunk('/session/holdSession', async (
    { session_id, additional_info },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_HOLDING_API}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                session_id,
                additional_info
            }
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const forwardSession = createAsyncThunk('/session/forwardSession', async (
    { session_id, additional_info, forwarding_channel },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_FORWARDING_API}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                session_id,
                additional_info,
                forwarding_channel
            }
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const closeSession = createAsyncThunk('/session/closeSession', async (
    { session_id, additional_info },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_CLOSING_API}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                session_id,
                additional_info
            }
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})
export const reopenSession = createAsyncThunk('/session/reopenSession', async (
    { session_id },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_REOPEN_API}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                session_id,
            }
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getSessionUser = createAsyncThunk('/session/getSessionUser', async (
    { user_id },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_USER_API}/${user_id}`,
            headers: { "Authorization": `Bearer ${access_token}` },
        })
        console.log('get session user', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getSessionHistory = createAsyncThunk('/session/getSessionHistory', async (
    { userId, limit, page },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_HISTORY_API}/${userId}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            params: { limit, page }
        })
        console.log('get session history', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getSessionHistoryForLobby = createAsyncThunk('/session/getSessionHistoryForLobby', async (
    { userId, limit, page },
    { rejectWithValue, dispatch }
) => {
    try {
        const accessToken = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_HISTORY_API}/${userId}`,
            headers: { "Authorization": `Bearer ${accessToken}` },
            params: { limit, page }
        })
        console.log('get session history', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getAllSessionList = createAsyncThunk('/session/getAllSessionList', async (
    { sort, sort_type, limit, page, search, user_name, source },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_LIST_ALL_API}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            params: { sort, sort_type, limit, page, search, user_name, source }
        })
        console.log('get all session list', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const manualAddSession = createAsyncThunk('/session/manualAddSession', async (
    { name, username, type, detail, tag, source },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_MANUAL_ADD}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                name, user_name:username, type, detail, tag, source
            }
        })
        console.log('manual add session', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const sessionAddTag = createAsyncThunk('/session/sessionAddTag', async (
    { session_id, tag },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_ADD_TAG}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: {
                session_id, tag
            }
        })
        console.log('manual add tag list', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getSessionForIssueItem = createAsyncThunk('/session/getSessionForIssueItem', async (
    { session_id },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_DETAIL_API}/${session_id}`,
            headers: { "Authorization": `Bearer ${access_token}` },
        })
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const getSessionHistoryForIssueItem = createAsyncThunk('/session/getSessionHistoryForIssueItem', async (
    { user_id, limit, page },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "get",
            url: `${SESSION_HISTORY_API}/${user_id}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            params: { limit, page }
        })
        console.log('get session history', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

export const editSession = createAsyncThunk('/session/editSession', async (
    { session_id, user_name, source },
    { rejectWithValue, dispatch }
) => {
    try {
        const access_token = getAccessTokenFromStorage()
        const response = await apiClient({
            method: "post",
            url: `${SESSION_EDIT}`,
            headers: { "Authorization": `Bearer ${access_token}` },
            data: { session_id, user_name, source }
        })
        console.log('edit session', response.data)
        // let formatted_response = {
        //     access_token: response.data.access_token,
        //     token_type: response.data.refresh_token
        // }
        // console.log('formatted access token response ', formatted_response)
        return response.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})




