import { io } from "socket.io-client"
import { ENDPOINT } from "./api/config";
import { getAccessTokenFromStorage } from "./localStorage/token";

export default class SocketClient {
    socket = null
    connect() {
        const access_token = getAccessTokenFromStorage()
        this.socket = io(ENDPOINT, {
            transports: ['websocket'],
            auth: {
                token: access_token
            },
            path: '/ws/socket.io'
        })
        console.log(`Connecting socket...`);
    }
    disconnect() {
        if (this.socket) {
            this.socket.disconnect()
            
        }
    }
    listenConnected() {
        if (this.socket) {
            this.socket.on('connect', (cb) => {
                console.log("connected")
            })
        }
    }
    listenDisconnected() {
        if (this.socket) {
            this.socket.on('disconnect' , () => {
                console.log('disconnected')
            })
        }
    }
    listenIncomingMessage() {
        if (this.socket) {
            this.socket.on('incoming_message', (cb) => {
                console.log(cb)
                // {
                //     channel:"vulcan/general"
                //     session_id:"633e7e12ccbdf52f39ad755e"
                //     user_name:"Monkey D Luffy"
                // }
                
                // dispatch to update ui?
            })
        }
    }
    // listenChat() {
    //     if (this.socket) {
    //         this.socket.on('message', (cb) => {
    //             console.log('new message is coming ')
    //             console.log(cb)
    //             // {
    //             //     channel:"vulcan/general"
    //             //     session_id:"633e7e12ccbdf52f39ad755e"
    //             //     user_name:"Monkey D Luffy"
    //             // }
                
    //             // dispatch to update ui?
    //         })
    //     }
    // }
    removeAllListeners() {
        if(this.socket){
            this.socket.removeAllListeners();
        }
    }
}
