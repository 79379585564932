import { getLocalStorage, removeFromLocalStorage, setLocalStorage } from "."
import { STORAGE_CURRENT_CHAT_KEY } from "./constant"
import jwt_decode from "jwt-decode";

export const setActiveChatToStorage = (current_chat) => {
    return setLocalStorage(STORAGE_CURRENT_CHAT_KEY, current_chat, true)
}   

export const getActiveChatIDFromStorage = () => {
    return getLocalStorage(STORAGE_CURRENT_CHAT_KEY)?.id
}

export const resetActiveChatFromStorage = () => {
    return removeFromLocalStorage(STORAGE_CURRENT_CHAT_KEY)
}


