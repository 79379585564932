import { Avatar, Button, Card, Col, Collapse, Input, Layout, Menu, Modal, Radio, Row, Select, Upload } from 'antd';
import ChatEditor from '../../components/ChatEditor/ChatEditor'
import ChatList from '../../components/ChatList/ChatList'
import ChatListLoading from '../../components/ChatListLoading/ChatListLoading';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import './chat.less'
import defaultProfile from '../../res/default_profile.svg'
import {
    UserOutlined,
    PauseCircleOutlined,
    EditOutlined,
    ForwardOutlined,
    CheckCircleOutlined,
    ArrowLeftOutlined,
    FormOutlined,
    DownOutlined
} from '@ant-design/icons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AdditionalInfo from './AdditionalInfo';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { closeSession, forwardSession, getSession, getSessionHistory, getSessionUser, holdSession, reopenSession, requestSession, sessionAddTag } from '../../app/services/session';
import { resetChatPageState, selectCurrentChat, selectFileUploadResult, selectSession, selectSessionHistory, selectSessionUser, selectUpdateSessionTagResult, setCurrentChat } from './chatSlice';
import { useSocket } from '../../hooks/socket/useSocket';
import { getAgentInfo, getAgentSessions } from '../../app/services/agent';
import { selectIssues, selectUser } from '../lobby/lobbySlice';
import { selectIsSocketConnected } from '../../hooks/socket/socketReducer';
import { useBackListener } from '../../hooks/navigate/useBackListener';
import sendBtn from '../../res/send_btn.svg'
import addItem from '../../res/add_item.svg'
import expandIcon from '../../res/expand_icon.svg'
import collapseIcon from '../../res/collapse_icon.svg'
import { CloseOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import IssueList from '../../components/IssueList/IssueList';
import { setActiveChat } from '../home/homeSlice';
import { getActiveChatIDFromStorage, resetActiveChatFromStorage, setActiveChatToStorage } from '../../utils/localStorage/activeChat';
import { setStorageActiveMenuID } from '../../utils/localStorage/menu';
import UserOverview from '../../components/UserOverview/UserOverview';
import ActivityLog from '../../components/ActivityLog/ActivityLog';
import IssueInfo from '../issueinfo/IssueInfo';
import IssueTagging from '../IssueTagging/IssueTagging';
import IssueListPrivate from '../../components/IssueListPrivate/IssueListPrivate';
import { activity_histories_label, close_btn_label, forward_btn_label, holding_message_menu_label, tagging_label, user_detail_label } from '../../wording';
import { uploadFile } from '../../app/services/file';
const { Header, Sider, Content } = Layout;
const { Panel } = Collapse;


dayjs.extend(relativeTime)

const Chat = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    // const [searchParams, setSearchParams] = useSearchParams()
    // const session_id = searchParams.get("id")
    // const session_id = getActiveChatIDFromStorage()
    const issues = useAppSelector(selectIssues)
    const dispatch = useAppDispatch();
    const session = useAppSelector(selectSession)
    const session_user = useAppSelector(selectSessionUser)
    const session_history = useAppSelector(selectSessionHistory)
    const is_socket_connected = useAppSelector(selectIsSocketConnected)
    const current_chat = useAppSelector(selectCurrentChat)
    const user = useAppSelector(selectUser)
    const update_session_tag_result = useAppSelector(selectUpdateSessionTagResult)
    const file_upload_result = useAppSelector(selectFileUploadResult)

    const hiddenFileInput = useRef(null)

    const [session_id, setSessionID] = useState(null)

    const [reply_message, setReplyMessage] = useState('');
    const [hold_action_modal, setHoldActionModal] = useState({
        is_visible: false,
        additional_info: ''
    })
    const [forward_action_modal, setForwardActionModal] = useState({
        is_visible: false,
        forward_to: '',
        error_message: ''
    })
    const [close_action_modal, setCloseActionModal] = useState({
        is_visible: false
    })
    const [user_profile, setUserProfile] = useState({
        is_loaded: false
    })
    const [messages, setMessages] = useState({
        is_loaded: false,
        contents: [
            {
                author: 'Han Solo',
                avatar: 'https://joeschmoe.io/api/v1/random',
                content: <p>Greetings! Please help me! I don't know what to do!!</p>,
                datetime: dayjs().fromNow(),
                type: 'client'
            },
        ]
    })
    // const [component_displayed, setComponent] = useState('UserOverview')
    const [additional_info, setAdditionalInfo] = useState('')
    const [chat_history, setChatHistory] = useState({
        current_page: 1,
        histories: null,
        has_more: true,
        previous_page: 0
    })

    const [selected_component, setComponent] = useState('user_detail')

    const [tagging_list, setTaggingList] = useState([])


    const UpdateTaggingListToSession = (tag_list) => {
        dispatch(sessionAddTag({
            session_id: session_id,
            tag: tag_list
        }))
    }
    // if update session success then call new session
    useEffect(() => {
        switch (update_session_tag_result.status) {
            case 'fulfilled': {
                dispatch(getSession({ session_id: session_id }))
                break
            }
        }
    }, [update_session_tag_result.status])

    const toLobbyPage = () => {
        setStorageActiveMenuID('1')
        navigate('/')
    }

    useEffect(() => {
        console.log('active chat id ', getActiveChatIDFromStorage())
        setSessionID(getActiveChatIDFromStorage())
        if (getActiveChatIDFromStorage()) {
            dispatch(reopenSession({ session_id: getActiveChatIDFromStorage() }))
        }
    }, [])

    useEffect(() => {
        if (session_id) {
            console.log('session_id maiwaaaa ', session_id)
            dispatch(reopenSession({ session_id: session_id }))
        }
    }, [session_id])

    useEffect(() => {
        if (is_socket_connected) {
            dispatch({ type: 'socket/onIncomingMessage' })
            dispatch({ type: 'socket/onStatusChange' })
        }
    }, [is_socket_connected])
    useEffect(() => {
        return () => {
            dispatch({ type: 'socket/removeAllListeners' })
        }
    }, [])

    useBackListener(() => {
        dispatch(holdSession({
            session_id: session_id,
            additional_info: additional_info
        }))
        dispatch(resetChatPageState())
    })

    const handleClickLogo = () => {
        dispatch(holdSession({
            session_id: session_id,
            additional_info: additional_info
        }))
        dispatch(resetChatPageState())

        toLobbyPage()
    }

    const handleUpdateReplyMessage = (e) => {
        setReplyMessage(e.target.value);
    };

    useEffect(() => {
        console.log('this is called')
        setImageFile({
            preview_url: '',
            full_url: ''
        })
    }, [])
    const handleSendMessage = () => {
        if (image_file.full_url) {
            setImageFile({
                preview_url: '',
                full_url: ''
            })
            dispatch({ type: 'socket/sendImage', payload: { session_id: session_id, url: image_file.full_url, preview_url: image_file.preview_url } })
        } else {
            if (!reply_message) return;
            setReplyMessage('');
            dispatch({ type: 'socket/sendMessage', payload: { session_id: session_id, message: reply_message } })
        }
    };

    useEffect(() => {
        setAdditionalInfo(session?.data?.additional_info)
        if (session?.data?.tag) {
            setTaggingList(session?.data?.tag)
        } else {
            setTaggingList([])
        }

    }, [session])

    const handleChangeAdditionalInfo = (event) => {
        setAdditionalInfo(event.target.value)
    }
    const handleResetAdditionalInfo = (event) => {
        setAdditionalInfo('')
    }

    const components = {
        "UserOverview": <UserOverview
            session={session.data}
            session_user={session_user.data}
            loading={user_profile.is_loaded}
        />,
        "AdditionalInfo": <AdditionalInfo
            session_user={session_user.data}
            handleChange={handleChangeAdditionalInfo}
            handleReset={handleResetAdditionalInfo}
            value={additional_info}
        />
    }
    const getItem = (label,
        key,
        icon,
        children) => {
        return {
            key,
            icon,
            children,
            label,
        };
    }
    const handleMenuClicked = (event) => {
        switch (event?.key) {
            case '1': {
                setComponent('UserOverview')
                break
            }
            case '2': {
                setComponent('AdditionalInfo')
                break
            }
            case '3': {
                setHoldActionModal({
                    ...hold_action_modal,
                    is_visible: true
                })
                break
            }
            case '4': {
                // setForwardActionModal({
                //     ...forward_action_modal,
                //     is_visible: true
                // })
                break
            }
            case '5': {
                // setCloseActionModal({
                //     ...close_action_modal,
                //     is_visible: true
                // })
                break
            }
            case '0': {
                dispatch(holdSession({
                    session_id: session_id,
                    additional_info: additional_info
                }))
                dispatch(resetChatPageState())
                toLobbyPage()
                break
            }
        }
    }
    const items = [
        getItem('User Profile', '1', <UserOutlined />),
        getItem('Additional Info', '2', <EditOutlined />),
        getItem('Actions', 'sub1', <FormOutlined />, [
            getItem('Hold', '3', <PauseCircleOutlined />),
            getItem('Forward', '4', <ForwardOutlined />),
            getItem('Close', '5', <CheckCircleOutlined />),
        ]),
        getItem('Back To Lobby', '0', <ArrowLeftOutlined />)
    ];

    const handleConfirmHold = () => {
        setHoldActionModal({
            ...hold_action_modal,
            is_visible: false
        })
        dispatch(holdSession({
            session_id: session_id,
            additional_info: additional_info
        }))

        resetActiveChatFromStorage()
        toLobbyPage()
    }
    const handleCancelHold = () => {
        setHoldActionModal({
            ...hold_action_modal,
            is_visible: false
        })
    }
    const handleConfirmForward = () => {
        setForwardActionModal({
            ...forward_action_modal,
            is_visible: false
        })
        dispatch(forwardSession({
            session_id: session_id,
            additional_info: additional_info,
            forwarding_channel: forward_action_modal.forward_to
        }))
        resetActiveChatFromStorage()
        toLobbyPage()
    }
    const handleCancelForward = () => {
        setForwardActionModal({
            ...forward_action_modal,
            is_visible: false
        })
    }
    const handleConfirmClose = () => {
        setCloseActionModal({
            ...close_action_modal,
            is_visible: false
        })
        dispatch(closeSession({
            session_id: session_id,
            additional_info: additional_info,
        }))
        resetActiveChatFromStorage()
        toLobbyPage()
    }
    const handleCancelClose = () => {
        setCloseActionModal({
            ...close_action_modal,
            is_visible: false
        })
    }
    const handleChooseForwardChannel = (value) => {
        setForwardActionModal({
            ...forward_action_modal,
            forward_to: value
        })
    }

    useEffect(() => {
        return () => {
            dispatch(resetChatPageState())
        }
    }, [])

    // useEffect(() => {
    //     if (is_socket_connected) {
    //         dispatch({ type: 'socket/onNewChat', payload: { session_id: session_id } })
    //         dispatch({ type: 'socket/onIncomingMessage' })
    //     }
    // }, [is_socket_connected, session_id])
    // get chats 
    // listen to new chat
    useEffect(() => {
        if (session_id) {
            dispatch(getAgentInfo())
            dispatch(getSession({ session_id: session_id }))
        }
    }, [session_id])

    const loadMoreHistory = () => {

        dispatch(getSessionHistory({
            user_id: session.data?.user_id,
            limit: 10,
            page: chat_history.current_page
        }))

        setChatHistory({
            ...chat_history,
            current_page: chat_history.current_page + 1
        })

    }



    // called only once but after user_id is valued
    useLayoutEffect(() => {
        if (session.data?.user_id) {
            dispatch(getSessionUser({ user_id: session.data?.user_id }))
            dispatch(getSessionHistory({ user_id: session.data?.user_id, page: chat_history.current_page, limit: 10 }))
            setChatHistory({
                ...chat_history,
                current_page: chat_history.current_page + 1
            })
        }
    }, [session.data?.user_id])

    // when chat history change 
    // update chat history
    useEffect(() => {
        if (session_history.data && session_history.data.length > 0) {
            // const reverseHistory = [...session_history.data].reverse()
            if (chat_history.histories && chat_history.histories.length > 0) {
                setChatHistory({
                    ...chat_history,
                    histories: chat_history.histories.concat(session_history.data)
                })
            } else {
                setChatHistory({
                    ...chat_history,
                    histories: session_history.data
                })
            }
        } else if (session_history.data && session_history.data?.length === 0) {
            // no more history content
            setChatHistory({
                ...chat_history,
                has_more: false
            })
        }
    }, [session_history.data])

    useEffect(() => {
        dispatch(getAgentSessions())
    }, [])

    const handleClickIssue = (id) => {
        if (id === session_id) {
            return
        }
        // dispatch(resetChatPageState())
        // dispatch(setActiveChat(id))
        dispatch(holdSession({
            session_id: session_id,
            additional_info: additional_info
        }))
        setActiveChatToStorage({ id: id })
        setSessionID(id)

        dispatch(requestSession({ session_id: id }))
        // navigate(`/issue?id=${id}`, { state: { user: { session_id: id } }, replace: true })
        window.location.reload()
    }

    const onSaveTagList = (tag_list) => {
        // call add tag 
        // then call new list 
        UpdateTaggingListToSession(tag_list)
    }
    const onDeleteTagList = () => {

    }

    const component = {
        'user_detail': <UserOverview
            loading={user_profile.is_loaded}
            session={session.data}
            session_user={session_user.data}
        />,
        'activity': <AdditionalInfo
            session_user={session_user.data}
            handleChange={handleChangeAdditionalInfo}
            handleReset={handleResetAdditionalInfo}
            value={additional_info}
        />,
        'tag': <IssueTagging
            on_save={onSaveTagList}
            parent_type='auto'
            tagging_list={tagging_list}
            setTaggingList={setTaggingList}
        />
    }

    const [image_file, setImageFile] = useState({
        preview_url: '',
        full_url: ''
    })

    const handleUploadImage = (info) => {
        hiddenFileInput.current.click()
    }

    const handleChangeFileUpload = (event) => {
        const fileUploaded = event.target.files[0]
        dispatch(uploadFile({ file: fileUploaded }))
    }

    useLayoutEffect(() => {
        switch (file_upload_result.status) {
            case 'fulfilled': {
                setImageFile({
                    preview_url: file_upload_result.data.preview_url,
                    full_url: file_upload_result.data.full_url
                })
                break
            }
            case 'rejected': {
                setImageFile({
                    preview_url: '',
                    full_url: ''
                })
                break
            }
            default: {
                setImageFile({
                    preview_url: '',
                    full_url: ''
                })
                break
            }
        }
    }, [file_upload_result.status])

    const handleCancelUploadImage = () => {
        setImageFile({
            preview_url: '',
            full_url: ''
        })
    }

    const handleEnter = (event) => {
        if (event.keyCode == 13 && event.shiftKey || event.keyCode == 13 && event.ctrlKey || event.keyCode == 13 && event.altKey) {
        } else if (event.keyCode == 13) {
            handleSendMessage()
            event.preventDefault();
        }
    }

    return (
        <Layout style={{ height: "100vh" }}>
            <Modal title="Hold the chat" visible={hold_action_modal.is_visible} onOk={handleConfirmHold} onCancel={handleCancelHold}>
                <p>Are you sure?</p>
                <p>you are going to hold the issue.</p>

            </Modal>
            <Modal title="Forward the chat" visible={forward_action_modal.is_visible} onOk={handleConfirmForward} onCancel={handleCancelForward}>
                <p>Forward to</p>
                <Select onChange={handleChooseForwardChannel} style={{ width: '100%' }}>
                    {
                        user?.data?.forwarded_channel?.map((channel, i) => {
                            return (
                                <Select.Option key={i} value={channel}>{channel}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Modal>
            <Modal title="Close the chat" visible={close_action_modal.is_visible} onOk={handleConfirmClose} onCancel={handleCancelClose}>
                <p>Are you sure?</p>
                <p>you are going to close the issue.</p>
            </Modal>

            {/* <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={(broken) => {
                }}
                onCollapse={(collapsed, type) => {
                }}
            >
                <div className="logo" >
                    <img src={logo} onClick={handleClickLogo} />
                </div>
                <Menu
                    onClick={handleMenuClicked}
                    theme='dark'
                    defaultSelectedKeys={['1']}
                    mode='inline'
                    items={items} />
            </Sider> */}
            <Layout>
                {/* <Header style={{ fontSize: '20px', color: '#fff' }}>
                    {state.user?.name}
                </Header> */}
                <Row >
                    {/* chat  */}
                    <Col span={5} style={{ height: '100vh' }}>
                        <Row style={{ height: '7%', width: '100%', backgroundColor: '#ffffff' }}>
                            <Header className='holding-header-text'>
                                {holding_message_menu_label}
                            </Header>
                        </Row>
                        <Row className='holding-search-container' style={{ height: '7%', width: '100%', backgroundColor: '#ffffff' }}>
                            <Input className='holding-search' placeholder='Search Messager' />
                        </Row>
                        <Row className='holding-container' style={{ height: '86%', width: '100%', backgroundColor: '#ffffff' }}>
                            <IssueListPrivate
                                selected={session_id}
                                issues={issues.data?.holding}
                                handleClickIssue={handleClickIssue}
                            // loading={is_loading_issues} 
                            />
                        </Row>

                    </Col>
                    <Col span={19} style={{ height: '100vh' }}>
                        <Row style={{ height: '10%', width: '100%', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center' }}>
                            <Col>
                                <Row>
                                    <Avatar
                                        src={session.data?.user_avatar ? session.data?.user_avatar : defaultProfile}
                                        style={{ marginLeft: '10px' }}
                                    // icon={<UserOutlined />}
                                    />
                                    <span className='holding-profile-name'>
                                        {session.data?.user_name}
                                    </span>
                                </Row>
                            </Col>
                            <Col style={{ backgroundColor: '#ffffff', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 0 }}>
                                <button
                                    className="btn"
                                    data={forward_btn_label}
                                    onClick={() => {
                                        setForwardActionModal({
                                            ...forward_action_modal,
                                            is_visible: true
                                        })
                                    }}></button>
                                <button
                                    onClick={() => {
                                        setCloseActionModal({
                                            ...close_action_modal,
                                            is_visible: true
                                        })
                                    }}
                                    className="accept-button">
                                    {close_btn_label}
                                </button>
                            </Col>
                        </Row>
                        <Row style={{ height: '90%' }}>
                            <Col span={12} style={{ height: '100%' }}>
                                <Row style={{ height: '80%', width: '100%', backgroundColor: '#F0F2F5' }}>
                                    <ChatList
                                        container_id="chat-chatlist"
                                        history={chat_history.histories}
                                        chats={session.data?.conversations}
                                        next={loadMoreHistory}
                                        // is_loaded={chat_history.is_loaded}
                                        has_more={chat_history.has_more} />
                                </Row>
                                <Row style={{ height: '20%', width: '100%', backgroundColor: '#ffffff', display: 'flex', alignItems: 'center' }}>
                                    <Col span={3} style={{ alignItems: 'center', textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <img
                                            onClick={handleUploadImage}
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                cursor: 'pointer'
                                            }} src={addItem} />
                                        <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            style={{ display: 'none' }}
                                            onChange={handleChangeFileUpload} />

                                    </Col>
                                    <Col span={18} style={{ alignItems: 'center', textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                        {
                                            image_file.preview_url ?
                                                <div style={{ position: 'relative', height: '80%', overflow: 'auto' }}>
                                                    <CloseOutlined
                                                        onClick={handleCancelUploadImage}
                                                        style={{ right: '0px', position: 'absolute', cursor: 'pointer', color: 'red', fontSize: '20px' }}
                                                    />
                                                    <img
                                                        style={{ width: '100%', height: '100%' }}
                                                        src={image_file.preview_url}
                                                    />
                                                </div>
                                                // <Card
                                                //     extra={<CloseOutlined style={{ cursor: 'pointer' }}  />}
                                                //     size='small'
                                                //     style={{ width: '100%', height: '80%', overflow: 'auto' }} >
                                                //     <img
                                                //         style={{ width: '100%', height: '100%' }}
                                                //         src={image_file.preview_url}
                                                //     />
                                                // </Card> 
                                                :
                                                <TextArea
                                                    onPressEnter={handleEnter}
                                                    style={{ borderRadius: '20px', overflowY: 'auto', width: '100%' }}
                                                    value={reply_message}
                                                    onChange={handleUpdateReplyMessage}
                                                    onResize={(width, height) => {
                                                        console.log(width, height)
                                                    }}
                                                    autoSize={{
                                                        minRows: 4,
                                                        maxRows: 4,
                                                    }}
                                                    placeholder='Aa' />
                                        }



                                    </Col>
                                    <Col span={3} style={{ alignItems: 'center', textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <img
                                            onClick={handleSendMessage}
                                            style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                            src={sendBtn} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12} style={{ height: '100%' }}>
                                <Row style={{ height: '100%', width: '100%', backgroundColor: '#ffffff' }}>
                                    <Row style={{ width: '100%', height: '5%' }}>
                                        <Radio.Group style={{ width: '100%' }} value={selected_component} onChange={event => {
                                            setComponent(event.target.value)
                                        }}>
                                            <Row>
                                                <Col span={8} >
                                                    <Radio.Button style={{ width: '100%', textAlign: 'center' }} value="user_detail">{user_detail_label}</Radio.Button>
                                                </Col>
                                                <Col span={8}>
                                                    <Radio.Button style={{ width: '100%', textAlign: 'center' }} value="activity">{activity_histories_label}</Radio.Button>
                                                </Col>
                                                <Col span={8}>
                                                    <Radio.Button style={{ width: '100%', textAlign: 'center' }} value="tag">{tagging_label}</Radio.Button>
                                                </Col>
                                            </Row>

                                        </Radio.Group>
                                    </Row>
                                    <Row style={{ padding: '30px', height: '95%', width: '100%' }}>
                                        {component[selected_component]}
                                    </Row>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout>
        </Layout>
    );
};
export default Chat


