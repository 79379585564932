export const setLocalStorage = (key, data, flush = false) => {
    try {
        if (data === undefined || data === null) {
            localStorage.removeItem(key)
        } else {
           if (flush) {
             localStorage.setItem(key, JSON.stringify(data))
            } else {
                const _oldData = localStorage.getItem(key)
                const oldData = JSON.parse(_oldData)
                const newData = {...oldData, ...data}
                localStorage.setItem(key, JSON.stringify(newData))
            } 
        }
        
    } catch(error) {
        console.warn(`Failure setting local storage with ${data}: ${error.message}`)
    }
}

export const getLocalStorage = (key) => {
    try {
        const _persistedState = localStorage.getItem(key)
        return JSON.parse(_persistedState) || {}
    } catch (error) {
        setLocalStorage(key, null, true)
        return {}
    }
}

export const removeFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key)
    } catch (error) {
        console.warn(`Failure deleting local storage with ${key}: ${error.message}`)
    }
}

export const resetLocalStorage = () => {
    try{
        localStorage.clear()
    }catch (error) {
        console.warn(`Cannot clear localstorage ${error.message}`)
    }
}