import dayjs from "dayjs"
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

export const formatDatetime = (stringDateTime) => {
    return dayjs.utc(stringDateTime).tz('Asia/Bangkok').format('MMM D, H:mm A')
}
export const formatDateTimeIso = (stringDateTime) => {
    return dayjs.utc(stringDateTime).tz('Asia/Bangkok').format('YYYYMMDD')
}
export const formatDatetimeWithYear = (stringDateTime) => {
    return dayjs.utc(stringDateTime).tz('Asia/Bangkok').format('MMM D YYYY, H:mm A')
}
export const compareTime = (a, b) => {
    return dayjs.utc(a).tz('Asia/Bangkok').isBefore(dayjs.utc(b).tz('Asia/Bangkok'))
}